import React from "react";
import {Link} from "react-router-dom";
import Logo_Main from  "./img/ushopper_green.png"
function Footer() {
return (
    <>
        <div className="container">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <p className="col-md-4 mb-0 text-muted">© 2022 Ushopper, by Smart Retail Group</p>    
           

            <a
                href="/"
                className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
            >
                <img src={Logo_Main} alt="" className="img-logo"/>
            </a>

            <ul className="nav col-md-4 justify-content-end">
                {/* <li className="nav-item">
                <a href="#" className="nav-link px-2 text-muted">
                    Equipo
                </a>
                </li> */}
                <li className="nav-item">
                <Link to="/privacy-policy" className="nav-link px-2 text-muted">
                    Politicas y Privacidad 
                </Link>
                </li>         
            </ul>
            </footer>
        </div>
    </>
  );
}

export default Footer;
