import React from 'react'

function CardTransaction(props) {
  return (
    <>
      <div className={props.col}>
        <div className="p-2 mt-3">
          <label className="pb-2 fw-bold text-decoration-underline text-muted">
            {props.title}
          </label>
          <p className="transaction-text text-center" >
            {!props.data_transaction ? <small className="text-muted text-center">No registrado</small> : <>{props.data_transaction}</>}
          </p>
        </div>
      </div>
    </>
  )
}

export default CardTransaction