import React, { useState } from "react";
//Internal components
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
//Icons
import { FaRegEye } from "react-icons/fa";

//api
import { BusinessFetchAPI } from '../../../api/Business';
//Swal
import Swal from "sweetalert2";


function ModalDetails({ data }) {
    const [lgShow, setLgShow] = useState(false); 
    //Function to aprobe the credentials 
    const aproveCredentials = async () => {
        try {
            
            const response = await BusinessFetchAPI.updateBusinessFECredentials(true, data._id);
            console.log(response);
            Swal.fire({
                title: 'Aprobado',
                icon: 'success'
            })
        } catch (err) {
            Swal.fire({
                title: 'Denegado',
                icon: 'error'
            })
        }
    }

    return (
        <>
            <button
                className=" btn btn-secondary-us bg-btn-secundary "
                onClick={() => setLgShow(true)}
            >
                <FaRegEye className="text-secondary-us " />
            </button>
            <Modal size="lg" show={lgShow} onHide={() => setLgShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex justify-content-between ">
                        Detalles la solicitud
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <h5 className="card-title">Cambio de credenciales de FE</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h5 className="card-title">Credenciales actuales</h5>
                            <div className="row">
                                <div className="col-6">
                                    Certificado:
                                    <span className="d-block  ">{data.fe_credentials.credentials_type === 'demo' ? 'Tipo Prueba' : data.fe_credentials.conf.cer}</span>
                                </div>
                            </div>
                            <p class="block">Contra del Certificado: {data.fe_credentials.credentials_type === 'demo' ? 'Tipo Prueba' : data.fe_credentials.conf.pass}</p>
                            <p class="block">Usuario del pac: {data.fe_credentials.credentials_type === 'demo' ? 'Tipo Prueba' : data.fe_credentials.pac.user}</p>
                            <p class="block">QR del pac: {data.fe_credentials.credentials_type === 'demo' ? 'Tipo Prueba' : data.fe_credentials.pac.QR}</p>
                            <p class="block">Contra del pac: {data.fe_credentials.credentials_type === 'demo' ? 'Tipo Prueba' : data.fe_credentials.pac.pass}</p>
                        </div>
                        <div className="col-12">
                            <h5 className="card-title">Credenciales a cambiar</h5>
                            <p class="block">Certificado: {data.fe_credentials.credentials_in_approval.conf.cer}</p>
                            <p class="block">Contra del Certificado: {data.fe_credentials.credentials_in_approval.conf.pass}</p>
                            <p class="block">Usuario del pac: {data.fe_credentials.credentials_in_approval.pac.user}</p>
                            <p class="block">QR del pac: {data.fe_credentials.credentials_in_approval.pac.qr}</p>
                            <p class="block">Contra del pac: {data.fe_credentials.credentials_in_approval.pac.pass}</p>
                        </div>
                        <div className="mt-3 d-flex justify-content-end">
                            <Button onClick={aproveCredentials} size="sm" type="button">
                                Aprobar cambio
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalDetails;
