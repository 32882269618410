import React, {useContext} from 'react'
import logo from '../../assets/img/Ushopper_Logo.png'
import { CompanyFetchAPI } from '../../api/company'
import CompanyContext from '../../context/CompanyContext'
import Swal from 'sweetalert2'
import {useNavigate, Link } from "react-router-dom";
import UserContext  from '../../context/UserContext'
import { useEffect } from 'react'


function SingUpCompany() {
    const {userData} = useContext(UserContext);

    const navigate = useNavigate();

    const submit = async (event) => {
        event.preventDefault()
        const form = new FormData(event.target)
        form.append("user_phone", userData.infoUser.phone)
        const  datacompany= Object.fromEntries(form.entries())

        try {

            const response = await CompanyFetchAPI.newCompany (datacompany)
            console.log(response);

            Swal.fire({
                position: 'top',
                icon: 'success',
                title: response.data.message,
                text: 'Bienvenido',
                showConfirmButton: false,
                timer: 1500
              })

            navigate('/login');
        
        } catch (error) {
            console.log(error);
            Swal.fire({
                position: 'top',
                icon: 'error',
                title: error.response.data.message,
                showConfirmButton: false,
                timer: 1500
              })

        }
    }
  return (
    <>
    
    <div className="container">
                <main>
                    <div className="py-5 text-center">
                        <img className="d-block mx-auto mb-4" src={logo} alt="" width="150" height="150" />
                        <h2>Registro de empresa</h2>

                    </div>

                    <div className="row g-5">
                        <div className="col-md-12 col-lg-12">
                            <h4 className="mb-3">Ingresa datos de empresa</h4>
                            <form className="needs-validation" onSubmit={submit} encType='multipart/form-data'>
                                <div className="row g-3">
                                    <div className="col-sm-6">
                                        <label htmlFor="firstName" className="form-label">Nombre</label>
                                        <input type="text" name="name" className="form-control" id="firstName" required placeholder=" Nombre empresa" />
                                        <div className="invalid-feedback">
                                        Debe ingresar su nombre.
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="ruc" className="form-label">Ruc</label>
                                        <input type="text" name="ruc" className="form-control" id="ruc" required placeholder="0-0000-0000" />
                                        <div className="invalid-feedback">
                                        Debe ingresar su apellido.
                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <label htmlFor="dv" className="form-label">DV </label>
                                        <input type="text" name="dv" className="form-control" id="dv" placeholder="00" required />
                                        <div className="invalid-feedback">
                                        Debe ingresar un correo electronico.
                                        </div>
                                    </div>

                                    

                                    <div className="col-12">
                                        <label htmlFor="business_phone" className="form-label">Celular de emprea</label>
                                        <input type="text" name='business_phone' className="form-control" id="business_phone" placeholder="0000-0000" required />
                                        <div className="invalid-feedback">
                                        Debe ingresar un numero de celular.
                                        </div>
                                    </div>


                                </div>

                                <hr className="my-4" />

                                <button className="w-100 btn btn-primary btn-lg" type="submit">Crear empresa</button>
                                
                               
                            </form>


                        </div>
                    </div>
                </main>
            </div>

   
    </>
  
  )
}

export default SingUpCompany