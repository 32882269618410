import React, { useEffect, useContext, useState } from "react";
import Swal from "sweetalert2";
import { UsersFetchAPI } from "../../../../api/Users";
import EditProfile from "../EditProfile/EditProfile"
import CardInfo from "../../../../utils/CardInfo";
import { FaPen } from "react-icons/fa";

function InfoProfile() {
  const [infouser, setInfouser] = useState({
    infouser: [],
  });

  let datastorage = JSON.parse(localStorage.getItem("userData"));
  let user_id = datastorage.infoUser.id;

  const infoprofile = async () => {
    try {
      const response = await UsersFetchAPI.getuserbyid(user_id);
      setInfouser({
        infouser: [response.data.Userbyid],
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await infoprofile();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const info = () => {
    let getdata = infouser.infouser;
    let infodata = getdata.map((data) => (
      <div className="row justify-content-center" key={data._id}>
        <CardInfo col={"col-md-4"} title={"Nombre"} data={data.name} />
        <CardInfo col={"col-md-4"} title={"Apellido"} data={data.lastName} />
        <CardInfo col={"col-md-4"} title={"Cédula / Pasaporte"} data={data.userId} />
        <CardInfo col={"col-md-6"} title={"Correo Electrónico"} data={data.email} />
        <CardInfo col={"col-md-6"} title={"Número"} data={data.phone} />
      </div>
    ));
    return <>{infodata}</>;
  };

  return (
    <>
      <section className=" mt-3">
        <div className="d-flex justify-content-between align-items-center mt-3   ">
          <h5> Mis Datos </h5>
          {/* <button className="fw-bold btn-edit  text-ushopper">
            <FaPen className="icon-m" /> Editar datos
          </button> */}
          

          <EditProfile></EditProfile>
        </div>
        <div className="p-2 mb-5">
            {info()}            
        </div>
      </section>
    </>
  );
}

export default InfoProfile;
