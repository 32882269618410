import "./style.css";
import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { OcrFetchAPI } from "../../../api/Ocr"
import Swal from 'sweetalert2'
const ScanQr = () => {
  const [selected, setSelected] = useState("environment");
  const [result, setResult] = useState("No result");
  const [startScan, setStartScan] = useState(false);

  const handleScan = async (scanData) => {
    if (scanData && scanData !== null) {
      setResult(scanData);
      setStartScan(false);
      await analyzeUrl(scanData);
    } else {
      setResult('Error analizando')
    }
  };
  const handleError = (err) => {
    setResult(err);
    console.error(err);
  };

  const previewStyle = {
    height: 240,
    width: 320
  };


  //Function to read the file input 
  const readFile = async (e) => {
    e.preventDefault();
    if (startScan) {
      Swal.fire({
        icon: 'warning',
        title: 'Ya esta escaneado, desactive el escaner para analizar la foto',
      })
    } else {
      const form = new FormData();
      form.append('files', e.target.files[0]);
      try {
        const response = await OcrFetchAPI.analyzeQr(form);
        console.log(response.data);
        handleScan(response.data.file_response.result);
      } catch (err) {
        if (err.response.status && err.response.status === 500) {
          handleError(err.response.data.error);
        } else {
          handleError('Error analizando')
        }
      }
    }

  }

  //Url analyze 
  const analyzeUrl = async (url) => {
    try {
      const response = await OcrFetchAPI.analyzeUrl(url);
      //const response = await OcrFetchAPI.analyzeUrl('https://dgi-fep.mef.gob.pa/Consultas/FacturasPorQR?chFE=FE0120000155664484-2-2018-0900002022092000000000419990112389565310&iAmb=1&digestValue=jD4nw5w2Jx1ZtCYocWeu+IAX7H1K8w4Defju5LeCA54=&jwt=eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJjaEZFICI6IkZFMDEyMDAwMDE1NTY2NDQ4NC0yLTIwMTgtMDkwMDAwMjAyMjA5MjAwMDAwMDAwMDQxOTk5MDExMjM4OTU2NTMxMCIsImlBbWIiOiIxIiwiZGlnZXN0VmFsdWUiOiJqRDRudzV3Mkp4MVp0Q1lvY1dldStJQVg3SDFLOHc0RGVmanU1TGVDQTU0PSJ9.0zg2PucFPyj_22z-IL1p0zjXjkQ77sTFu6gYmtoRE6w');
      
      //We register the response of the url 
      const register = await OcrFetchAPI.registerInvoice({
        invoice_details: response.data.invoice_details, 
        source: response.data.html,
        description: "Factura obtenida por Qr"
      });

      Swal.fire({
        icon: 'success', 
        title: register.data.message
      })
      setResult(JSON.stringify(response.data.invoice_details));
      
    } catch (err) {
      console.log(err)
      if (err.response.status !== 500) {
        handleError(err.response.data.message)
      } else {
        handleError("Error analizando")
      }
    }
  }


  return (
    <>
      <div className="QR">
        <h1>Lector QR</h1>

        <button onClick={() => { setStartScan(!startScan) }}>
          {startScan ? "Parar" : "Escanear"}
        </button>

        {startScan && (
          <>
            <h1>Escaneá el codigo QR</h1>
            <h2>Apuntá con la camara al codigo, se detectará automáticamente</h2>

            <select name="camera" onChange={(e) => setSelected(e.target.value)}>
              <option value={"environment"}>Cámara trasera</option>
              <option value={"front"}>Cámara delantera</option>
            </select>

            <QrReader
              facingMode={selected}
              delay={500}
              style={previewStyle}
              onScan={handleScan}
              onError={handleError}
            />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
        <input type="file" name="files" accept="image/*" encType="multipart/form-data"
          onChange={e => {
            readFile(e)
          }}
          onClick={e => {
            e.target.value = null
          }}
        />
        <p>{result}</p>
      </div>
    </>
  );
};

export default ScanQr;