import React from 'react'
import { View, StyleSheet, Text, Font } from '@react-pdf/renderer';
import FontsRoboto from './Fonts/Roboto-Bold.ttf'
//Styles 
Font.register({
   family: 'Roboto',
   fonts: [
      {
         src: FontsRoboto,
         fontWeight: 'bold',
      },

   ],
});

const styles = StyleSheet.create({

   container: {      
      marginTop: 10,
   },

   tableContainer: {
      flexDirection: 'row',   
   },

   textTitle:{
      width: 300,
      fontSize: 10,
      alignItems: 'center',
      textAlign: 'center',  
      fontFamily: 'Roboto',
      backgroundColor: '#F1F1F1',
   },

   textNormal: {
      backgroundColor: '#F1F1F1',
      width: 100,
      fontSize: 10,
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: 4,
      fontWeight: 'bold',
      fontFamily: 'Roboto',


   },

   rowContainer: {
      flexDirection: 'row',
      marginTop: 0,
      alignContent: 'center',
   },

   textRow1: {
      width: 100,
      fontSize: 9,
      alignItems: 'center',
      textAlign: 'center',
      borderColor: '#F1F1F1',
      borderWidth: 1,
      paddingVertical: 2,
   },


});

function TimePayment() {
   return (
      <>
         <View style={styles.container}>
            <Text style={styles.textTitle}>Información de Pago a Plazo</Text>
            <View style={styles.tableContainer}>
               <Text style={styles.textNormal}>Cuota </Text>
               <Text style={styles.textNormal}>Fecha de Vencimiento</Text>
               <Text style={styles.textNormal}>Valor</Text>
            </View>
         </View>

                  {/* Values  */}
         {/* Row 1 */}
         <View style={styles.rowContainer}>
            <Text style={styles.textRow1}> 1</Text>
            <Text style={styles.textRow1}> 15/01/2023 </Text>
            <Text style={styles.textRow1}> 545.70 </Text>
         </View>

      </>
   )
}

export default TimePayment