import React from 'react'
// External Componets
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DocPdf from './DocPdf';

function Report() {

  return (
    <>
      <main className='container'>
    
        <h1 className='text-center '>Reporte</h1>
        <h3>Vista del PDF</h3>    
        <div className='smart-report border p-5 mt-3 rounded text-center'>
          <PDFViewer className=' pdf-viewer-sp '>
            <DocPdf />
          </PDFViewer>
        </div>
      </main>
    </>
  )
}

export default Report