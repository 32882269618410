import React, { useState } from 'react'
import { View, StyleSheet, Text, Font } from '@react-pdf/renderer';
import FontsRoboto from './Fonts/Roboto-Bold.ttf'
import Itbms from './ItbmsValues';
//Styles 
Font.register({
   family: 'Roboto',
   fonts: [
      {
         src: FontsRoboto,
         fontWeight: 'bold',
      },

   ],
});

const styles = StyleSheet.create({

   container: {
      marginTop: 10,
   },

   tableContainer: {
      flexDirection: 'row',
   },

   textTitle: {
      width: 300,
      fontSize: 10,
      alignItems: 'center',
      textAlign: 'center',
      fontFamily: 'Roboto',
      backgroundColor: '#F1F1F1',
   },

   textNormal: {
      backgroundColor: '#F1F1F1',
      width: 125,
      fontSize: 10,
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: 4,
      fontWeight: 'bold',
      fontFamily: 'Roboto',


   },
   textTotal: {
      fontSize: 10,
      width: 100,
      backgroundColor: '#F1F1F1',
      fontFamily: 'Roboto',
      alignItems: 'right',
      textAlign: 'right',
   },

   textPorcen: {
      backgroundColor: '#F1F1F1',
      width: 50,
      fontSize: 10,
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: 4,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
   },

   rowContainer: {
      flexDirection: 'row',
      marginTop: 0,
      alignContent: 'center',
   },

   textRow1: {
      width: 100,
      fontSize: 9,
      alignItems: 'center',
      textAlign: 'center',
      borderColor: '#F1F1F1',
      borderWidth: 1,
      paddingVertical: 2,
   },

});

function ItbmsValues({ totalTaxes, total }) {
   //Function to populate the table tax
   const taxTable = () => {
      const listTaxes = totalTaxes.map((tax) => (
         <View style={styles.rowContainer}>
            <Text style={styles.textRow1}> {tax.total_base.toFixed(2)}</Text>
            <Text style={styles.textRow1}> {tax.tax} </Text>
            <Text style={styles.textRow1}> {tax.total_tax.toFixed(2)} </Text>
         </View>
      ))
      return listTaxes
   }




   return (
      <>
         {/* Header Table  */}
         <View style={styles.container}>
            <Text style={styles.textTitle}>Desglose ITBMS</Text>
            <View style={styles.tableContainer}>
               <Text style={styles.textNormal}>Monto Base</Text>
               <Text style={styles.textPorcen}>%</Text>
               <Text style={styles.textNormal}>Impuesto</Text>
            </View>
         </View>

         {/* Values  */}
         {/* Row 1 */}
         {taxTable()}

         {/*Total */}
         <View style={styles.rowContainer}>
            <Text style={styles.textRow1}>  </Text>
            <Text style={styles.textTotal}> Total  </Text>
            <Text style={styles.textRow1}> {total}</Text>
         </View>

      </>
   )
}

export default ItbmsValues