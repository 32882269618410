import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaPen } from "react-icons/fa";
import Swal from 'sweetalert2'
import Select from 'react-select';

//api
import { CategoriesFetchAPI } from "../../../api/Categories"

function ModalEditarCategories({id, setEventHandler, panamanianCodes}) {

  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState({
    infoCategories: []
  })
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getCategoryById();

  }
  //Function to get category by id 
  const getCategoryById = async () => {
    try {
      const response = await CategoriesFetchAPI.getcategoriebyid(id)
      console.log(response);
      setCategories({
        infoCategories: [response.data.categoriesbyid]
      })
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  //States of search select 
  const [selectedCodeOption, setSelectedCodeOption] = useState(null);
  //The default option of the select input 
  const defaultOption = [{ label: 'Seleccione...', value: null }];

  //Get codes 
  const listPanamanianCodes = panamanianCodes.map((element) => ({
    label: element.name,
    value: element._id
  }));

  //Event to detect the panamanian selected on search select 
  const handleChangePanamianCodes = (option) => {
    //We check if the option is null 
    setSelectedCodeOption(option)
  };



  const submit = async (event) => {

    event.preventDefault()
    const form = new FormData(event.target)
    form.append("dcodcpbscmp_id", selectedCodeOption.value);
    Object.fromEntries(form.entries())

    try {
      const response = await CategoriesFetchAPI.updateCategories(id, form)
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
      //We reolad the table 
      setEventHandler(1)
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    //We close the modal 
    handleClose()
  }



  const forminfo = () => {
    let getdata = categories.infoCategories;

    let info = getdata.map((data) => (
      <Form onSubmit={submit} encType="multipart/form-data">
        {/* Input de nombre  */}
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder={data.name}
            defaultValue={data.name}
            name="name"
            required
            autoFocus
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="panamaniancode">
              <Form.Label>Codigo panameño</Form.Label>
              <Select
                className='w-100'
                value={selectedCodeOption}
                options={[defaultOption[0], ...listPanamanianCodes]}
                onChange={handleChangePanamianCodes}
              />
            </Form.Group>
        {/* Input de imagen  */}
        <Form.Group className="mb-3" controlId="clientId">
          <Form.Label>Seleccione una imagen </Form.Label>
          <Form.Control
            type="file"
            placeholder="Imagen"
            name="files"
          />
        </Form.Group>

        <div className="mt-3 d-flex justify-content-end">
          <Button size="sm" variant="success" type="submit">
            Guardar cambios
          </Button>
        </div>
      </Form>
    ))
    return (
      <>
        {info}
      </>
    )
  }



  return (
    <>
      <button className=' btn btn-secondary-us bg-btn-secundary' onClick={handleShow}>
        <FaPen className="text-secondary-us " ></FaPen>
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Categoria </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {forminfo()}
        </Modal.Body>
      </Modal>

    </>
  )
}

export default ModalEditarCategories