import React, { useEffect, useState } from "react";
//Components App
import Footer from "../Footer/Footer";
import Nabvar from "../Navbar/Navbar";
import Header from "../Navbar/Header";
//External components
import ModalCredentials from "./ModalCredentials";
//api
import { BusinessFetchAPI } from '../../../api/Business';

function ListCredentials() {
    const name_label = "Menú Principal  ";
    //To set business credentials 
    const [credentialsBusiness, setCredentialsBusiness] = useState([]);
    const [data, setBusinessData] = useState()

    //Function to get business credentials
    const getBusinessCredentials = async () => {
        try {
            const response = await BusinessFetchAPI.getBusinessCredentialsToAprove();
            setCredentialsBusiness(response.data.business);
        } catch (err) {
            console.log(err)
        }
    }

    //We generate the business list credentials 
    const generateListBusinessCredentials = () => {
        let credentialsData = credentialsBusiness;
        console.log(credentialsData);
        let rows = credentialsData.map((credential) => (
            <div className="col-lg-12 col-sm-12 mt-3 " key={credential._id}>
                <div className="list-group-checkable ">

                    <label className="list-group-item ">
                        <div className=" d-flex justify-content-between align-items-center p-2">
                            <span className="d-block  opacity-80">
                                Empresa:&nbsp; {credential.name}&nbsp;
                                Ruc: &nbsp;{credential.ruc} &nbsp;
                                Dv: &nbsp;{credential.dv} &nbsp;
                            </span>

                            <div className="card-up d-flex justify-content-between small opacity-75">
                                <ModalCredentials data={credential}></ModalCredentials>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        ));
        return (
            <>
                {rows}
            </>
        )
    }


    useEffect(() => {
        getBusinessCredentials();
    }, []);

    return (
        <>
        <Header></Header>
            <div className="mt-5 ">
                <div className="container">
                    <div className="head-section-us mt-4 py-lg-5 py-sm-2  ">
                        <div className="text-center mb-3">
                            <h2 className="main-title"> Lista de credenciales </h2>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-content-center gap-1 mt-4">
                        <h5 className="sub-title">Lista de solicitudes de credenciales</h5>
                    </div>
                </div>
            </div>
            <section className="data-section-us">
                <div className="container ">
                    {/* Carga de datos  */}
                    {generateListBusinessCredentials()}
                </div>
            </section>

            <Footer></Footer>
        </>
    );
}


export default ListCredentials;
