import React, { useContext, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { FaPen } from "react-icons/fa";
import Form from "react-bootstrap/Form";
//Sweet alert
import Swal from "sweetalert2";

//API
import { BusinessFetchAPI } from '../../../../api/Business';


//Function to edit company
function EditCompany() {

    const [businessdata, setBusinessData] = useState({});

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let datastorage = JSON.parse(localStorage.getItem('userData'));
    let business_id = datastorage.Business._id
    let businessData = datastorage.Business

    //We get the business data 
    const getBusiness = async () => {
        try {
            const response = await BusinessFetchAPI.Businessbyid(business_id);
            setBusinessData(response.data.Buisnessbyid)

        } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: error.data.message,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }

    useEffect(() => {
        (async () => {
            try {
                getBusiness();
            } catch (err) {
                console.log(err)
            }
        })()
    }, []);


    //Function event to update a company 
    const submit = async (event) => {
        event.preventDefault()
        const form = new FormData(event.target);
        let updateBusiness = Object.fromEntries(form.entries());
        try {

            //We validate if the user send the certificates data 
            if (updateBusiness.conf_cer !== '' && updateBusiness.conf_cer_pass !== '' && updateBusiness.pac_pass !== '' && updateBusiness.pac_qr !== '') {
                updateBusiness.edit_fe_credentials = true
            } else {
                updateBusiness.edit_fe_credentials = false
            }

            const update_response = await BusinessFetchAPI.Updatebusiness(business_id, updateBusiness)

            //Alert
            Swal.fire({
                icon: 'success',
                title: update_response.data.message,
            });

        } catch (err) {
            console.log(err);
            //Error
            Swal.fire({
                icon: 'success',
                title: err.response.data.error,
            });
        }
    }


    return (
        <>
            <button className='btn btn-light text-secondary-us border-sp' onClick={handleShow}>
                <span className='font-us label-btn'>
                    <FaPen className='icon-m' />
                    Editar Datos
                </span>
            </button>

            <Modal show={show} onHide={handleClose}>
            <div class="header-modal-sp">
                    <div class="btn-close-modal-sm d-flex justify-content-end">
                        <button type="button" class="btn-close m-1" title=" Cerrar " onClick={handleClose} > </button>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <h4 class="title-modal"> Editar datos de empresa </h4>
                    </div>
                </div>
                <Modal.Body>
                    <Form className="needs-validation" onSubmit={submit} encType='multipart/form-data'>
                        <div className="row mb-3">
                            <label htmlFor="name" className="col-md-4 col-lg-3 col-form-label">Nombre</label>
                            <div className="col-md-8 col-lg-9">
                                <input placeholder={businessdata.name} name="name" type="text" className="form-control" id="name" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="ruc" className="col-md-4 col-lg-3 col-form-label">Ruc</label>
                            <div className="col-md-8 col-lg-9">
                                <input name="ruc" placeholder={businessdata.ruc} type="text" className="form-control" id="ruc" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="dv" className="col-md-4 col-lg-3 col-form-label">Dv</label>
                            <div className="col-md-8 col-lg-9">
                                <input name="dv" placeholder={businessdata.dv} type="text" className="form-control" id="dv" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="phone" className="col-md-4 col-lg-3 col-form-label">Numero de empresa</label>
                            <div className="col-md-8 col-lg-9">
                                <input name="phone" placeholder={businessdata.phone} type="text" className="form-control" id="business_phone" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="phone" className="col-md-4 col-lg-3 col-form-label">Tipo de credenciales de factura electronica</label>
                            <div className="col-md-8 col-lg-9">
                                <input name="" type="text" className="form-control" id="" disabled value={businessData.fe_credentials.credentials_type} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="phone" className="col-md-4 col-lg-3 col-form-label">PAC Usuario (RUC)</label>
                            <div className="col-md-8 col-lg-9">
                                <input name="" type="text" className="form-control" id="" disabled value={businessData.fe_credentials.pac.user} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="phone" className="col-md-4 col-lg-3 col-form-label">PAC Contra</label>
                            <div className="col-md-8 col-lg-9">
                                <input name="pac_pass" placeholder={businessData.fe_credentials.pac.pass} type="text" className="form-control" id="" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="phone" className="col-md-4 col-lg-3 col-form-label">PAC QR</label>
                            <div className="col-md-8 col-lg-9">
                                <input name="pac_qr" placeholder={businessData.fe_credentials.pac.qr} type="text" className="form-control" id="" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="phone" className="col-md-4 col-lg-3 col-form-label">Certificado</label>
                            <div className="col-md-8 col-lg-9">
                                <input name="conf_cer" placeholder={businessData.fe_credentials.conf.cer} type="text" className="form-control" id="" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="phone" className="col-md-4 col-lg-3 col-form-label">Contra del Certificado</label>
                            <div className="col-md-8 col-lg-9">
                                <input name="conf_cer_pass" placeholder={businessData.fe_credentials.conf.pass} type="text" className="form-control" id="" />
                            </div>
                        </div>
                        <div className="text-center d-flex justify-content-end">
                            <button type="submit" className="btn btn-sm button-us">Guardar</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditCompany;
