import React, { useState } from 'react';

//Components
import Swal from 'sweetalert2'

//Api 
import { OcrFetchAPI } from '../../../api/Ocr';

function UploadFile() {
    const [invoice, setInvoice] = useState({
        title: '',
        photo: null
    });

    const [img, setImg] = useState({
        name: '',
        file: null,
        url: ''
    });

    const [invoiceData, setInvoiceData] = useState({
        invoice: null
    });

    //Event to upload something to AWS S3
    const submit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);
        Object.fromEntries(form.entries());
        try {
            const response = await OcrFetchAPI.uploadInvoice(form);
            setInvoiceData({ invoice: JSON.stringify(response.data.AWS_JSON) })
            Swal.fire({
                icon: 'success',
                title: response.data.message
            })
            console.log(invoiceData);
        } catch (err) {
            console.log(err)
            Swal.fire({
                icon: "error",
                title: err.response.data.message
            })
        }
    }

    //Function to update precharge the img on the front 
    const inputChange = (e) => {
        //We make an url of the photo
        let url = URL.createObjectURL(e.photo);
        //We make the img object
        let uploadImg = {
            name: e.photo.name,
            file: e.photo,
            url
        }
        //We set the img obj
        setImg(uploadImg);
        //We set the invoice obj
        setInvoice({ ...invoice, photo: e.photo })

    }

    return (
        <div>
            <form onSubmit={submit} encType='multipart/form-data'>
                <input type="text" placeholder='title' name='title'
                    onChange={e => setInvoice({ ...invoice, title: e.target.value })}
                />
                <input type="file" name="files" id=""
                    onChange={e => inputChange({ ...invoice, photo: e.target.files[0] })}
                />

                <button type='submit'>Subir</button>
            </form>
            <img src={img.url} />

            <div>
                {invoiceData.invoice}
            </div>
        </div>
    )
}

export default UploadFile