import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': "application/json"}, 
});

export class ClientFetchAPI {
    static async newClient(data){
        const res = await server ("/client", {
            method: "post",
            data: data,
        });
        return res;
    }

    static async getClientsByBusiness(business_phone){
        const res = await server (`/clients/${business_phone}/clients_business`, {
            method: "get",
        });
        return res;
    }

    static async getClientsByid(id) { 
        const res = await server(`/clientbyid/${id}`, { 
            method: 'get'
        }); 
        return res
    }

    static async getSpecificClient(business_phone, client_position){
        const res = await server (`/clients/${business_phone}/clients_business/${client_position}/client_position`, {
            method: "get"    
        });
        return res;
    }

    static async deleteClient(business_phone, client_id){
        const res = await server(`/clients/${business_phone}/clients_business`, {
            method: "delete",
            data: {client_id: client_id} 
        });

        return res;
    }
    static async updateClient (data){
        const res = await server(`/client`, {
            method: 'PUT', 
            data: data
        });
        return res;
    }
}
