import React, { useEffect, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer';
import Modal from 'react-bootstrap/Modal';
import Header from '../Navbar/Header';
import Footer from "../Footer/Footer";
//Components App
import ButtonExit from "../Buttons/ButtonExit";
import Swal from 'sweetalert2';
import DocPdf from '../Pdf/DocPdf';
import LoadingAlert from '../../Alerts/LoadingAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
//Component BSt
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from 'react-bootstrap/Table';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CardTransaction from '../Transactions/CardTransaction'
import Select from 'react-select'
//Icons
import { FaTrashAlt } from 'react-icons/fa'
import logo from '../../assets/img/logo/logo-srg.png'
//Utils 
import { RenderIf } from '../../../utils/RenderIf';
//API 
import { TipoRucFetchAPI } from '../../../api/dTipoRuc';
import { TipoRecFetchAPI } from '../../../api/iTipoRec';
import { PaymentMethodFetchAPI } from '../../../api/PaymentMethods';
import { TaxesFetchAPI } from '../../../api/Taxes';
import { InvoiceFetchAPI } from '../../../api/Invoice';
import { ProductsFetchAPI } from '../../../api/Products';
import { OrdersFetchAPI } from '../../../api/Orders';
import { ClientFetchAPI } from '../../../api/Clients';
import { CountryFetchAPI } from '../../../api/Countries';

const Form_biller = () => {
  //Variables 
  const [paso, setPaso] = useState(1);
  const [show, setShow] = useState(false);
  const obl = <span className="text-danger"> * </span>;
  let userLogged = JSON.parse(localStorage.getItem("userData"));
  let business_info = userLogged.Business;
  let token = localStorage.getItem('ushopper-access-token')
  //Global data 
  const [rucTypes, setRucTypes] = useState([]);
  const [receptorType, setReceptorTypes] = useState([]);
  const [paymentMetods, setPaymentMethods] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [nextPaymentDate, setNextPaymentDate] = useState(new Date());
  const [businessProducts, setBusinessProducts] = useState([]);
  const [countries, setCountries] = useState([]);
  //Form Fields
  let [productsList, setProducts] = useState([]);
  let [eventHandler, setEventHandler] = useState(0)
  let [invoiceTotal, setInvoiceTotal] = useState(0);
  let [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
  let [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
  let [invoiceDiscountTotal, setInvoiceDiscountTotal] = useState(0)
  //Client info 
  let [clientName, setClientName] = useState();
  let [clientRuc, setClientRuc] = useState();
  let [clientTypeRuc, setClientTypeRuc] = useState();
  let [clientPaymentMethod, setClientPaymentMethod] = useState('null');
  let [clientDV, setClientDV] = useState();
  let [clientDirection, setClientDirection] = useState();
  let [clientType, setClientType] = useState();
  let [countrySelected, setCountrySelected] = useState('PA')
  //Payment info
  let [totalCashReceived, setTotalCashReceived] = useState(0);
  let [clientPaymentType, setClientPaymentType] = useState('null');
  let [productSelected, setProduct] = useState('null');
  let [businessClients, setBusinessClients] = useState([]);

  //invoice dgi 
  let [cufe, setCufe] = useState();
  let [authDGI, setAuthDGI] = useState();
  let [invoiceDate, setInvoiceDate] = useState()
  let [invoiceQR, setInvoiceQR] = useState();
  let [invoiceCode, setInvoiceCode] = useState('0');
  let [quoteNumber, setQuoteNumber] = useState(null);

  //Transmitter info
  const [emmiterData, setEmmiterData] = useState({
    "ruc": business_info.fe_credentials.pac.user,
    "dv": business_info.dv,
    "name": business_info.name,
    "ruc_type": business_info.business_ruc_type.type,
    "direction": business_info.direction
  });
  //Selected option
  const [selectedOption, setSelectedOption] = useState(null);
  //Receptor info 
  const [receptorData, setReceptorData] = useState({
    name: "",
    ruc: "",
    direction: "",
    dv: "",
    ruc_type: "",
    type: ""
  })

  //Total taxes 
  const [totalTaxes, setTotalTaxes] = useState([
    { total_base: 0, tax: "0%", total_tax: 0 },
    { total_base: 0, tax: "7%", total_tax: 0 },
    { total_base: 0, tax: "10%", total_tax: 0 },
    { total_base: 0, tax: "15%", total_tax: 0 }
  ]);



  //For the modal 
  const handleClose = () => {
    //We close the modal
    setShow(false);
    //We clean the form
    cleanForm();
  }
  const handleShow = () => setShow(true)

  //Function to get the initial data 
  const getInitialData = async () => {
    try {
      //We get the ruc types 
      const tiporuc = await TipoRucFetchAPI.getTipoRuc();
      //We set the ruc types 
      setRucTypes(tiporuc.data.dTipoRuc);
      //We get the receptor types 
      const tiporeceptor = await TipoRecFetchAPI.getTipoRec();
      //We set the receptor types 
      setReceptorTypes(tiporeceptor.data.iTipoRec);
      //We get the paymentmethods 
      const paymentmethods = await PaymentMethodFetchAPI.getPaymentMethods();
      //We set the paymentmethods 
      setPaymentMethods(paymentmethods.data.paymentMethod);
      //We get the taxes 
      const taxes = await TaxesFetchAPI.getTaxes();
      setTaxes(taxes.data.taxes);
      //We get the business products 
      const products = await ProductsFetchAPI.getProductsByBusiness(business_info.phone, token);
      setBusinessProducts(products.data.business_products.products)
      //We get the countries
      const countriesData = await CountryFetchAPI.getCountries(token);
      //We set the countries
      setCountries(countriesData.data.countries)
    } catch (err) {
      console.log(err)
    }
  }

  //Function to add a product 
  const addProduct = async (e) => {
    e.preventDefault();

    //we validate that the user select a product
    if (productSelected === 'null') {
      ErrorAlert('Seleccione un producto', 'Error', 'warning')
    } else {
      //If the switch is checked it means that the product exist so we get the product of the server 
      const product_selected = await ProductsFetchAPI.getProductsById(productSelected.value, token);

      //We calculate the total product itbms 
      let total_product_itbms = 1 * (product_selected.data.product.tax.value * (product_selected.data.product.price - 0))

      //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
      let subtotal_product = (1 * (product_selected.data.product.price - 0));

      //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
      let total_product = (1 * (product_selected.data.product.price - 0)) + total_product_itbms;

      //We get the itbms an we calculate the total itbms 
      invoiceTotalItbms = invoiceTotalItbms + (1 * ((product_selected.data.product.tax.value * (product_selected.data.product.price - 0))));
      setInvoiceTotalItbms(invoiceTotalItbms);

      //We calculate the subtotal 
      invoiceSubtotal = invoiceSubtotal + subtotal_product;
      setInvoiceSubtotal(invoiceSubtotal);

      //We calculate the total of discounts 
      invoiceDiscountTotal = invoiceDiscountTotal + 0;
      setInvoiceDiscountTotal(invoiceDiscountTotal)

      //We calculate the total  of the transaction
      invoiceTotal = invoiceSubtotal + invoiceTotalItbms;
      setInvoiceTotal(invoiceTotal)

      //We add the products 
      setProducts([...productsList, {
        "name": product_selected.data.product.name,
        "code": product_selected.data.product.upc ? product_selected.data.product.upc : '0000',
        "cant": 1,
        "price": product_selected.data.product.price,
        "tax": product_selected.data.product.tax.dTasaITBMSName,
        "total_product_itbms": total_product_itbms,
        "total_discount": 0,
        "subtotal_product": subtotal_product,
        "total_product": total_product,
        "product": productSelected.value,
        "exist_product": true,
        "panamanianCode": product_selected.data.product.categorie.dCodCPBScmp.code
      }]);
    }
  }

  //Function to delete a product of the table preview 
  const deleteProduct = async (product_index) => {
    let product = productsList[product_index];

    if (product.exist_product === false) {
      //We get the itbms and we calculate the total itbms
      let itmb_value = product.tax.split('%')[0] / 100;
      let product_itbms = (product.cant * (itmb_value * (product.price - product.total_discount)));
      invoiceTotalItbms = invoiceTotalItbms - product_itbms
      //Subtotal
      invoiceSubtotal = invoiceSubtotal - product.total_product;
      //total 
      invoiceTotal = invoiceTotal - (product.total_product + product_itbms)
      //We calculate the total of discounts 
      invoiceDiscountTotal = invoiceDiscountTotal - parseFloat(product.total_discount);
    } else {
      //We get the itbms and we calculate the total itbms
      let itmb_value = product.tax.split('%')[0] / 100;
      let product_itbms = product.total_product_itbms;
      invoiceTotalItbms = invoiceTotalItbms - product_itbms
      //Subtotal
      invoiceSubtotal = invoiceSubtotal - product.subtotal_product;
      //total 
      invoiceTotal = invoiceTotal - product.total_product;
      //We calculate the total of discounts 
      invoiceDiscountTotal = invoiceDiscountTotal - parseFloat(product.total_discount);

    }
    //We set the totals
    setInvoiceSubtotal(invoiceSubtotal);
    setInvoiceTotal(invoiceTotal);
    setInvoiceTotalItbms(invoiceTotalItbms);
    setInvoiceDiscountTotal(invoiceDiscountTotal)

    //We delete the product 
    productsList.splice(product_index, 1);
    setProducts([...productsList]);
  }

  //Function to render the table preview invoice table 
  const tableProduct = () => {
    let productData = productsList;
    let rows = productData.map((product, index) => (
      <tr key={index}>
        <td>{product.name}</td>
        <td>{product.code}</td>
        <td>
          <input
            type='number'
            defaultValue={product.cant}
            className='form-control'
            onChange={(e) => handleEditTableProduct(index, "cant", e.target.value)}
          /></td>
        <td>
          <input type="number"
            defaultValue={product.price}
            className='form-control'
            onChange={(e) => handleEditTableProduct(index, "price", e.target.value)}
          />
        </td>
        <td>

          <select className='form-select' aria-label="Select payment type"
            onChange={(e) => handleEditTableProduct(index, "tax", e.target.value)}
            value={product.tax}>

            <option value="7%">7%</option>
            <option value="0%">0%</option>
            <option value="10%">10%</option>
            <option value="15%">15%</option>
          </select>
        </td>
        <td>
          <input
            type='number'
            defaultValue={product.total_discount}
            onChange={(e) => handleEditTableProduct(index, "total_discount", e.target.value)}
            className='form-control'
          />
        </td>
        <td>{product.total_product.toFixed(2)}</td>
        <td>
          <button onClick={(e) => deleteProduct(index)} className="btn-danger-us btn text-secondary bg-btn-secundary ">
            <FaTrashAlt />
          </button>
        </td>
      </tr>
    ))
    return (
      <>
        {rows}
      </>
    )
  }

  //Function to edit the table product 
  const handleEditTableProduct = (indexCell, cellName, cellValue) => {
    //We create a new array with the edit product
    const editProducts = productsList.map((product, index) => {
      let newProductCant = product.cant;
      let newProductDiscount = product.total_discount;
      let newProductPrice = product.price;
      //We get the itbms and we calculate the total itbms
      let itmb_value = product.tax.split('%')[0] / 100;

      let total_product_itbms = 0;
      let subtotal_product = 0
      let total_product = 0
      //We check if we want to update the amount of the product
      if (cellName === 'cant') {
        newProductCant = cellValue;
        //We validate that the cant comes like an string
        if (newProductCant === '' || newProductCant < 1) {
          newProductCant = 1
        }
      }

      //We check if we want to update the tax of the product
      if (cellName === "tax") {
        itmb_value = cellValue.split('%')[0] / 100;
      }

      //We check if we want to update the total discount of the product
      if (cellName === "total_discount") {
        newProductDiscount = cellValue
        //We validate that the discount comes like an string
        if (newProductDiscount === '' || newProductDiscount < 0) {
          newProductDiscount = 0
        }
      }

      //We check if we want to update the price of the product
      if (cellName === "price") {
        newProductPrice = cellValue
        //We validate that the price comes like an string
        if (cellValue === '' || cellValue < 0) {
          newProductPrice = 0
        }
      }

      //We found the product to edit we return it 
      if (index === indexCell) {
        //We calculate the total product itbms 
        total_product_itbms = newProductCant * (itmb_value * (newProductPrice - newProductDiscount))
        //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
        subtotal_product = (newProductCant * (newProductPrice - newProductDiscount));
        //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
        total_product = (newProductCant * (newProductPrice - newProductDiscount)) + total_product_itbms;
        return {
          ...product,
          [cellName]: cellValue,
          "subtotal_product": subtotal_product,
          "total_product": total_product,
          "total_product_itbms": total_product_itbms,
          "total_discount": newProductDiscount
        };
      }
      //We return the product 
      return product;
    });
    //We set the products edited
    setProducts(editProducts);
    //We reload the component
    setEventHandler(1)
  };

  //Function to calculate the invoice totals 
  const calculateInvoiceTotals = (products) => {
    //For every new calculate we set the totals 
    let invoiceDiscountTotal = 0
    let invoiceSubtotal = 0
    let invoiceTotalItbms = 0
    let invoiceTotal = 0

    //if we dont we products we put the totals in 0 for default 
    if (products.length === 0) {
      //We calculate the totals of the invoice 
      setInvoiceDiscountTotal(0)
      setInvoiceSubtotal(0);
      setInvoiceTotalItbms(0)
      setInvoiceTotal(0);
    } else {

      //We reset the total taxes
      let TotalTaxes = [
        { total_base: 0, tax: "0%", total_tax: 0 },
        { total_base: 0, tax: "7%", total_tax: 0 },
        { total_base: 0, tax: "10%", total_tax: 0 },
        { total_base: 0, tax: "15%", total_tax: 0 }
      ]


      //For every product we calculate 
      for (let i = 0; i < products.length; i++) {
        let product_info = products[i]
        //We get the itbms and we calculate the total itbms
        let itmb_value = product_info.tax.split('%')[0] / 100;
        //Unit discount
        let unitItemDiscount = product_info.total_discount;
        //We validate that the discount comes like an string 
        if (typeof unitItemDiscount === 'string') {
          unitItemDiscount = parseFloat(product_info.total_discount)
        }
        //Variables
        let total_product_price = 0;
        let total_product_itbms = 0;
        let product_total_discount = 0;
        //We set the total discount of the product (we calculate the total discount of the products because the product.total_discount is the unit discount)
        product_total_discount = unitItemDiscount * product_info.cant
        //We set the total of the product 
        total_product_price = (product_info.cant * (product_info.price - unitItemDiscount));
        //We set the itbms of the product 
        total_product_itbms = (product_info.cant * ((product_info.price - unitItemDiscount) * itmb_value));

        //We calculate the totals of the invoice 
        invoiceDiscountTotal = invoiceDiscountTotal + product_total_discount;
        invoiceSubtotal = invoiceSubtotal + total_product_price;
        invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
        invoiceTotal = invoiceTotal + total_product_price + total_product_itbms;

        //We get the index of the tax
        let indexTax = TotalTaxes.map(value => value['tax']).indexOf(product_info.tax)

        //If the paymenthod exist we just affect the total of it 
        TotalTaxes[indexTax].total_tax = TotalTaxes[indexTax].total_tax + total_product_itbms;
        TotalTaxes[indexTax].total_base = TotalTaxes[indexTax].total_base + total_product_price;
      }

      invoiceTotal = Math.round(invoiceTotal * 100) / 100;
      //We set the total taxes
      setTotalTaxes(TotalTaxes)
      //We set the totals
      setInvoiceTotalItbms(invoiceTotalItbms);
      setInvoiceSubtotal(invoiceSubtotal);
      setInvoiceDiscountTotal(invoiceDiscountTotal)
      setInvoiceTotal(invoiceTotal)
    }
  }

  //We get the fields to make the invoice 
  const createInvoice = async () => {
    //We show the loading alert
    LoadingAlert('Generando factura', 'Por favor espere')
    try {
      //We validate the fields 
      if (productsList.length === 0) {
        Swal.fire({
          "icon": "error",
          "title": "Error",
          "text": "No ha agregado productos"
        })
      } else if (clientTypeRuc === undefined || clientTypeRuc === 'null') {
        Swal.fire({
          "icon": "error",
          "title": "Error",
          "text": "No ha seleccionado el tipo de ruc del cliente"
        })
      } else if (clientPaymentType === 'null' || clientPaymentType === undefined) {
        Swal.fire({
          "icon": "error",
          "title": "Error",
          "text": "No ha seleccionado el tipo de pago del cliente"
        })
      } else if (clientPaymentMethod === 'null' || clientPaymentMethod === undefined) {
        Swal.fire({
          "icon": "error",
          "title": "Error",
          "text": "No ha seleccionado el metodo de pago del cliente"
        })
      } else if (totalCashReceived === '') {
        Swal.fire({
          "icon": "error",
          "title": "Error",
          "text": "Ingrese el total de dinero recibido por el cliente"
        })
      }
      else {

        //We construc the json to send 
        let json_fe = {
          "pac": {
            "usuario": business_info.fe_credentials.pac.user,
            "pass": business_info.fe_credentials.pac.pass,
            "QR": business_info.fe_credentials.pac.qr
          },
          "conf": {
            "cer": business_info.fe_credentials.conf.cer,
            "pass": business_info.fe_credentials.conf.pass
          },
          "operation_type": "Salida o venta",
          "sell_type": 'Venta de Giro del negocio',
          "document_type": "Factura de Operación Interna",
          "transmitter_ruc": business_info.ruc,
          "transmitter_dv": business_info.dv,
          "transmitter_name": business_info.name,
          "transmitter_ruc_type": business_info.business_ruc_type.type,
          "branch_code": "0000",
          "branch_direction": business_info.direction,
          "transmitter_phone": business_info.phone,
          "transmitter_email": business_info.email,
          "receptor_type": clientType,
          "receptor_name": clientName,
          "receptor_direction": clientDirection,
          "total_cash_received": totalCashReceived,
          "payment_type": clientPaymentType,
          "payment_method": clientPaymentMethod,
          "items": productsList
        }

        //We check if the client is contribuyente or not 
        if (clientTypeRuc !== 'No contribuyente') {
          //if is contribuyent we add the fields of ruc
          json_fe.receptor_type_ruc = clientTypeRuc
          json_fe.receptor_ruc = clientRuc
          json_fe.receptor_dv = clientDV
        } else {
          json_fe.receptor_ruc = clientRuc
        }

        //We set the receptor country code
        json_fe.receptor_country_code = countrySelected

        //We check the payment type of the client
        if (clientPaymentType === 'credito') {
          json_fe.payment_dues = [{
            "next_payment_date": nextPaymentDate.toISOString().split('T')[0] + "T11:56:40-05:00",
            "total_to_pay": invoiceTotal
          }]
        }
        //We check the receptor type ruc 
        if (clientTypeRuc === 'Extranjero' && clientType === 'Extranjero') {
          //We change the document type to exportacion
          json_fe.document_type = "Factura de Exportación"
        }

        //Depending of the credentials type we send the transmitter info 
        if (business_info.fe_credentials.credentials_type === 'demo') {
          json_fe.transmitter_name = "FE generada en ambiente de pruebas - sin valor comercial ni fiscal"
          json_fe.transmitter_ruc = "844084-1-504061"
          json_fe.transmitter_dv = "00"
        }

        try {
          //We generate the electronic invoice 
          const fe_response = await InvoiceFetchAPI.generateFE(json_fe);

          console.log(fe_response)
          //We make the json of the order 
          let json_order = {
            "business_phone": business_info.phone,
            "business_ruc": business_info.ruc,
            "operation_type": "Salida o venta",
            "sell_type": "Venta de Giro del negocio",
            "document_type": "Factura de Operacion Interna",
            "branch_code": "0000",
            "branch_direction": business_info.direction,
            "receptor_type": clientType,
            "receptor_name": clientName,
            "receptor_direction": clientDirection,
            "total_cash_received": totalCashReceived,
            "payment_type": clientPaymentType,
            "payment_method": clientPaymentMethod,
            "invoice_type": "Salida o venta",
            "products": productsList,
            "cufe": fe_response.data.data.CUFE,
            "Qr": fe_response.data.data.QR,
            "code": fe_response.data.order_code,
            "auth_protocol": fe_response.data.data.AU,
            "quote_number": quoteNumber
          }

          //We check if the client is contribuyente or not 
          if (clientTypeRuc !== 'No contribuyente') {
            //if is contribuyent we add the fields of ruc
            json_order.receptor_type_ruc = clientTypeRuc
            json_order.receptor_ruc = clientRuc
            json_order.receptor_dv = clientDV
          } else {
            json_order.receptor_ruc = clientRuc
          }
          //We set the receptor country code 
          json_order.receptor_country_code = countrySelected

          //We check the receptor type ruc 
          if (clientTypeRuc === 'Extranjero' && clientType === 'Extranjero') {
            //We change the document type to exportacion
            json_order.document_type = "Factura de Exportación"
          }

          //We check the payment type of the client
          if (clientPaymentType === 'credito') {
            json_order.payment_dues = [{
              "next_payment_date": nextPaymentDate.toISOString().split('T')[0] + "T11:56:40-05:00",
              "total_to_pay": invoiceTotal
            }]
          }

          try {
            //We register the transaction on the database 
            await OrdersFetchAPI.newOrder(json_order);
            //Alert 
            Swal.fire({
              icon: "success",
              title: 'Factura electronica generada'
            });

            //After save the info we set the receptor info on the state 
            setReceptorData({
              name: clientName,
              ruc: clientRuc,
              direction: clientDirection,
              dv: clientDV,
              ruc_type: clientTypeRuc,
              type: clientType,
              receptor_country_code: countrySelected
            });
            //We set the dgi info 
            setCufe(fe_response.data.data.CUFE)
            setAuthDGI(fe_response.data.data.AU)
            setInvoiceDate(fe_response.data.data.dFecProc)
            setInvoiceQR(fe_response.data.data.QR)
            setInvoiceCode(fe_response.data.order_code)
            //We show the modal of pdf 
            handleShow()

            //We refresh the page and clean the states 
            //cleanForm()
          } catch (err) {
            //Alert 
            Swal.fire({
              icon: "error",
              title: 'Error',
              text: `${err}`
            });
          }

        } catch (err) {
          console.log(err)
          let error = err.response.data.data === undefined || err.response.data.errorMessage !== undefined ? err.response.data.errorMessage : err.response.data.data.codigo_mf_texto
          //Alert 
          Swal.fire({
            icon: "error",
            title: `${err.response.data.error}`,
            text: `${error}`
          });
        }
      }
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: "Error generando factura",
        text: 'Intente nuevamente'
      });

    }
  }

  //We clean the form 
  const cleanForm = () => {
    try {
      //We set the case of the step form on one
      setPaso(1)
      //We refresh the states 
      setSelectedOption(null)
      setProducts([])
      setInvoiceTotal(0)
      setInvoiceTotalItbms(0)
      setInvoiceSubtotal(0)
      setInvoiceDiscountTotal(0)
      setClientName()
      setClientRuc()
      setClientTypeRuc()
      setClientDV()
      setClientDirection()
      setClientType()

      setClientPaymentMethod('null')
      setTotalCashReceived(0)
      setClientPaymentType('null')
      setProduct('null')

      setReceptorData({
        name: "",
        ruc: "",
        direction: "",
        dv: "",
        ruc_type: "",
        type: ""
      });
      //We set the total taxes
      setTotalTaxes([
        { total_base: 0, tax: "0%", total_tax: 0 },
        { total_base: 0, tax: "7%", total_tax: 0 },
        { total_base: 0, tax: "10%", total_tax: 0 },
        { total_base: 0, tax: "15%", total_tax: 0 }
      ]);
      setQuoteNumber(null)

    } catch (err) {
      console.log(err)
    }


  }

  //We get the business clients 
  const getBusinessClients = async () => {
    try {
      const response = await ClientFetchAPI.getClientsByBusiness(business_info.phone);
      setBusinessClients(response.data.business_clients.clients);
    } catch (err) {
      console.log(err)
    }
  }

  //Get clients
  const optionsClients = businessClients.map((client) => ({
    label: client.name,
    value: client._id,
  }));

  //Function to get the client by id
  const handleChangeClients = (option) => {
    setSelectedOption(option);
    getClientById(option.value);
  };

  //Get Products 
  const optionsProducts = businessProducts.map((product) => ({
    label: product.name,
    value: product._id,
  }));

  //Function to set the product
  const handleChangeProducts = (option) => {
    setProduct(option);
  };

  const defaultOption = { label: 'Cliente Genérico', value: '631bb567cc065992d10d0ce5' };
  //Function to get client info by id 
  const getClientById = async (id) => {
    try {
      const response = await ClientFetchAPI.getClientsByid(id)
      console.log(response)
      const client_info = response.data.clientbyid
      //We set the client info 
      setClientName(client_info.name);
      setClientTypeRuc(client_info.receptor_client_ruc_type.dTipoRucName);
      setClientType(client_info.receptor_type.iTipoRecname)
      setClientRuc(client_info.ruc)
      setClientDV(client_info.dv)
      setClientDirection(client_info.Direccion)

    } catch (error) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  //We charge the initial info 
  useEffect(() => {
    (async () => {
      try {
        getInitialData();
        getBusinessClients()
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  //We recharge the table preview 
  useEffect(() => {
    calculateInvoiceTotals([...productsList])
    setProducts([...productsList]);
    setEventHandler(0)
  }, [eventHandler]);


  //Function to send the next step 
  const nextStep = (step) => {
    //We check the step 
    if (step === 2) {
      //We check if the type of receptor is selected
      if (clientType === null || clientType === 'null' || clientType === undefined) {
        //We show a error
        ErrorAlert('Seleccione el tipo de receptor', 'Error', 'warning')
      }//We check if the client is selected
      else if (clientType === 'Extranjero' && countrySelected === 'PA') {
        //We show a error
        ErrorAlert('Si el tipo de cliente es extranjero debe seleccionar un pais diferente a Panama', 'Error', 'warning')
      }
      else if (selectedOption === null || selectedOption === 'null') {
        //We show a error
        ErrorAlert('Seleccione un cliente', 'Error', 'warning')
      } else {
        //We set the next step 
        setPaso(step)
      }

    } else if (step === 3) {
      //We check if the products list have
      if (productsList.length === 0) {
        //We show a error
        ErrorAlert('Agregue productos', 'Error', 'warning')
      } else {
        //We set the next step
        setPaso(step)
      }
    } else if (step === 4) {
      //We check the client type payment 
      if (clientPaymentType === 'null') {
        //We show a error
        ErrorAlert('Agregue el tipo de pago', 'Error', 'warning')
      } else {
        //We set the next step
        setPaso(step)
      }
    } else if (step === 5) {
      //We check if the client payment method is selected
      if (clientPaymentMethod === 'null') {
        //We show a error
        ErrorAlert('Seleccione el metodo de pago', 'Error', 'warning')
      } else {
        //We check the cash received
        if (totalCashReceived < parseFloat(invoiceTotal.toFixed(2))) {
          //We show a error
          ErrorAlert('El total recibido es menor al total de la factura', 'Error', 'warning')
        } else {
          //We set the next step
          setPaso(step)
        }
      }
    }
  }

  //We populate the form step 
  const stepForm = () => {
    switch (paso) {
      case 1:
        return (
          <>

            <div className='text-center'>
              <small className="fw-bold text-secondary">Paso {paso}</small>
              <p className='h4  my-2'>
                Seleccione Cliente  a facturar
              </p>
            </div>
            <div className='d-flex justify-content-center'>
              <Select
                className='w-50'
                value={selectedOption}
                options={[defaultOption, ...optionsClients]}
                onChange={handleChangeClients}
              />
            </div>

            <Form>
              <Row className='mt-4'>
                <h5>Datos del cliente </h5>
                <div className='d-flex justify-content-end mb-2'>
                  <small className='text-muted opacity-75'> <span className='text-danger'> * </span>  Campos Obligarotios </small>
                </div>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <label className='fact-label-form'>Nombre{obl} </label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese"
                      name="client_name"
                      id="client_name"
                      onChange={(e) => setClientName(e.target.value)}
                      value={clientName}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="">
                    <label className='fact-label-form'>Tipo de RUC{obl} </label>
                    <Form.Select aria-label="Select payment type"
                      id='client-type-ruc'
                      value={clientTypeRuc}
                      onChange={(e) => {
                        setClientTypeRuc(e.target.value)
                        //We check what type of client type ruc send the user and then predifine the receptor client
                        if (e.target.value === 'No contribuyente') {
                          setClientType('Consumidor final');
                          //Client type
                          document.getElementById("client_type").value = 'Consumidor final';
                          document.getElementById('client_type').disabled = 'true';

                          //Client dv 
                          document.getElementById('client_dv').value = '';
                          document.getElementById('client_dv').disabled = true;
                          document.getElementById('client_dv').placeholder = 'No contribuyente';

                          //Client country
                          setCountrySelected('PA')
                          document.getElementById('country').disabled = true;

                        } else if (e.target.value === 'Extranjero') {
                          //Client type
                          setClientType('Extranjero');
                          document.getElementById('client_type').disabled = 'true';

                          //Client dv 
                          document.getElementById('client_dv').value = '';
                          document.getElementById('client_dv').disabled = true;
                          document.getElementById('client_dv').placeholder = 'Extranjero';

                          //Client country
                          document.getElementById('country').removeAttribute('disabled');

                        } else {
                          setClientType('Contribuyente');
                          document.getElementById("client_type").value = 'null';
                          document.getElementById('client_type').removeAttribute('disabled')
                          //Client dv
                          document.getElementById('client_dv').value = '';
                          document.getElementById('client_dv').removeAttribute('disabled');
                          document.getElementById('client_dv').placeholder = 'Ingrese';
                        }
                      }}
                      name="clientTypeRuc">
                      <option value="null">Seleccione...</option>
                      {rucTypes.map(rucTypes => (
                        <option key={rucTypes._id} value={rucTypes.dTipoRucName}>
                          {rucTypes.dTipoRucName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="mb-3" controlId="client_ruc">

                    {/** Label of Client ruc  */}
                    <RenderIf isTrue={clientTypeRuc === 'No contribuyente'}>
                      <label className='fact-label-form'>Cedula  {obl} </label>
                    </RenderIf>
                    <RenderIf isTrue={clientTypeRuc !== 'No contribuyente'}>
                      <label className='fact-label-form'>RUC{obl} </label>
                    </RenderIf>
                    {/** END Label of Client ruc  */}

                    <Form.Control
                      type="text"
                      placeholder="Ingrese"
                      name="client_ruc"
                      controlid="client_ruc"
                      onChange={(e) => setClientRuc(e.target.value)}
                      value={clientRuc}

                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="mb-3" controlId="client_dv">
                    <label className='fact-label-form'>DV{obl} </label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese"
                      name="client_dv"
                      controlid="client_dv"
                      onChange={(e) => setClientDV(e.target.value)}
                      value={clientDV}

                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="mb-3" controlId="">
                    <label className='fact-label-form'>Tipo de receptor {obl} </label>
                    <Form.Select aria-label="Select payment type" name="client_type" id="client_type"
                      value={clientType}
                      onChange={(e) => setClientType(e.target.value)}>
                      <option value="null">Seleccione...</option>
                      {receptorType.map(receptorType => (
                        <option key={receptorType._id} value={receptorType.iTipoRecname}>
                          {receptorType.iTipoRecname}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="mb-3" controlId="">
                    <label className='fact-label-form'>Seleccione pais  {obl} </label>
                    <Form.Select aria-label="Select payment type"
                      id='country'
                      name="country"
                      value={countrySelected}
                      onChange={(e) => {
                        setCountrySelected(e.target.value)
                      }}>
                      {countries.map(country => (
                        <option key={country._id} value={country.iso2}>
                          {country.translations.es === "" ? country.name : country.translations.es}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <label className='fact-label-form'>Dirección </label>
                    <Form.Control as="textarea" id='client-direction' value={clientDirection} onChange={(e) => setClientDirection(e.target.value)} rows={2} />
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group className="mb-3" controlId="client_dv">
                    <label className='fact-label-form'>Numero de orden de compra </label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese"
                      name="quote_number"
                      controlid="quote_number"
                      onChange={(e) => setQuoteNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <div className='d-flex justify-content-end mb-4'>
              <button className="btn btn-primary" onClick={() => nextStep(paso + 1)}>Siguiente</button>
            </div>

          </>
        );

      case 2:
        return (
          <>
            <div className='text-center'>
              <small className="fw-bold text-secondary">Paso {paso}</small>
              <p className='h4  my-2'>
                Agregar Productos a Facturar
              </p>
            </div>
            <div>
              {/**Product select  */}
              <div className='d-flex justify-content-between my-4'>
                <Select
                  className='w-75'
                  value={productSelected}
                  options={optionsProducts}
                  onChange={handleChangeProducts}
                />
                <Button type="button" onClick={addProduct} variant="success">Agregar Producto </Button>
              </div>
            </div>
            {/**Product table  */}
            <Table responsive>
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Código</th>
                  <th>Cantidad </th>
                  <th>Precio</th>
                  <th>Impuesto(itbms)</th>
                  <th>Descuento unitario</th>
                  <th>Total</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {tableProduct()}
              </tbody>
            </Table>

            {/**Totals of the invoice */}
            <div className='d-flex justify-content-end '>
              <div className='flex-column '>
                <div className="py-1 d-flex justify-content-between lh-sm">
                  <div>
                    <h6 className="my-0 pe-5">Subtotal:</h6>

                  </div>
                  <span className="text-muted ps-5">${invoiceSubtotal.toFixed(2)}</span>
                </div>

                <div className="py-1 d-flex justify-content-between lh-sm">
                  <div>
                    <h6 className="my-0">Total impuestos:</h6>

                  </div>
                  <span className="text-muted ps-5">${invoiceTotalItbms.toFixed(2)}</span>
                </div>

                <div className="py-1 d-flex justify-content-between lh-sm ">
                  <div>
                    <h6 className="my-0">Total:</h6>

                  </div>
                  <span className="text-muted ps-5">${invoiceTotal.toFixed(2)}</span>
                </div>
              </div>
            </div>

            {/** Buttons of next step */}
            <div className='d-flex justify-content-between mb-4'>
              <button className="btn btn-light text-secondary" onClick={() => setPaso(paso - 1)}>Anterior</button>
              <button className="btn btn-success" onClick={() => nextStep(paso + 1)}>Confirmo</button>
            </div>

          </>


        );
      case 3:
        return (
          <>
            <div className='text-center'>
              <small className="fw-bold text-secondary">Paso {paso}</small>
              <p className='h4  my-2'>
                Tipo de Pago
              </p>
              <div className='d-flex justify-content-center'>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Select
                      className='form-select'
                      aria-label="Select payment type"
                      name="clientPaymentType"
                      id="client_payment_type"
                      defaultValue={clientPaymentType}

                      onChange={(e) => {
                        //We set the client payment type
                        setClientPaymentType(e.target.value);
                      }}>
                      <option value="null">Seleccione...</option>
                      <option value="contado">Contado</option>
                      <option value="credito">Crédito</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <br />
              </div>
              <div className='d-flex justify-content-center'>
                {/** Next payment field  */}
                <RenderIf isTrue={clientPaymentType === 'credito'}>
                  <div id="div-nextPaymentDate" >
                    <Form.Group className="mb-3 px-5" controlId="nextpaymentdate">
                      <Form.Label>Fecha de pago {obl} </Form.Label>
                      <DatePicker
                        className='form-control'
                        id='nextpaymentdate'
                        selected={nextPaymentDate}
                        onChange={(date) => setNextPaymentDate(date)}
                      />
                    </Form.Group>
                  </div>
                </RenderIf>
              </div>
              <div className='d-flex justify-content-between mb-4'>
                <button className="btn btn-light text-secondary" onClick={() => setPaso(paso - 1)}>Anterior</button>
                <button className="btn btn-success" onClick={() => nextStep(paso + 1)}>Confirmo</button>
              </div>
            </div>
          </>


        );

      case 4:
        return (
          <>
            <div className='text-center'>
              <small className="fw-bold text-secondary">Paso {paso}</small>
              <p className='h4  my-2'>
                Metodos de pago
              </p>
              <div className='d-flex justify-content-center mt-3'>
                <div>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Seleccione un  método de Pago {obl} </Form.Label>
                    <Form.Select
                      aria-label="Select payment type"
                      id="client_paymentmethod"
                      defaultChecked={clientPaymentMethod}
                      onChange={(e) => setClientPaymentMethod(e.target.value)} name="paymentmethod">
                      <option value="null">Seleccione...</option>
                      {paymentMetods.map(paymentmethod => (
                        <option key={paymentmethod._id} value={paymentmethod.paymentMethodName}>
                          {paymentmethod.paymentMethodName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>

              </div>
              <div className='d-flex justify-content-center'>
                <div className='w-25'>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Ingrese el total recibido {obl} </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="0.00"
                      name="total_cash_received"
                      id="total_cash_received"
                      onChange={(e) => setTotalCashReceived(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='d-flex justify-content-between mb-4'>
                <button className="btn btn-light text-secondary" onClick={() => setPaso(paso - 1)}>Anterior</button>
                <button className="btn btn-success" onClick={() => nextStep(paso + 1)}>Confirmo</button>
              </div>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className='text-center'>
              <small className="fw-bold text-secondary">Paso {paso}</small>
              <p className='h4  my-2'>
                Previzualización de datos a facturar
              </p>
            </div>
            {/* -----:::::Invoice Ushopper Preview :::::------ */}
            <div className='invoice-us'>
              <div>
                <div className="invoice-us-dates p-4">
                  <div className=" header-invoice-us d-flex  justify-content-center align-content-center mb-10 mb-lg-5">
                    {/*----- LOgo----- */}
                    <a href="#">
                      <img alt="Logo" className="invoice-logo" src={logo} />
                    </a>
                  </div>
                  <div className='row gap-1'>
                    {/*-----Client  Details ------ */}
                    <div className='col-5'>
                      <h6 className="my-2 pb-2 fw-bold text-muted">Datos del cliente:</h6>
                      <p>
                        <span className="fw-bold">Cliente: </span>
                        {clientName === '' ? <small className="text-muted ">No registrado</small> : clientName}
                      </p>
                      <p >
                        <span className="fw-bold">Tipo de RUC: </span>
                        {clientTypeRuc === '' ? <small className="text-muted ">No registrado</small> : clientTypeRuc}</p>
                      <p>
                        <span className="fw-bold">RUC: </span>
                        {clientRuc === '' ? <small className="text-muted">No registrado</small> : clientRuc}</p>
                      <p>
                        <span className="fw-bold">DV:</span>
                        {clientDV === '' ? <small className="text-muted">No registrado</small> : clientDV}</p>
                      <p>
                        <span className="fw-bold">Tipo:</span>
                        {clientType === '' ? <small className="text-muted">No registrado</small> : clientType}</p>
                    </div>
                    {/*-----End Client  Details------ */}
                    {/*-----Transaction Details ------ */}
                    <div className='col-6'>
                      <h6 className="my-2 pb-2 fw-bold text-muted" >Detalles de Transación:</h6>
                      <p>
                        <span className="fw-bold"> Tipo de Transacción :</span>
                        Salida o Venta
                      </p>
                      <p>
                        <span className="fw-bold"> Dirección de Sucursal :</span>{business_info.direction}</p>
                      <p>
                        <span className="fw-bold">Estado : </span>
                        <span className='text-warning bg-light px-2'> Previsualización  </span>
                      </p>
                      <p>
                        <span className="fw-bold">Monto pagado por el cliente: </span>
                        {totalCashReceived} </p>
                    </div>
                  </div>
                  {/** Form info  */}
                  <div>
                    <div className="row">
                      <CardTransaction col={"col-md-5"} title={"Método de Pago:"} data_transaction={clientPaymentMethod} />
                      <CardTransaction col={"col-md-5"} title={"Tipo de Método:"} data_transaction={clientPaymentType} />
                    </div>

                  </div>

                  {/*-----Items Details ------ */}
                  <div className='invoice-product-us mt-4'>
                    <div className="table-responsive">
                      <table className="table table-hover table-borderless ">
                        <thead className="bg-light" >
                          <tr className="text-muted">
                            <th>Producto</th>
                            <th>Código</th>
                            <th>Cantidad </th>
                            <th>Precio</th>
                            <th>Impuesto(itbms)</th>
                            <th>Descuento Unitario</th>
                            <th>Total</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableProduct()}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/*-----End Items Details ------ */}
                  <div className='invoice-us-totals mt-4'>
                    <div class="d-flex justify-content-end">
                      <div class="mw-300px">
                        <div class="d-flex justify-content-between mb-2">
                          <div class="  fw-bold text-gray-600 fs-7"> Descuentos: </div>
                          <div class=" fs-6 text-gray-800"> {invoiceDiscountTotal.toFixed(2)} </div>
                        </div>

                        <div class="d-flex justify-content-between mb-2">
                          <div class="  fw-bold text-gray-600 fs-7">Itbms :</div>
                          <div class="text-end fs-6 text-gray-800"> {invoiceTotalItbms.toFixed(2)} </div>
                        </div>

                        <div class="d-flex justify-content-between mb-2">
                          <div class="  fw-bold text-gray-600 "> Subtotal: </div>
                          <div class="fs-6"> {invoiceSubtotal.toFixed(2)} </div>
                        </div>

                        <div class="d-flex justify-content-between">
                          <div class="  fw-bold text-gray-600 fs-7">Total: </div>
                          <div class=" fs-6"> {invoiceTotal.toFixed(2)}</div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* -----:::::End Invoice Ushopper Preview :::::------ */}

            <div className='d-flex justify-content-between my-4'>
              <button className="btn btn-light text-secondary" onClick={() => setPaso(paso - 1)}>Anterior</button>
              <button className="btn btn-success" onClick={() => createInvoice()}>Generar factura</button>
            </div>

          </>
        );
    }

  }

  return (
    <>
      <main>
        <Header title='Facturador' ></Header>
        <div className="container head-content ">
          <div className='d-flex justify-content-start mt-2'>
            <ButtonExit data="Menú Principal  " link='/dashboard' />
          </div>
          <div className='container'>
            {stepForm()}
          </div >
        </div >
      </main >
      <Footer></Footer>

      <Modal dialogClassName="modal-90w" size="lg" backdrop="static"
        show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>PDF de la factura </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PDFViewer className=' pdf-viewer-sp '>
            <DocPdf doc_type="invoice" order_code={invoiceCode} invoiceQR={invoiceQR} invoiceDate={invoiceDate} cufe={cufe} auth_protocol={authDGI} receptor={receptorData} emitter={emmiterData} products={productsList} invoiceSubtotal={invoiceSubtotal} totalTaxes={totalTaxes}
              totalCashReceived={totalCashReceived} invoiceTotal={invoiceTotal} invoiceTotalItbms={invoiceTotalItbms} paymentMethod={clientPaymentMethod} quote_number={quoteNumber} />
          </PDFViewer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>Aceptar</Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default Form_biller;