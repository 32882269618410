import React, { useEffect, useState } from 'react'
//Components App
import ButtonExit from "../Buttons/ButtonExit";
import Nabvar from "../Navbar/Navbar";
import Swal from 'sweetalert2';
//Component BSt
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from 'react-bootstrap/Table';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//Icons
import { FaTrashAlt } from 'react-icons/fa'
//Utils 
import { RenderIf } from '../../../utils/RenderIf';
//API 
import { TipoRucFetchAPI } from '../../../api/dTipoRuc';
import { TipoRecFetchAPI } from '../../../api/iTipoRec';
import { PaymentMethodFetchAPI } from '../../../api/PaymentMethods';
import { TaxesFetchAPI } from '../../../api/Taxes';
import { InvoiceFetchAPI } from '../../../api/Invoice';
import { ProductsFetchAPI } from '../../../api/Products';
import { OrdersFetchAPI } from '../../../api/Orders';

function Biller() {
  const name_label = "Menú Principal  ";
  const obl = <span className="text-danger"> * </span>;
  let userLogged = JSON.parse(localStorage.getItem("userData"));
  let business_info = userLogged.Business;
  //Global data 
  const [rucTypes, setRucTypes] = useState([]);
  const [receptorType, setReceptorTypes] = useState([]);
  const [paymentMetods, setPaymentMethods] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [nextPaymentDate, setNextPaymentDate] = useState(new Date());
  const [businessProducts, setBusinessProducts] = useState([]);
  const [checkboxCheked, setCheckboxCheked] = useState(false);

  //Form Fields
  let [productsList, setProducts] = useState([]);
  let [invoiceTotal, setInvoiceTotal] = useState(0);
  let [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
  let [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
  let [clientName, setClientName] = useState();
  let [clientRuc, setClientRuc] = useState();
  let [clientTypeRuc, setClientTypeRuc] = useState();
  let [clientPaymentMethod, setClientPaymentMethod] = useState();
  let [clientDV, setClientDV] = useState();
  let [clientDirection, setClientDirection] = useState();
  let [clientType, setClientType] = useState();
  let [totalCashReceived, setTotalCashReceived] = useState(0);
  let [clientPaymentType, setClientPaymentType] = useState();
  let [productSelected, setProduct] = useState('null');

  //Function to get the initial data 
  const getInitialData = async () => {
    try {
      //We get the ruc types 
      const tiporuc = await TipoRucFetchAPI.getTipoRuc();
      //We set the ruc types 
      setRucTypes(tiporuc.data.dTipoRuc);
      //We get the receptor types 
      const tiporeceptor = await TipoRecFetchAPI.getTipoRec();
      //We set the receptor types 
      setReceptorTypes(tiporeceptor.data.iTipoRec);
      //We get the paymentmethods 
      const paymentmethods = await PaymentMethodFetchAPI.getPaymentMethods();
      //We set the paymentmethods 
      setPaymentMethods(paymentmethods.data.paymentMethod);
      //We get the taxes 
      const taxes = await TaxesFetchAPI.getTaxes();
      setTaxes(taxes.data.taxes);
      //We get the business products 
      const products = await ProductsFetchAPI.getProductsByBusiness(business_info.phone);
      setBusinessProducts(products.data.business_products.products)
    } catch (err) {
      console.log(err)
    }
  }


  //We charge the initial info 
  useEffect(() => {
    (async () => {
      try {
        getInitialData();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  //Function to know if the product exist or not 
  const handleCheckboxChange = (checked) => {
    //We set the attribute of the checkbox product switch
    setCheckboxCheked(checked)
    //If the checkbox is not checked we show the form of the no existent products
    if (checked === false) {
      document.getElementById('div-no-existent-products').hidden = false
      document.getElementById('div-existent-products').hidden = true
    } else {
      //If the checkbox is checked we show the form of the existent products
      document.getElementById('div-no-existent-products').hidden = true
      document.getElementById('div-existent-products').hidden = false
    }
  };


  //Function to calculate the rouding of a discount
  const rounding = (amount_discount) => {
    let result = (amount_discount / 0.05).toFixed(2);
    //We verify if the result is int or decimal 
    if (result % 1 == 0) {
      console.log('no es decimal')
      amount_discount = parseFloat(amount_discount);
    } else {
      console.log('es decimal');
      amount_discount = parseFloat(amount_discount) + 0.01;
      rounding(amount_discount)
    }
    return amount_discount
  }

  //Function to add a product 
  const addProduct = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    let product = Object.fromEntries(form.entries());

    console.log(product)
    //We verify if the swicth is cheked or not to add the product 
    if (checkboxCheked === true) {

      if (product.product_cant === '' || product.product_discount === '') {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: 'Campos de los productos vacios'
        })
      } else if (product.product_cant <= 0) {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: 'La cantidad del producto no puede ser menor a 1'
        })
      } else if (product.code.length > 20) {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: 'El codigo de producto no puede exceder los 20 caracteres'
        })
      } else if (product.product_discount <= -1) {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: 'No colocar datos negativos en el descuento'
        })
      } else {
        //If the switch is checked it means that the product exist so we get the product of the server 
        const product_selected = await ProductsFetchAPI.getProductsById(productSelected);

        //We calculate the product total and add it to the product list json 
        let total_product = (product.product_cant * (product_selected.data.product.price - product.product_discount));

        //We get the itbms an we calculate the total itbms 
        invoiceTotalItbms = invoiceTotalItbms + (product.product_cant * ((product_selected.data.product.tax.value * (product_selected.data.product.price - product.product_discount))));
        setInvoiceTotalItbms(invoiceTotalItbms);

        //We calculate the subtotal 
        invoiceSubtotal = invoiceSubtotal + total_product;
        setInvoiceSubtotal(invoiceSubtotal);

        //We calculate the total  of the transaction
        invoiceTotal = invoiceSubtotal + invoiceTotalItbms;
        setInvoiceTotal(invoiceTotal)
        //We add the products 
        setProducts([...productsList, {
          "name": product_selected.data.product.name,
          "code": product_selected.data.product.upc ? product_selected.data.product.upc : '0000',
          "cant": product.product_cant,
          "price": product_selected.data.product.price,
          "tax": product_selected.data.product.tax.dTasaITBMSName,
          "total_discount": product.product_discount,
          "total_product": total_product,
          "product": productSelected,
          "exist_product": true
        }]);
      }


    } else {
      //We check the fields 
      if (product.name === '' || product.code === '' || product.cant === '' || product.price === '' || product.tax === "null" || product.total_discount === '') {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: 'Campos de los productos vacios'
        })
      } else if (product.name.length < 2) {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: 'El nombre del producto debe tener al menos 2 caracteres'
        })
      } else if (product.cant <= 0) {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: 'La cantidad del producto no puede ser menor a 1'
        })
      } else if (product.price < 0) {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: 'El precio del producto no puede ser menor a 0'
        })
      } else if (product.code.length > 20) {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: 'El codigo de producto no puede exceder los 20 caracteres'
        })
      } else if (product.total_discount <= -1) {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: 'No colocar datos negativos en el descuento'
        })
      } else {
        let product_total_discount = parseFloat(product.total_discount);
        product.total_discount = rounding(product_total_discount.toFixed(2))
        //We calculate the product total and add it to the product list json
        let total_product = (product.cant * (product.price - product.total_discount));
        product.total_product = total_product;
        //We get the itbms and we calculate the total itbms
        let itmb_value = product.tax.split('%')[0] / 100
        invoiceTotalItbms = invoiceTotalItbms + (product.cant * (itmb_value * (product.price - product.total_discount)));
        setInvoiceTotalItbms(invoiceTotalItbms)

        //We calculate the subtotal 
        invoiceSubtotal = invoiceSubtotal + total_product;
        setInvoiceSubtotal(invoiceSubtotal);

        //We calculate the total  of the transaction
        invoiceTotal = invoiceSubtotal + invoiceTotalItbms;
        setInvoiceTotal(invoiceTotal);

        //We set if the product exist 
        product.exist_product = false;

        //We add the products 
        setProducts([...productsList, product])
      }
    }

  }

  //Function to delete a product of the table preview 
  const deleteProduct = async (product_index) => {
    let product = productsList[product_index];

    console.log(product)
    if (product.exist_product === false) {
      //We get the itbms and we calculate the total itbms
      let itmb_value = product.tax.split('%')[0] / 100;
      let product_itbms = (product.cant * (itmb_value * (product.price - product.total_discount)));
      invoiceTotalItbms = invoiceTotalItbms - product_itbms
      //Subtotal
      invoiceSubtotal = invoiceSubtotal - product.total_product;
      //total 
      invoiceTotal = invoiceTotal - product.total_product - product_itbms
    } else {
      //We get the itbms and we calculate the total itbms
      let itmb_value = product.tax.split('%')[0] / 100;
      let product_itbms = (product.cant * (itmb_value * (product.price - product.total_discount)));
      invoiceTotalItbms = invoiceTotalItbms - product_itbms
      //Subtotal
      invoiceSubtotal = invoiceSubtotal - product.total_product;
      //total 
      invoiceTotal = invoiceTotal - product.total_product - product_itbms
    }
    //We set the totals
    setInvoiceSubtotal(invoiceSubtotal);
    setInvoiceTotal(invoiceTotal);
    setInvoiceTotalItbms(invoiceTotalItbms);

    //We delete the product 
    productsList.splice(product_index, 1);
    setProducts([...productsList]);
  }

  //Function to render the table preview invoice table 
  const tableProduct = () => {
    let productData = productsList;
    let tr_key = 0;
    let rows = productData.map((product) => (
      <tr key={tr_key++}>
        <td>{product.name}</td>
        <td>{product.code}</td>
        <td>{product.cant}</td>
        <td>{product.price}</td>
        <td>{product.tax}</td>
        <td>{product.total_discount}</td>
        <td>{product.total_product}</td>
        <td>
          <button data-id={tr_key} onClick={(e) => deleteProduct(e.currentTarget.dataset.id)} className="btn-danger-us btn text-secondary bg-btn-secundary ">
            <FaTrashAlt />
          </button>
        </td>
      </tr>
    ))
    return (
      <>
        {rows}
      </>
    )
  }

  //We recharge the table preview 
  useEffect(() => {
    (async () => {
      try {
        tableProduct();
      } catch (err) {
        console.log(err);
      }
    })();
  }, [productsList]);


  //We get the fields to make the invoice 
  const createInvoice = async (e) => {
    e.preventDefault();
    try {
      //We validate the fields 
      if (productsList.length === 0) {
        Swal.fire({
          "icon": "error",
          "title": "Error",
          "text": "No ha agregado productos"
        })
      } else if (clientTypeRuc === undefined || clientTypeRuc === 'null') {
        Swal.fire({
          "icon": "error",
          "title": "Error",
          "text": "No ha seleccionado el tipo de ruc del cliente"
        })
      } else if (clientPaymentType === 'null' || clientPaymentType === undefined) {
        Swal.fire({
          "icon": "error",
          "title": "Error",
          "text": "No ha seleccionado el tipo de pago del cliente"
        })
      } else if (clientPaymentMethod === 'null' || clientPaymentMethod === undefined) {
        Swal.fire({
          "icon": "error",
          "title": "Error",
          "text": "No ha seleccionado el metodo de pago del cliente"
        })
      } else if (totalCashReceived === '') {
        Swal.fire({
          "icon": "error",
          "title": "Error",
          "text": "Ingrese el total de dinero recibido por el cliente"
        })
      }
      else {
        //We construc the json to send 
        let json_fe = {
          "pac": {
            "usuario": business_info.fe_credentials.pac.user,
            "pass": business_info.fe_credentials.pac.pass,
            "QR": business_info.fe_credentials.pac.qr
          },
          "conf": {
            "cer": business_info.fe_credentials.conf.cer,
            "pass": business_info.fe_credentials.conf.pass
          },
          "operation_type": "Salida o venta",
          "sell_type": 'Venta de Giro del negocio',
          "document_type": "Factura de Operación Interna",
          "transmitter_ruc": business_info.ruc,
          "transmitter_dv": business_info.dv,
          "transmitter_name": business_info.name,
          "transmitter_ruc_type": business_info.business_ruc_type.type,
          "branch_code": "0000",
          "branch_direction": business_info.direction,
          "transmitter_phone": business_info.phone,
          "transmitter_email": business_info.email,
          "receptor_type": clientType,
          "receptor_name": clientName,
          "receptor_direction": clientDirection,
          "total_cash_received": totalCashReceived,
          "payment_type": clientPaymentType,
          "payment_method": clientPaymentMethod,
          "items": productsList
        }

        //We check if the client is contribuyente or not 
        if (clientTypeRuc !== 'No contribuyente') {
          //if is contribuyent we add the fields of ruc
          json_fe.receptor_type_ruc = clientTypeRuc
          json_fe.receptor_ruc = clientRuc
          json_fe.receptor_dv = clientDV
        } else {
          json_fe.receptor_ruc = clientRuc
        }

        //We check the payment type of the client
        if (clientPaymentType === 'credito') {
          json_fe.payment_dues = [{
            "next_payment_date": nextPaymentDate.toISOString().split('T')[0] + "T11:56:40-05:00",
            "total_to_pay": invoiceTotal
          }]
        }

        //Depending of the credentials type we send the transmitter info 
        if (business_info.fe_credentials.credentials_type === 'demo') {
          json_fe.transmitter_name = "FE generada en ambiente de pruebas - sin valor comercial ni fiscal"
          json_fe.transmitter_ruc = "844084-1-504061"
          json_fe.transmitter_dv = "00"
        }

        try {
          //We generate the electronic invoice 
          const fe_response = await InvoiceFetchAPI.generateFE(json_fe);
          //We make the json of the order 
          let json_order = {
            "business_phone": business_info.phone,
            "operation_type": "Salida o venta",
            "sell_type": "Venta de Giro del negocio",
            "document_type": "Factura de Operacion Interna",
            "branch_code": "0000",
            "branch_direction": business_info.direction,
            "receptor_type": clientType,
            "receptor_name": clientName,
            "receptor_direction": clientDirection,
            "total_cash_received": totalCashReceived,
            "payment_type": clientPaymentType,
            "payment_method": clientPaymentMethod,
            "invoice_type": "Salida o venta",
            "products": productsList,
            "cufe": fe_response.data.data.CUFE,
            "Qr": fe_response.data.data.QR,
            "code": fe_response.data.order_code,
          }

          //We check if the client is contribuyente or not 
          if (clientTypeRuc !== 'No contribuyente') {
            //if is contribuyent we add the fields of ruc
            json_order.receptor_type_ruc = clientTypeRuc
            json_order.receptor_ruc = clientRuc
            json_order.receptor_dv = clientDV
          } else {
            json_order.receptor_ruc = clientRuc
          }

          //We check the payment type of the client
          if (clientPaymentType === 'credito') {
            json_order.payment_dues = [{
              "next_payment_date": nextPaymentDate.toISOString().split('T')[0] + "T11:56:40-05:00",
              "total_to_pay": invoiceTotal
            }]
          }

          try {
            //We register the transaction on the database 
            await OrdersFetchAPI.newOrder(json_order);
            //Alert 
            Swal.fire({
              icon: "success",
              title: 'Factura electronica generada',
              text: `${fe_response.data.data.QR}`
            });

            //We refresh the page and clean the states 
            cleanForm()
          } catch (err) {
            //Alert 
            Swal.fire({
              icon: "error",
              title: 'Error',
              text: `${err}`
            });
          }

        } catch (err) {
          console.log(err)
          let error = err.response.data.data === undefined || err.response.data.errorMessage !== undefined ? err.response.data.errorMessage : err.response.data.data.codigo_mf_texto
          //Alert 
          Swal.fire({
            icon: "error",
            title: `${err.response.data.error}`,
            text: `${error}`
          });
        }
      }
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: "Error generando factura",
        text: 'Intente nuevamente'
      });

    }
  }

  //We clean the form 
  const cleanForm = () => {
    //Client type ruc 
    document.getElementById('client-type-ruc').value = 'null'
    //client Type 
    document.getElementById("client_type").value = 'null';
    document.getElementById('client_type').removeAttribute('disabled')
    //Client ruc
    document.getElementById('client_ruc').value = '';
    document.getElementById('client_ruc').removeAttribute('disabled');
    //Client dv
    document.getElementById('client_dv').value = '';
    document.getElementById('client_dv').removeAttribute('disabled');
    //Client direction 
    document.getElementById('client-direction').value = '';
    //client name 
    document.getElementById('client_name').value = '';
    //Payment type 
    document.getElementById('client_payment_type').value = 'null';
    //Payment method
    document.getElementById('client_paymentmethod').value = 'null';
    //Total cash received 
    document.getElementById('total_cash_received').value = '';
    //Products exist
    document.getElementById('select_product').value = 'null';
    document.getElementById('product_cant').value = 'null'
    document.getElementById('product_discount').value = 'null';
    //Product no exist 
    document.getElementById('name').value = '';
    document.getElementById('code').value = '';
    document.getElementById('cant').value = '';
    document.getElementById('price').value = '';
    document.getElementById('tax').value = 'null';
    document.getElementById('total_discount').value = '';
    //We refresh the states 
    setProducts([])
    setInvoiceTotal(0)
    setInvoiceTotalItbms(0)
    setInvoiceSubtotal(0)
    setClientName()
    setClientRuc()
    setClientTypeRuc()
    setClientPaymentMethod()
    setClientDV()
    setClientDirection()
    setClientType()
    setTotalCashReceived(0)
    setClientPaymentType()
    setProduct('null')

  }

  return (
    <>
      <div className="mt-5 ">
        <div className="container">
          {/* Button Exit  */}
          <ButtonExit data={name_label} />
          <div className="mt-4 d-flex justify-content-center text-ushopper">
            <h2>Facturador</h2>
          </div>
          <div className='biller-us-form border rounded shadow-sm p-4'>
            <h5>Datos de emisor </h5>
            <div className='header-invoice-us'>
              <div className='text-center'>
                <span className='sub-title-biller'>Empresa</span>
                {/* transmitter_name */}
                <h3>{userLogged.Business.name} </h3>
              </div>
            </div>
            <details>
              <summary className='text-ushopper'>Información de la empresa</summary>
              <Row className='mt-2 pt-4 rounded border'>
                {/* transmitter_ruc */}
                <Col md={2}>
                  <label className='sub-title-biller'>RUC</label>
                  <p>{userLogged.Business.ruc}</p>
                </Col>
                {/* transmitter_ruc_type*/}
                <Col md={2}>
                  <label className='sub-title-biller' >Tipo de RUC</label>
                  <p>{userLogged.Business.business_ruc_type !== '' ? userLogged.Business.business_ruc_type.type : 'No registrado'} </p>
                </Col>
                {/* transmitter_dv  */}
                <Col md={2}>
                  <label className='sub-title-biller' >DV</label>
                  <p>{userLogged.Business.dv ? userLogged.Business.dv : 'No registrado'}</p>
                </Col>
                {/* operation_type */}
                <Col md={2}>
                  <label className='sub-title-biller' >Tipo de operación</label>
                  <p>Salida o venta </p>
                </Col>
                {/* branch_code  */}
                <Col md={2}>
                  <label className='sub-title-biller' >Código de sucursal</label>
                  <p>00</p>
                </Col>
                {/* Other line */}
                {/* sell_type  */}
                <Col md={2}>
                  <label className='sub-title-biller' >Tipo de Venta</label>
                  <p>Venta de Giro del negocio</p>
                </Col>
                {/* document_type */}
                <Col md={4}>
                  <label className='sub-title-biller' >Tipo de docuemnto</label>
                  <p>Factura de Operación Interna</p>
                </Col>

                {/* transmitter_phone */}
                <Col md={2}>
                  <label className='sub-title-biller' >Teléfono</label>
                  <p>{userLogged.Business.phone ? userLogged.Business.phone : "No registrado"}</p>
                </Col>

                {/* transmitter_email */}
                <Col md={2}>
                  <label className='sub-title-biller' >Correo Electrónico</label>
                  <p>{userLogged.Business.email ? userLogged.Business.email : 'No registrado'}</p>
                </Col>


                {/* transmitter_direction */}
                <Col md={6}>
                  <label className='sub-title-biller' >Dirección</label>
                  <p>{userLogged.Business.direction ? userLogged.Business.direction : 'No registrado'}</p>
                </Col>
              </Row>
            </details>
            {/**Client form  */}
            <Form>
              <Row className='mt-4'>
                <h5>Datos del cliente </h5>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Nombre {obl} </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese"
                      name="client_name"
                      id="client_name"
                      onChange={(e) => setClientName(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Tipo de RUC {obl} </Form.Label>
                    <Form.Select aria-label="Select payment type"
                      id='client-type-ruc'
                      onChange={(e) => {
                        setClientTypeRuc(e.target.value)
                        //We check what type of client type ruc send the user and then predifine the receptor client
                        if (e.target.value === 'No contribuyente') {
                          setClientType('Consumidor final');
                          //Client type
                          document.getElementById("client_type").value = 'Consumidor final';
                          document.getElementById('client_type').disabled = 'true';

                          //Client dv 
                          document.getElementById('client_dv').value = '';
                          document.getElementById('client_dv').disabled = true;
                          document.getElementById('client_dv').placeholder = 'No contribuyente';

                        } else {
                          document.getElementById("client_type").value = 'null';
                          document.getElementById('client_type').removeAttribute('disabled')
                          //Client dv
                          document.getElementById('client_dv').value = '';
                          document.getElementById('client_dv').removeAttribute('disabled');
                          document.getElementById('client_dv').placeholder = 'Ingrese';
                        }
                      }}
                      name="clientTypeRuc">
                      <option value="null">Seleccione...</option>
                      {rucTypes.map(rucTypes => (
                        <option key={rucTypes._id} value={rucTypes.dTipoRucName}>
                          {rucTypes.dTipoRucName}
                        </option>
                      ))}
                      <option value="No contribuyente">No contribuyente</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="mb-3" controlId="client_ruc">

                    {/** Label of Client ruc  */}
                    <RenderIf isTrue={clientTypeRuc === 'No contribuyente'}>
                      <Form.Label  > Cedula </Form.Label>
                    </RenderIf>
                    <RenderIf isTrue={clientTypeRuc !== 'No contribuyente'}>
                      <Form.Label  > RUC {obl} </Form.Label>
                    </RenderIf>
                    {/** END Label of Client ruc  */}

                    <Form.Control
                      type="text"
                      placeholder="Ingrese"
                      name="client_ruc"
                      controlid="client_ruc"
                      onChange={(e) => setClientRuc(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="mb-3" controlId="client_dv">
                    <Form.Label>DV {obl} </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese"
                      name="client_dv"
                      controlid="client_dv"
                      onChange={(e) => setClientDV(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={2}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Tipo de receptor  {obl} </Form.Label>
                    <Form.Select aria-label="Select payment type" name="client_type" id="client_type" onChange={(e) => setClientType(e.target.value)}>
                      <option value="null">Seleccione...</option>
                      {receptorType.map(receptorType => (
                        <option key={receptorType._id} value={receptorType.iTipoRecname}>
                          {receptorType.iTipoRecname}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={5}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Dirección</Form.Label>
                    <Form.Control as="textarea" id='client-direction' onChange={(e) => setClientDirection(e.target.value)} rows={2} />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-4'>
                <h5>Cobro  </h5>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Método de Pago {obl} </Form.Label>
                    <Form.Select aria-label="Select payment type" id="client_paymentmethod" onChange={(e) => setClientPaymentMethod(e.target.value)} name="paymentmethod">
                      <option value="null">Seleccione...</option>
                      {paymentMetods.map(paymentmethod => (
                        <option key={paymentmethod._id} value={paymentmethod.paymentMethodName}>
                          {paymentmethod.paymentMethodName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Tipo de Pago {obl} </Form.Label>
                    <Form.Select aria-label="Select payment type" name="clientPaymentType" id="client_payment_type" onChange={(e) => {
                      //We set the client payment type
                      setClientPaymentType(e.target.value);
                      if (e.target.value === 'credito') {
                        //if the payment type is credit we show the datepicker div
                        document.getElementById('div-nextPaymentDate').removeAttribute('hidden');
                      } else {
                        //If the payment type isnt credit we dont show the nextpayment date 
                        document.getElementById('div-nextPaymentDate').hidden = true
                      }
                    }}>
                      <option value="null">Seleccione...</option>
                      <option value="contado">Contado</option>
                      <option value="credito">Crédito</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4} id="div-nextPaymentDate" hidden>
                  <Form.Group className="mb-3" controlId="nextpaymentdate">
                    <Form.Label>Fecha de pago {obl} </Form.Label>
                    <DatePicker
                      id='nextpaymentdate'
                      selected={nextPaymentDate}
                      onChange={(date) => setNextPaymentDate(date)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Total recibido {obl} </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese"
                      name="total_cash_received"
                      id="total_cash_received"
                      onChange={(e) => setTotalCashReceived(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>

              </Row>
            </Form>

            {/**Products */}
            <Row className='mt-4'>
              <h5>Productos</h5>
              <Form onSubmit={addProduct} noValidate>
                {/**Product check */}
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    placeholder="Ingrese"
                    name="product_exist"
                    label="Producto existente?"
                    onChange={e => { handleCheckboxChange(e.target.checked) }}
                    required
                  />
                </Form.Group>

                {/**Product select  */}
                <Row className='align-items-center' id='div-existent-products' hidden>
                  <Form.Group className='mb-3'>
                    <Form.Label>Seleccione su producto </Form.Label>
                    <Form.Select aria-label="Select payment type" id="select_product" onChange={e => setProduct(e.target.value)} name="product_select">
                      <option value="null">Seleccione...</option>
                      {businessProducts.map(product => (
                        <option key={product._id} value={product._id}>
                          {product.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Cantidad </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        name="product_cant"
                        id="product_cant"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Descuento </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        name="product_discount"
                        id="product_discount"
                        required
                      />
                    </Form.Group>
                  </Form.Group>
                </Row>
                {/* Form products  */}
                <Row className="align-items-center" id="div-no-existent-products">
                  <Col md={2}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Nombre del Producto </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Ingrese"
                        name="name"
                        id="name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Código </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Ingrese"
                        name="code"
                        id="code"
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col md={1}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Cantidad </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        name="cant"
                        id="cant"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={1}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Precio </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        name="price"
                        id="price"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Impuesto </Form.Label>
                      <Form.Select aria-label="Select payment type" id="tax" name="tax">
                        <option value="null">Seleccione...</option>
                        {taxes.map(tax => (
                          <option key={tax._id} value={tax.dTasaITBMSName}>
                            {tax.dTasaITBMSName === '0%' ? 'Excento' : tax.dTasaITBMSName}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Descuento </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Ingrese"
                        name="total_discount"
                        id="total_discount"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* Button submit */}
                <Col md={2}>
                  <Button type="submit" variant="success" className='mt-3'>Agregar</Button>
                </Col>
              </Form>
              {/**Product table  */}
              <Table responsive>
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Código</th>
                    <th>Cantidad </th>
                    <th>Precio</th>
                    <th>Impuesto(itbms)</th>
                    <th>Descuento</th>
                    <th>Total</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {tableProduct()}
                </tbody>
              </Table>

              <div className='d-flex justify-content-end '>
                <div className='flex-column '>
                  <div className="py-1 d-flex justify-content-between lh-sm">
                    <div>
                      <h6 className="my-0 pe-5">Subtotal:</h6>

                    </div>
                    <span className="text-muted ps-5">${invoiceSubtotal.toFixed(2)}</span>
                  </div>

                  <div className="py-1 d-flex justify-content-between lh-sm">
                    <div>
                      <h6 className="my-0">Total impuestos:</h6>

                    </div>
                    <span className="text-muted ps-5">${invoiceTotalItbms.toFixed(2)}</span>
                  </div>

                  <div className="py-1 d-flex justify-content-between lh-sm ">
                    <div>
                      <h6 className="my-0">Total:</h6>

                    </div>
                    <span className="text-muted ps-5">${invoiceTotal.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </Row>

            <Row className="mt-4">
              <Col md={2}>
                <Button type="button" onClick={createInvoice} variant="success" className='mt-3'>Crear factura </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

    </>
  )
}

export default Biller