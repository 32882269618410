import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Swal from "sweetalert2";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import logo from "./us.png";
import Row from 'react-bootstrap/Row';
import { ProductsFetchAPI } from "../../../api/Products";

function ListsProducts() {
  const [productsdata, setproductsdata] = useState({
    products: [],
    isLoaded: false,
  });

  let datastorage = JSON.parse(localStorage.getItem("userData"));

  const getproducts = async () => {
    //declaramos y asignamos el celular del negocio
    let business_phone = datastorage.Business.phone;
    try {
      const respuesta = await ProductsFetchAPI.getProductsByBusiness(
        business_phone
      );
      setproductsdata({
        products: respuesta.data.business_products.products,
        isLoaded: true,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "No hay productos resgistrados",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getproducts();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  /**/
  const list_data_products = () => {
    let scope = 1;
    let getdata = productsdata.products;

    let row = getdata.map((data) => (
      <div>
        <input
          class="list-group-item-check"
          type="radio"
          name="listClientGroup"
          id={data._id}
          value=""
        />
        <label class="list-group-item py-3" for={data._id}>
        <img className="img-table rounded border " src={data.image}></img>
            &nbsp; {data.name}
          <span class="d-block small opacity-50 mt-1">
            &nbsp;Categoria: {!data.categorie ? <span>No registrado</span> : <>{data.categorie.name}</>},
            &nbsp;Precio: {data.price}, 
            &nbsp;Tipo:{!data.typeItem ? <span>No registrado</span> : <>{data.typeItem}</>} ,
            &nbsp;Impuesto:{data.tax.dTasaITBMSName}
          </span>
        </label>
      </div>
      // <tr>
      //   <th scope="row">{scope++}</th>
      //   <td>
      //     <img className="img-table rounded border " src={data.image}></img>
      //   </td>
      //   <td className="text-secondary-us">{data.name}</td>
      //   <td className="text-secondary-us">{!data.categorie ? <td>No registrado</td> : <>{data.categorie.name}</>}</td>
      //   <td className="text-secondary-us">{data.price}</td>
      //   <td className="text-secondary-us">{!data.typeItem ? <td>No registrado</td> : <>{data.typeItem}</>}</td>
      //   <td className="text-secondary-us">{data.tax.dTasaITBMSName}</td>
      // </tr>
    ));
    return <>{!productsdata.isLoaded ? <td>Cargando</td> : <>{row}</>}</>;
  };

  return (
    <>
      <Container>
        <article className="container-cards">
          <section className="justify-content-md-center  mt-1">
          <div className="d-flex justify-content-center mb-5">
                <img src={logo} alt="" width="150" height="150" />
            </div>
            <h3 className="text-center text-ushopper"> Lista de Productos </h3>
            <Form>
                <Row className="align-items-center justify-content-center ">
                    <Col sm={4} xs={8}  className="my-1">
                    <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                        Name
                    </Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder="Buscar producto" />
                    </Col>
                    <Col xs="auto" className="my-1">
                        <button className="btn btn-success" type="submit">Buscar</button>
                    </Col>
                </Row>
            </Form>
            <div className="">
              <div class="list-group list-group-checkable">
                {list_data_products()}
              </div>
            </div>
          </section>
        </article>
        <a href="#" class="btn-flotante">
          Cancelar
        </a>
      </Container>
    </>
  );
}

export default ListsProducts;
