import React, { useContext } from "react";
import Footer from "../Layout/Footer";
import logo from "./img/white-ushopper.png";
import validacionForm from "./form-validation.js";
import Swal from "sweetalert2";
import { FetchAPI } from "../../api/Auth";
import { useNavigate, Link } from "react-router-dom";
import Usercontext from "../../context/UserContext";
import '../assets/css/style.css';

function SingUp() {
  const { setUserData } = useContext(Usercontext);

  const navigate = useNavigate();

    //evento de captura de datos del formulario
    const submit = async (event) => {
        event.preventDefault()
        const register  = new FormData(event.target)
        let newUser = Object.fromEntries(register.entries())

        console.log(register)

        try{
            //consulta request para registro
            const response =  await FetchAPI.register(newUser);

            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500
            })

            //redirecciona a log in
            navigate('/login');
    

        } catch (err) {
            console.log(err)

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
            })
        }

        }
    
  

  return (
    <>
      <div className=" sing-up-section bg-primary-ushopper">
        <div>
          <main className="py-4 form-signup m-auto h-100">
            <div className=" ">
              <div className="d-flex justify-content-between">
                <Link to="/sign-in">
                  <img src={logo} width="150" alt="" />
                </Link>
                <div className="mt-4">
                  <span className="link text-white mx-2">
                    ¿Ya tienes una cuenta?
                  </span>
                  <Link to="/sign-in" className="sing-up-link ">
                    Iniciar sesión
                  </Link>
                </div>
              </div>

              <div className="py-5 text-center">
                <h3 className="text-white">Registro de usuario</h3>
              </div>
            </div>

            <div className="bg-white border rounded p-4">
              <div className="row g-5">
                <div className="col-md-12 col-lg-12">
                  <form
                    className="mt-3"
                    onSubmit={submit}
                    encType="multipart/form-data"
                  >
                    <div className="row g-3">
                      <div className="col-sm-6">
                        <label htmlFor="firstName" className="label-input">
                          Nombre
                        </label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="firstName"
                          required
                          placeholder=""
                        />
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="lastName" className="label-input">
                          Apellido
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          className="form-control"
                          id="lastName"
                          required
                          placeholder=""
                        />
                      </div>

                      <div className="col-12">
                        <label htmlFor="email" className="label-input">
                          Correo Electrónico
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="form-control rounded "
                          id="email"
                          placeholder="you@example.com"
                          required
                        />
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="userId" className="label-input">
                          Identificación personal
                        </label>
                        <input
                          type="text"
                          name="userId"
                          className="form-control"
                          id="userId"
                          placeholder=""
                        />
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="phone" className="label-input">
                          Teléfono
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          className="form-control"
                          id="phone"
                          placeholder=""
                          required
                        />
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="password" className="label-input">
                          Contraseña
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          id="password"
                          placeholder=""
                          required
                        />
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="passwordCheck" className="label-input">
                          Confirme su contraseña
                        </label>
                        <input
                          type="password"
                          name="passwordCheck"
                          className="form-control"
                          id="passwordCheck"
                          placeholder=""
                          required
                        />
                      </div>

                      <div className="col-12">
                        <label htmlFor="files" className="label-input">
                          Imagen de perfil
                        </label>
                        <small className="text-muted"> (Optional) </small>
                        <input
                          type="file"
                          name="files"
                          id="files"
                          className="form-control"
                        />
                        <div className="invalid-feedback">Subir imagen.</div>
                      </div>
                    </div>

                    <button className="w-100 button-us mt-4 " type="submit">
                      Enviar
                    </button>
                  </form>            
                </div>
              </div>
            </div>
            <br />
            <Footer/>
          </main>
        </div>
      </div>
    </>
  );
}

export default SingUp;
