import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Swal from "sweetalert2";

//api
import { ProductsFetchAPI } from '../../../../api/Products'
function ModalProductoInfo(id) {
  const [productbyid, setProductbyid] = useState({
    producto: []
  })
  let token = localStorage.getItem('ushopper-access-token'); 
  let datastorage = JSON.parse(localStorage.getItem("userData"));

  const getproduct = async () => {
    try {
      const response = await ProductsFetchAPI.getProductsById(id.id, token)
      setProductbyid({
        producto: [response.data.product]
      })

    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    
  }

  const infoProductCard = () => {
    let getdata = productbyid.producto;
    let card = getdata.map((data) => (
      <Card style={{ width: '100%', height:'100%'}}>
        <Card.Img variant="top" src={data.image} />
        <Card.Header>
          <Card.Title>{data.name}</Card.Title>
        </Card.Header>
        <Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroup.Item><b>Id:</b> {!data._id ? <td>No registrado</td> : <>{data._id}</>}</ListGroup.Item>
            <ListGroup.Item><b>Categoria:</b> {!data.categorie ? <td>No registrado</td> : <>{data.categorie.name}</>}</ListGroup.Item>
            <ListGroup.Item><b>Tipo:</b> {!data.typeItem ? <td>No registrado</td> : <>{data.typeItem}</>}</ListGroup.Item>
            <ListGroup.Item><b>Precio:</b> {!data.price ? <td>No registrado</td> : <>{data.price}</>}</ListGroup.Item>
            <ListGroup.Item><b>Impuesto:</b> {!data.tax ? <td>No registrado</td> : <>{data.tax.dTasaITBMSName}</>}</ListGroup.Item>
            <ListGroup.Item><b>Upc:</b> {!data.upc ? <td>No registrado</td> : <>{data.upc}</>}</ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    ))
    return (
      <>
        {card}
      </>
    )
  }


  //Modal state
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    await getproduct()
    setShow(true);
  }

  return (
    <>


      {['top'].map((placement) => (
        <OverlayTrigger
          key={placement}
          placement={placement}
          overlay={
            <Tooltip id={`tooltip-${placement}`}>
              Ver más
            </Tooltip>
          }
        >
          <button className=" btn btn-secondary-us" onClick={handleShow}>
            <FaEye className="text-secondary-us " />
          </button>
        </OverlayTrigger>
      ))}



      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalle de producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {infoProductCard()}
        </Modal.Body>
      </Modal>
    </>

  );
}

export default ModalProductoInfo;
