import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class TaxesFetchAPI { 

    static async getTaxes() {
        const res = await server("/tax", {
            method: "get",
        });
        return res;
    }

    
    static async getTaxById(tax_id) {
        const res = await server("/tax/" +  tax_id, {
            method: "get",
        });
        return res;
    }
}
