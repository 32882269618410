import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import logo from '../../assets/img/logo/logo-srg.png'
import Button from 'react-bootstrap/Button';
import CardTransaction from './CardTransaction'
import Swal from "sweetalert2";
import { FaFileInvoice } from "react-icons/fa";
import { CgClose, CgLock } from "react-icons/cg";

import { OrdersFetchAPI } from "../../../api/Orders";
import './Transactions.css'

import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import DocPdf from '../Pdf/DocPdf';

function ModalDetails(id) {
  const [lgShow, setLgShow] = useState(false);

  const handleClose = () => setLgShow(false);
  let userLogged = JSON.parse(localStorage.getItem("userData"));
  let business_info = userLogged.Business;
  const [ordersinfo, setOrdersinfo] = useState({
    infoOrders: [],
    items: [],
    client_name: "",
    transaction_numb: "",
    client_type_ruc: "",
    client_ruc: "",
    client_dv: "",
    client_type: "",

    invoice_type_us: "",
    branch_code_us: "",
    branch_direction_us: "",
    state_us: "",
    createdAt_us: "",

    discount: "",
    order_items: "",
    total_order: "",
    code: ""
  })

  //Transmitter info
  const [emmiterData, setEmmiterData] = useState({
    "ruc": business_info.fe_credentials.pac.user,
    "dv": business_info.dv,
    "name": business_info.name,
    "ruc_type": business_info.business_ruc_type.type,
    "direction": business_info.direction
  });

  //Total taxes 
  const [totalTaxes, setTotalTaxes] = useState([
    { total_base: 0, tax: "0%", total_tax: 0 },
    { total_base: 0, tax: "7%", total_tax: 0 },
    { total_base: 0, tax: "10%", total_tax: 0 },
    { total_base: 0, tax: "15%", total_tax: 0 }
  ]);

  //Get order by id
  const getorder = async () => {
    //We reset the total taxes
    let TotalTaxes = [
      { total_base: 0, tax: "0%", total_tax: 0 },
      { total_base: 0, tax: "7%", total_tax: 0 },
      { total_base: 0, tax: "10%", total_tax: 0 },
      { total_base: 0, tax: "15%", total_tax: 0 }
    ]

    try {
      const response = await OrdersFetchAPI.getOrderid(id.id);

      let items = ""
      //We check if the order has products
      if (response.data.orderbyid.products) {
        items = response.data.orderbyid.products;
        //We go through the products to get the total taxes
        for (let i = 0; i < items.length; i++) {
          let product_info = items[i];
          let indexTax = TotalTaxes.map(value => value['tax']).indexOf(product_info.tax);

          //We calculate the total product itbms 
          let total_product_itbms = (product_info.cant * (product_info.price * (product_info.tax.split('%')[0] / 100)));

          //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
          let subtotal_product = (product_info.cant * product_info.price) - product_info.total_discount;

          //If the tax exist we just affect the total of it 
          TotalTaxes[indexTax].total_tax = TotalTaxes[indexTax].total_tax + total_product_itbms;
          TotalTaxes[indexTax].total_base = TotalTaxes[indexTax].total_base + subtotal_product;
        }
        //We set the total taxes to the state
        setTotalTaxes(TotalTaxes)

      } else (
        items = []
      );


      //We set the order info to the state
      setOrdersinfo({
        infoOrders: [response.data.orderbyid],
        items: items,
        client_name: response.data.orderbyid.receptor_name,
        transaction_numb: response.data.orderbyid.code,
        client_type_ruc: response.data.orderbyid.receptor_type_ruc,
        client_ruc: response.data.orderbyid.receptor_ruc,
        client_dv: response.data.orderbyid.receptor_dv,
        client_type: response.data.orderbyid.receptor_type,

        invoice_type_us: response.data.orderbyid.invoice_type,
        branch_code_us: response.data.orderbyid.branch_code,
        branch_direction_us: response.data.orderbyid.branch_direction,
        state_us: response.data.orderbyid.state,
        createdAt_us: response.data.orderbyid.createdAt,

        discount: response.data.orderbyid.total_order_discount.toFixed(2),
        order_items: response.data.orderbyid.total_order_itbms.toFixed(2),
        total_order: response.data.orderbyid.total_order.toFixed(2),
        code: response.data.orderbyid.code, 

        quote_number: response.data.orderbyid.quote_number
      })

    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };


  //Button Reference CUFE 
  const infoCufe = () => {
    const getdata = ordersinfo.infoOrders;


    //We check if the order has a cufe
    const buttonCufe = getdata.map((data) => (
      <>
        <div title="Clic para consultar" className="mb-1">
          <a href={!data.Qr ? '#' : data.Qr} className="btn btn-primary btn-sm" target="_blank" > {data.invoice_type === "Nota de credito" ? "Ver Nota de credito" : "Ver Factura Electrónica"}  </a>
        </div>

        <PDFDownloadLink className='pdf-viewer-sp' document={<DocPdf
          order_code={data.code}
          quote_number={data.quote_number}
          doc_type={data.invoice_type === "Nota de credito" ? "credit_note" : "invoice" } invoiceQR={data.Qr} invoiceDate={data.createdAt} cufe={data.cufe} auth_protocol={data.auth_protocol ? data.auth_protocol : "00000000"}
          receptor={{
            name: data.receptor_name,
            ruc: data.receptor_ruc,
            direction: data.receptor_direction,
            dv: data.receptor_dv,
            ruc_type: data.receptor_type_ruc,
            type: data.receptor_type
          }}
          emitter={emmiterData} products={data.products} invoiceSubtotal={data.subtotal_order} totalTaxes={totalTaxes}
          totalCashReceived={data.total_cash_received} invoiceTotal={data.total_order} invoiceTotalItbms={data.total_order_itbms} paymentMethod={data.payment_method}


        />} >
          <a className="btn btn-primary btn-sm" target="_blank" > {data.invoice_type === "Nota de credito" ? "Descargar nota de credito" : "Descargar factura electronica"} </a>
        </PDFDownloadLink>
      </>
    ))
    return (
      <>
        {buttonCufe}
      </>
    )
  }

  //Informacion de productos facturados
  const tableitems = () => {
    let scope = 1
    let getdata = ordersinfo.items
    console.log(getdata);
    let row = getdata.map((data) => (
      <tr>
        <th className="text-secondary-us" scope="row">{scope++}</th>
        <td className="text-secondary-us" > {!data.product ? <small className="text-muted text-center">No registrado</small> : <>{!data.product.name ? <small className="text-muted text-center">No registrado</small> : <>{data.product.name}</>}</>} </td>
        <td className="text-secondary-us" >{!data.product ? <small className="text-muted text-center">No registrado</small> : <>{!data.tax ? <small className="text-muted text-center">No registrado</small> : <>{data.tax}</>}</>}</td>
        <td className="text-secondary-us" >{!data.product ? <small className="text-muted text-center">No registrado</small> : <>{!data.cant ? <small className="text-muted text-center">No registrado</small> : <>{data.cant}</>}</>}</td>
        <td className="text-secondary-us" >{!data.product ? <small className="text-muted text-center">No registrado</small> : <>{!data.price ? <small className="text-muted text-center">No registrado</small> : <>{typeof data.price === 'string' ? parseFloat(data.price).toFixed(2) : data.price.toFixed(2)}$</>}</>}</td>
      </tr>
    ));
    return (
      <>
        {row}
      </>
    )
  }

  const infopago = () => {
    const getdata = ordersinfo.infoOrders
    const row = getdata.map((data) => (

      <>
        <div className="row">
          <CardTransaction col={"col-md-5"} title={"Método de Pago:"} data_transaction={data.payment_method} />
          <CardTransaction col={"col-md-5"} title={"Tipo de Método:"} data_transaction={data.payment_type} />
        </div>
      </>

    ))
    return (
      <>
        {row}
      </>
    )
  }

  const handleShow = async () => {
    setLgShow(true)
    await getorder();
  };

  return (
    <>

      <button
        className=" btn btn-secondary-us bg-btn-secundary "
        onClick={handleShow}
      >
        <FaFileInvoice className="text-secondary-us " />
      </button>

      <Modal size="lg" show={lgShow} onHide={() => setLgShow(false)}>
        <div class="header-modal-sp">
          <div class="btn-close-modal-sm d-flex justify-content-end">
            <button type="button" class="btn-close m-1" title=" Cerrar " onClick={handleClose} > </button>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <h4 class="title-modal"> Información de Transacción  </h4>
          </div>
        </div>
        <Modal.Body>
          <div className="">
            {/* -----:::::Invoice Ushopper Preview :::::------ */}
            <div className='invoice-us'>
              <div>
                <div className="invoice-us-dates p-4">
                  <div className=" header-invoice-us d-flex  justify-content-between align-content-center mb-10 mb-lg-15">
                    {/*----- LOgo----- */}
                    <a href="#">
                      <img alt="Logo" className="invoice-logo" src={logo} />
                    </a>
                    {/*------ CUFE -----*/}
                    <div className="my-1">
                      {infoCufe()}
                    </div>
                  </div>
                  {/* -----End CUFE----- */}
                  {/*----- Numb Transaction------ */}
                  <div className="invoice-us-n-transaction mt-4">
                    <h5>
                      Factura #{ordersinfo.transaction_numb}
                    </h5>
                  </div>
                  {/*-----End Numb Transaction------ */}
                  <div className='row gap-1'>
                    {/*-----Client  Details ------ */}
                    <div className='col-5'>
                      <h6 className="my-2 pb-2 fw-bold text-decoration-underline text-muted">Datos del cliente:</h6>

                      <p>
                        <span className="">Cliente: </span>
                        {!ordersinfo.client_name ? <small className="text-muted text-center">No registrado</small> : ordersinfo.client_name}
                      </p>

                      <p>Tipo de RUC: {!ordersinfo.client_type_ruc ? <small className="text-muted text-center">No registrado</small> : ordersinfo.client_type_ruc}</p>
                      <p>RUC: {!ordersinfo.client_ruc ? <small className="text-muted text-center">No registrado</small> : ordersinfo.client_ruc}</p>
                      <p>DV: {!ordersinfo.client_dv ? <small className="text-muted text-center">No registrado</small> : ordersinfo.client_dv}</p>
                      <p>Tipo: {!ordersinfo.client_type ? <small className="text-muted text-center">No registrado</small> : ordersinfo.client_type}</p>
                    </div>
                    {/*-----End Client  Details------ */}
                    {/*-----Transaction Details ------ */}
                    <div className='col-6'>
                      <h6 className="my-2 pb-2 fw-bold  text-decoration-underline text-muted" >Detalles de Transación:</h6>
                      <p>
                        Tipo de Transacción :{ordersinfo.invoice_type_us}
                      </p>
                      <p>Código de Sucursa:{ordersinfo.branch_code_us}</p>
                      <p>Dirección de Sucursal :{ordersinfo.branch_direction_us}</p>
                      <p>Estado :{ordersinfo.state_us}</p>
                      <p>Fecha:{ordersinfo.createdAt_us}</p>
                    </div>
                  </div>
                  <div>
                    {infopago()}
                  </div>

                  {/*-----Items Details ------ */}
                  <div className='invoice-product-us mt-4'>
                    <div className="table-responsive">
                      <table className="table table-hover table-borderless ">
                        <thead className="bg-light" >
                          <tr className="text-muted">
                            <th>#</th>
                            <th>Producto</th>
                            <th>Itbms</th>
                            <th>Cantidad</th>
                            <th>Precio</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableitems()}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/*-----End Items Details ------ */}
                  <div className='invoice-us-totals mt-4'>
                    <div class="d-flex justify-content-end">
                      <div class="mw-300px">
                        <div class="d-flex justify-content-between mb-2">
                          <div class="  fw-bold text-gray-600 fs-7"> Descuentos: </div>
                          <div class=" fs-6 text-gray-800"> {ordersinfo.discount} </div>
                        </div>

                        <div class="d-flex justify-content-between mb-2">
                          <div class="  fw-bold text-gray-600 fs-7">Itbms :</div>
                          <div class="text-end fs-6 text-gray-800"> {ordersinfo.order_items} </div>
                        </div>

                        <div class="d-flex justify-content-between mb-2">
                          <div class="  fw-bold text-gray-600 "> Subtotal: </div>
                          <div class="fs-6"> {ordersinfo.order_items} </div>
                        </div>

                        <div class="d-flex justify-content-between">
                          <div class="  fw-bold text-gray-600 fs-7">Total: </div>
                          <div class=" fs-6"> {ordersinfo.total_order}</div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* -----:::::End Invoice Ushopper Preview :::::------ */}
          </div>

          <div className="mt-4 d-flex justify-content-end">
            <button className="btn btn-outline-light fw-bold text-secondary" onClick={handleClose}>
              Cerrar
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalDetails;


