import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaRegPlusSquare } from "react-icons/fa";
import Swal from 'sweetalert2'
import Select from 'react-select';

//Icons 
import { FaPlus } from "react-icons/fa";
//api
import { CategoriesFetchAPI } from "../../../api/Categories";


function ModalCategories({ setEventHandler, panamanianCodes }) {
  const [show, setShow] = useState(false);
  let dataStorage = JSON.parse(localStorage.getItem('userData'))
  let token = localStorage.getItem('ushopper-access-token');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //States of search select 
  const [selectedCodeOption, setSelectedCodeOption] = useState(null);
  //The default option of the select input 
  const defaultOption = [{ label: 'Seleccione...', value: null }];

  //Get codes 
  const listPanamanianCodes = panamanianCodes.map((element) => ({
    label: element.name,
    value: element._id
  }));

  //Event to detect the panamanian selected on search select 
  const handleChangePanamianCodes = (option) => {
    //We check if the option is null 
    setSelectedCodeOption(option)
  };

  const submit = async (event) => {
    event.preventDefault()
    const categoriform = new FormData(event.target)
    categoriform.append("business_phone", dataStorage.Business.phone);
    categoriform.append("dcodcpbscmp_id", selectedCodeOption.value);
    Object.fromEntries(categoriform.entries())

    try {
      
      const response = await CategoriesFetchAPI.newCategories(categoriform, token);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    //We reload the component 
    setEventHandler(1);
    //We close the modal
    handleClose();
  };


  return (
    <>
      <button className="btn btn-sm btn-primary" onClick={handleShow}>
        <span className="text-white px-2">
          <FaPlus />
          <strong > Nueva Categoria</strong>
        </span>
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Crear Categorias </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={submit}
            encType="multipart/form-data"
          >
            {/* <div class="d-flex justify-content-center ">
              <img src={img_bg} class="img-modal rounded-circle " />
            </div> */}
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre de categoria"
                name="name"
                required
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="panamaniancode">
              <Form.Label>Codigo panameño</Form.Label>
              <Select
                className='w-100'
                value={selectedCodeOption}
                options={[defaultOption[0], ...listPanamanianCodes]}
                onChange={handleChangePanamianCodes}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="clientId">
              <Form.Label></Form.Label>
              <Form.Control
                type="file"
                placeholder="Imagen"
                name="files"
              />
            </Form.Group>

            <div className="mt-3 d-flex justify-content-end">
              <Button size="sm" variant="success" type="submit">
                Guardar
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalCategories;
