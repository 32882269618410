import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//Components App
import Header from '../Navbar/Header';
import ButtonExit from "../Buttons/ButtonExit";
import ModalProductoInfo from "./ModalProducto/ModalProductoInfo";
import ModalProductoEditar from "./ModalProducto/ModalProductoEditar";
import ModalProductoRegistro from "./ModalProducto/ModalProductoRegistro";
import Footer from "../Footer/Footer";
//Components Boots
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
//DataTable
import MUIDataTable from 'mui-datatables'
//Libraries
import Swal from "sweetalert2";
//icons
import { FaTrash } from "react-icons/fa";
//Apis
// import UserContext from "../../../../context/UserContext";
import { ProductsFetchAPI } from "../../../api/Products";



function Productos() {
  const name_btn = "Menú principal ";
  let token = localStorage.getItem('ushopper-access-token'); 
  const [data_rows, setDataRows] = useState([])
  const [reload, setReload]= useState(); 
  const [productsdata, setproductsdata] = useState({
    products: [],
    isLoaded: false,
  });

  const [temporal, setTemporal] = useState({
    products: [],
  });

  let datastorage = JSON.parse(localStorage.getItem("userData"));

  const getproducts = async () => {
    //declaramos y asignamos el celular del negocio
    let business_phone = datastorage.Business.phone;
    try {
      const response = await ProductsFetchAPI.getProductsByBusiness(
        business_phone, token
      );

      console.log(response);
      setproductsdata({
        products: response.data.business_products.products,
        isLoaded: true,
      });

      setTemporal({
        products: response.data.business_products.products,
        isLoaded: true,
      });

      //?Cargan los  datos a la DataTable
      setDataRows(response.data.business_products.products)

    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "No hay productos resgistrados",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getproducts();
      } catch (err) {
        console.log(err);
      }
    })();

    setReload(0); 
  }, [reload]);

  //--------------
  //alert elimina un producto seleccionado
  function alert(product_id) {
    const getdelete = async () => {
      //declaramos y asignamos el celular del negocio
      let business_phone = datastorage.Business.phone;
      try {
        const resposedelete = await ProductsFetchAPI.deleteProduct(
          product_id,
          business_phone, 
          token
        );
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: resposedelete.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
        getproducts();
      } catch (error) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    };

    Swal.fire({
      icon: "info",
      title: "¿Quieres eliminar este producto?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Eliminar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        getdelete();
      } else if (result.isDenied) {
        Swal.fire({
          position: "top-end",
          icon: "info",
          title: "Accion cancelada",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }


  const columns = [
    {
      name: "image",
      label: "Imagen",
      options: {
        filter: false,
        customBodyRender: (name) => {
          return (
            <img className="img-table" src={name} />        
          );
        },
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        responsive: 'stacked',
      }
    },

    {
      name: 'categorie',
      label: "Categoria",
      options: {
        filter: false,
        customBodyRender: (name) => {
          return (
            <div id={name}>{name.name}</div>
          );
        },
      },
    },
    {
      name: "price",
      label: "Precio",
    },
    {
      name: "typeItem",
      label: "Tipo",
    },
    // {
    //   name: "createdAt",
    //   label: "Fecha",
    //   options: {
    //     filter: false,}
    // },
    {
      name: '_id',
      label: "Acciones",
      options: {
        filter: false,
        customBodyRender: (name) => {
          return (
            <div className="small opacity-75 ">
              <ModalProductoInfo id={name}></ModalProductoInfo>
              <ModalProductoEditar id={name} setLoad={setReload}></ModalProductoEditar>
              {/* <button
              type="button"
              class="btn-danger-us btn text-secondary "
              data-id={data._id}
              onClick={() => alert(data._id)}
            ></button> */}

              {["top"].map((placement) => (
                <OverlayTrigger
                  key={placement}
                  placement={placement}
                  overlay={
                    <Tooltip id={`tooltip-${placement}`}>Eliminar</Tooltip>
                  }
                >
                  <button
                    type="button"
                    className="btn-danger-us btn text-secondary "
                    data-id={name}
                    onClick={() => alert(name)}
                  >
                    <FaTrash />
                  </button>
                </OverlayTrigger>
              ))}
            </div>
          );
        },
      },
    },
  ]

  const filterOptions = {
    Acciones: {
      display: false,
    },
  };

  const options = {
    selectableRows: false,
    print: false,
    download: false,
    responsive: true,
    filter: true,
    filterOptions: filterOptions,

  };





  return (
    <>
      <main>
        <Header title='Productos'></Header>
        <div className="container head-content ">

          <div className='d-flex justify-content-between mt-2 mb-4'>
            <ButtonExit data="Menú Principal  " link='/dashboard' />         
            <ModalProductoRegistro setLoad={setReload}></ModalProductoRegistro>
          </div>   
          <MUIDataTable
            title={"Productos Registrados"}
            columns={columns}
            data={data_rows}
            className="rounded rounded-us mb-4"
            options={options}
          />
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Productos;
