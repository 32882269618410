import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class CountryFetchAPI {

    //Funtion to get all countries
    static async getCountries(token) {
        const res = await server("/countries", {
            method: "get",
            headers: {
                'ushopper-access-token': token
            }
        });
        return res;
    }

    //Function to get a country by code
    static async getCountryByCode(code, token) {
        const res = await server(`/countries/${code}`, {
            method: "get",
            headers: {
                'ushopper-access-token': token
            }

        });
        return res;
    }
}