import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer"
import T1 from "./img/team/1.jpeg"
import T2 from "./img/team/2.jpeg"
import T6 from "./img/team/6.jpeg"
import T7 from "./img/team/7.jpeg"
import T5 from "./img/team/5.jpeg"
function Team() {
    return (
        <>   
        <NavBar></NavBar>
        <div className="bg-color-sencudary">
        <div className="px-4 py-5 text-center" >
            {/* <img
            className=" mx-auto mb-4 img-about"
            src={Img_About}
            alt="Icono Ushopper"
            /> */}
            <h4 className="display-6 fw-bold color-ushopper mb-3">
            Conoce el equipo de Ushooper
            </h4>
   
        </div>
        
        </div>   


        <div class="container marketing">
            <div class="row">
                <div class="col-lg-4">
                    <img
                    src={T6}
                    class="bd-placeholder-img rounded-circle img-team"
                    width="150"
                    height="150"  
                    />
                    
                    <h5 class="fw-normal mt-2">Moises Rodríguez </h5>
                    <small className="color-ushopper fw-bold">CEO</small>
                </div>

                <div class="col-lg-4">
                <img
                    src={T2}
                    class="bd-placeholder-img rounded-circle img-team"
                    width="140"
                    height="140"  
                    />
                    <h5 class="fw-normal mt-2">Juan Velasquez  </h5>
                    <small className="color-ushopper fw-bold ">Developer </small>
                </div>

                <div class="col-lg-4">
                <img
                    src={T5}
                    class="bd-placeholder-img rounded-circle img-team"
                    width="140"
                    height="140"  
                    />
                    <h5 class="fw-normal mt-2">Daniela Chalbaud </h5>
                    <small className="color-ushopper fw-bold " >UI/UX Design </small>
                </div>

                <div class="col-lg-4 offset-md-2">
                <img
                    src={T1}
                    class="bd-placeholder-img rounded-circle img-team"
                    width="140"
                    height="140"  
                    />
                    <h5 class="fw-normal mt-2">Maycol Cuervo  </h5>
                    <small className="color-ushopper fw-bold">Developer</small>
                </div>

                <div class="col-lg-4">
                <img
                    src={T7}
                    class="bd-placeholder-img rounded-circle img-team"
                    width="160"
                    height="160"  
                    />
                    <h5 class="fw-normal mt-2"> Vicente Pitty </h5>
                    <small className="color-ushopper fw-bold" >Developer</small>
                </div>

            </div>
        </div>

        <Footer></Footer>
        </>
    );
}



export default Team;



