import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class OrdersFetchAPI {

    //We get a order by business 
    static async getOrdersBusiness(business_phone) {
        const res = await server(`/orderbybusiness/${business_phone}`, {
            method: "get"
        });
        return res;
    }

    //We get a order by id 
    static async getOrderid(_id) {
        const res = await server(`/order/${_id}`, {
            method: "get"
        });
        return res;
    }

    //We generate a new order 
    static async newOrder(data) {
        const res = await server(`/ordergenerate`, {
            method: 'POST',
            data: data
        });
        return res;
    }

    //We get the orders by business and state 
    static async getBusinessOrderByState(id, state) {
        const res = await server(`/business/orders/${id}/${state}`, {
            method: 'get'
        });
        return res;
    }


    //We get a order by business 
    static async getBusinessOrdersByDate(business_ruc, minDate, maxDate, token) {
        const res = await server(`/business/orders/${business_ruc}/${minDate}/${maxDate}`, {
            method: "get",
            headers: {
                'ushopper-access-token': token
            }
        });
        return res;
    }

    //We get a order by business 
    static async getBusinessOrderByCode(business_ruc, order_code, token) {
        const res = await server(`/business/${business_ruc}/orders/${order_code}`, {
            method: "get",
            headers: {
                'ushopper-access-token': token
            }
        });
        return res;
    }
}