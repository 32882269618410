import React from "react";

function Footer() {
    return (
        <>
        <footer className="footer-us">
            <div className=" text-white d-flex justify-content-center p-4 ">
            <p className="footer-title px-2">
                Smart Retail Group, All rights reserved.{" "}
            </p>
            <p className="footer-title  px-2"> © 2022 Ushopper </p>
            </div>
        </footer>
        </>
    );
}

export default Footer;
