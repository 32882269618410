import React from 'react'
import NavBar from "./Navbar";
import ProfileCard from "../Cards/ProfileCard";

function Header(props) {
   return (
      <>
         <div class="main-header-us">
            <div className='container'>
               <NavBar title={props.title}/>                    
            </div>
            <div className="">
               <ProfileCard ></ProfileCard>               
            </div> 
         </div>
      </>
   )
}

export default Header