import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class InvoiceFetchAPI {
    //Function to generate a electronic invoice 
    static async generateFE(data) {
        const res = await server("/invoice", {
            method: "post",
            data: {
                invoice_params: data
            }
        });
        return res;
    }

    //Function to generate a electronic invoice 
    static async generateCreditNote(data) {
        const res = await server("/credit_note", {
            method: "post",
            data: {
                credit_note_params: data
            }
        });
        return res;
    }
}
