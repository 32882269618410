import React from 'react';
import Container from "react-bootstrap/Container";
//import RegisterProducts from '../Productos/Forms/RegisterProduct';
function RegisterProduct() {
    return (
        <>
            <Container>
                <div className='mt-5 py-5 mx-lg-5'>
                    <h3 className='text-center text-ushopper'>Registro de productos </h3>
                    {/* <RegisterProducts></RegisterProducts> */}
                </div>

            </Container>

        </>
    )
}

export default RegisterProduct