import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': "application/json"}, 
});

export class ProductsFetchAPI {
    static async newProduct(data, token){
        
        const res = await server ("/product", {
            method: "post",
            data: data,
            headers :{'ushopper-access-token': token}

        });

        return res;
    }

    static async getProductsByBusiness(business_phone, token){
        const res = await server (`/products/${business_phone}/products_business`, {
            method: "GET",
            headers :{'ushopper-access-token': token}
        });

        return res;
    }

    static async getProductsById(_id, token){
        const res = await server (`/productbyid/${_id}`, {
            method: "get",
            headers :{'ushopper-access-token': token}

        });

        return res;
    }

    static async getSpecificProdutc(business_phone, product_position, token){
        const res = await server (`/products/${business_phone}/products_business/${product_position}/product_position`, {
            method: "get", 
            headers :{'ushopper-access-token': token}
    
        });
        return res;
    }

    static async deleteProduct(product_id, business_phone, token){
        const res = await server (`/products/${business_phone}/products_business`, {
            method: "delete", 
            data: {
                product_id
            }, 
            headers :{'ushopper-access-token': token}

        });
        return res;
    }

    static async updateProduct (data, product_id, token){
        const res = await server(`/product/${product_id}`, {
            method: 'PUT', 
            data: data, 
            headers :{'ushopper-access-token': token}

        });
        return res;
    }

    static async productupdateinventori (data, product_id, token){
        const res = await server(`/productupdateinventori/${product_id}`, {
            method: 'PUT', 
            data: data, 
            headers :{'ushopper-access-token': token}

        });
        return res;
    }

    static async filter (data, business_phone, token){
        const res = await server(`/productbyname/${business_phone}`, {
            method: 'post', 
            data: data, 
            headers :{'ushopper-access-token': token}
        });
        return res;
    }

}



