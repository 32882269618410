import React, { useEffect, useState } from "react";
//Components App
import Header from '../Navbar/Header';
import ButtonExit from "../Buttons/ButtonExit";
import Footer from "../Footer/Footer";
//Modals
import ModalEditar from "./ModalCliente/ModalEditar";
//Sweet Alert
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
//Icons 
import { FaPlus, FaTrash } from "react-icons/fa";

//api
import { ClientFetchAPI } from "../../../api/Clients";
//DataTable
import MUIDataTable from 'mui-datatables'

function Clientes() {
  const name_label = "Menú Principal  ";
  const [data_rows, setDataRows] = useState([])
  const [clientsdata, setclientsdata] = useState({
    clients: [],
    isLoaded: false,
  });

  const [eventHandler, setEventHandler] = useState(0)
  const [temporal, setTemporal] = useState({
    transaccions: [],
  });

  let datastorage = JSON.parse(localStorage.getItem("userData"));

  //Function to get the clients of the business 
  const getBusinessClients = async () => {
    //declaramos y asignamos el celular del negocio
    let business_phone = datastorage.Business.phone;
    try {
      const response = await ClientFetchAPI.getClientsByBusiness(
        business_phone
      );
      setclientsdata({
        clients: response.data.business_clients.clients,
        isLoaded: true,
      });

      setTemporal({
        clients: response.data.business_clients.clients,
        isLoaded: true,
      });

      setDataRows(response.data.business_clients.clients)
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  //Hook to load the info 
  useEffect(() => {
    (async () => {
      try {
        await getBusinessClients();
      } catch (err) {
        console.log(err);
      }
      setEventHandler(0)
    })();
  }, [eventHandler]);

  //Function to delete a client for a business 
  const deleteClient = async (business_phone, client_id) => {
    try {
      const response = await ClientFetchAPI.deleteClient(business_phone, client_id);
      console.log(response)
      Swal.fire({
        icon: "success",
        title: response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
      //We reload the table 
      setEventHandler(1);
    } catch (error) {
      console.log(error)
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };


  //Function to display a confirmation to delete the client info 
  const comfirmDeleteClient = (client_id) => {
    let buisness_phone = datastorage.Business.phone;

    console.log(buisness_phone)
    console.log(client_id)
    Swal.fire({
      icon: "info",
      title: "¿Quieres eliminar este cliente?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Eliminar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //We delete the client 
        deleteClient(buisness_phone, client_id);
      } else if (result.isDenied) {
        Swal.fire({
          position: "top-end",
          icon: "info",
          title: "Accion cancelada",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }

  //Colums for the table 
  const columns = [
    {
      name: "name",
      label: "Cliente",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "ruc",
      label: "RUC",
    },
    {
      name: "phone",
      label: "Telefono",
    },
    {
      name: '_id',
      label: "Acciones",
      options: {
        filter: false,
        customBodyRender: (name) => {
          return (
            <>
              <ModalEditar id={name} setEventHandler={setEventHandler}></ModalEditar>

              <button
                type="button"
                class="btn-danger-us btn text-secondary "
                data-id={name}
                onClick={() => comfirmDeleteClient(name)}
              >
                <FaTrash />
              </button>
            </>
          );
        },
      },
    }
  ]
  //Options of the table to filter 
  const filterOptions = {
    Acciones: {
      display: false,
    },
  };

  //Options of the table 
  const options = {
    selectableRows: false,
    print: false,
    download: false,
    responsive: true,
    filter: true,
    filterOptions: filterOptions,

  };

  const title_area= "Clientes"
  return (
    <>
      <main>
        <Header title={title_area}></Header>
        <div className="container head-content ">
          <div className="mt-2">
            <div className="container">
              {/* Button Exit  */}
              <div className="my-3 d-flex justify-content-between">
                <ButtonExit data={name_label} link='/dashboard' />
                <Link className="btn btn-sm btn-primary" to="/new_client">
                  <span className="text-white px-2">
                    <FaPlus/>
                    <strong > Crear Cliente </strong>                     
                  </span>
                </Link>
              </div>
              <MUIDataTable
                title={"Lista de Clientes "}
                columns={columns}
                data={data_rows}
                className="rounded rounded-us mb-4"
                options={options}
              />
            </div>
          </div>

        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

// Nacional o Extanjero
// Es empresa o gobierno

export default Clientes;
