import React from "react";
// import AboutUs from "./AboutUs";
// import Features from "./Features";
import Hero from "./Hero";
// import NavBar from "./NavBar";
// import Banner from "./Banner";
// import Footer from "./Footer";
import Whatsapp from './img/whatsapp_white.png'


//Styles
import "./navbar.css";
export default function LandingPage() {
return (
    <>
    {/* Navigation  */}

    <main>       
        <Hero/>
    </main>
    {/* <!-- End --> */}

    {/* <a href="https://api.whatsapp.com/send?phone=50767977778">
        <div class="btn-flotante d-flex justify-content-center align-items-center px-4 gap-1">
            <img src={Whatsapp} className="img-float-whatsapp" alt="" />
            <span className="h4 mt-2">
                Demo 
            </span>
        </div>
    </a> */}
    {/* <Footer></Footer> */}
    </>
);
}
