import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../../Navbar/Header';
import Footer from "../../Footer/Footer";
import axios from 'axios';
import ButtonExit from '../../Buttons/ButtonExit'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

//Icons 
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";

//Alerts 
import Swal from "sweetalert2";
import ErrorAlert from '../../../../components/Alerts/ErrorAlert';
//DataTable
import MUIDataTable from 'mui-datatables'

//Apis
import { TipoRecFetchAPI } from '../../../../api/iTipoRec';
import { TipoRucFetchAPI } from '../../../../api/dTipoRuc';
import { ClientFetchAPI } from '../../../../api/Clients';
import { DgiFetchAPI } from '../../../../api/Dgi';

const NewClient = () => {
   const [data_rows, setDataRows] = useState([])
   let new_client_dv = 0
   let nombre = ''
   let token = localStorage.getItem("ushopper-access-token");
   const navigate = useNavigate();
   // Estado que controla el paso actual del NewClient
   const [paso, setPaso] = useState(1);
   const [respuesta, setRespuesta] = useState(null);
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(false);
   const [cancel_send, setCancel_send] = useState();
   const [inputClass, setInputClass] = useState('')
   // Estados para almacenar los datos ingresados por el usuario
   const [responseData, setResponseData] = useState('');

   //Client Data
   const [client_name, setClient_name] = useState('');
   const [client_dv, setClient_dv] = useState('');
   const [client_type, setClient_type] = useState(1);
   const [client_ruc, setClient_ruc] = useState('');
   const [client_rec, setClient_rec] = useState('');
   const [client_msg_res, setClient_MsgRes] = useState('');

   const [client_email, setClient_email] = useState('');
   const [client_direction, setClient_direction] = useState('');
   const [client_phone, setClient_phone] = useState('');

   const [tiporecdata, setTiporecdata] = useState({
      infoTipo: []
   })
   const [tiporucdata, setTiporucdata] = useState({
      infoTipo: []
   })

   const handlePhoneChange = (status, phoneNumber, country) => {
      setClient_phone(phoneNumber)
   }
   // Función para manejar el envío del formulario de la DGI 
   const checkClientOnDgi = async (event) => {
      event.preventDefault();

      //Valdation RUC input Empty
      if (client_ruc === '') {
         const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
               toast.addEventListener('mouseenter', Swal.stopTimer)
               toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
         })
         Toast.fire({
            icon: 'warning',
            title: 'Por favor, ingrese un RUC.'
         })
      } else {

         // Enviar los datos del formulario
         let type_ruc = ''
         if (client_type == 'Persona' || client_type == 1) {
            type_ruc = 1
            console.log('Es persona o extrjr', client_type);
         } else if (client_type == 'Extranjero') {
            type_ruc = 3
            console.log('Es extrjr', client_type);
         } else {
            type_ruc = 2
            console.log('Es Empresa o gob', client_type);
         }
         console.log('Data ruc', client_ruc, 'tipo', type_ruc);

         //Post to info for DGI 
         try {
            setLoading(true);
            const response = await axios.post('https://ushopper-backend.herokuapp.com/api/dgi/contribuyent',
               {
                  ruc: client_ruc,
                  ruc_type: type_ruc,
               })

            console.log('response de la validacion', response.data)
            //Seteo de datos de la respuesta post    
            setClient_name(response.data.dgi_response.dNomb)
            setClient_ruc(response.data.dgi_response.dRuc)
            setClient_MsgRes(response.data.dgi_response.gResProc.dMsgRes)
            setClient_type(response.data.dgi_response.dTipoRuc)
            setClient_rec(response.data.dgi_response.dTipoRec)

            //Seteo de datos de la respuesta post 
            if (client_type === "Extranjero") setClient_rec("Extranjero")

            //Respuesta del servidor       
            console.log(responseData);
            console.log('Se ha enviado la peticion  ');
            console.log(response);
            nombre = response.data.dgi_response.dNomb
            console.log("nombre:", nombre);
            setRespuesta(response.data);
            setError(null);

            let dv_number = 0
            let mensaje_rec = client_msg_res
            dv_number = response.data.dgi_response.dDV

            console.log("Mensjae de dgi:", mensaje_rec);

            if (dv_number < 10) {
               setClient_dv("0" + dv_number)
               console.log("DV DE CLIENTE con 0 adicional :", "0" + dv_number);
               // new_client_dv  = "0" + client_dv ;
            } else {
               setClient_dv(dv_number)
               console.log("DV DE CLIENTE completo :", dv_number);
            }

            //Tipo de Ruc 
            const natural_us = "62cf62b0303f4e5a7008aa38"
            const juridico_us = "62cf62c1303f4e5a7008aa40"
            const extranjero = "66596f2c117de7d3840b2c3c"
            //Tipo de Receptor 
            const contribuyente_us = "62fe881c0d9c81f1cc105669"
            const consumidor_final_us = "62fe88310d9c81f1cc10566c"
            const gobierno_us = "62fe883b0d9c81f1cc10566e"
            const extranjero_us = "62fe88460d9c81f1cc105670"
            //!Validar algunos campos de el json a enviar el post  tipo de cliente y ruc 
            if (client_type == 'Persona' && client_rec == "Contribuyente") {
               setClient_type(natural_us)
               setClient_rec(consumidor_final_us)//contribuyente 
               console.log('Es persona o extrjr', client_type);
            } else if (client_type == 'Empresa' && client_rec == "Contribuyente") {
               setClient_type(juridico_us)
               setClient_rec(contribuyente_us)
               console.log('Es Empresa', client_type,);
            } else if (client_type == 'Gobierno' && client_rec == "Contribuyente") {
               setClient_type(juridico_us)
               setClient_rec(gobierno_us)

            } else if (client_type == 'Extranjero') {
               setClient_type(extranjero)
               setClient_rec(extranjero_us)
            }

         } catch (error) {
            console.log(error);
            setRespuesta(null);
            setError(error.response.data !== undefined ? error.response.data.message : 'Contribuyente no encontrado');
         } finally {
            setLoading(false);
         }
      }
   };


   //Function to create a new client 
   const createClient = async (e) => {
      let dataStorage = JSON.parse(localStorage.getItem("userData"));
      let clienttype = '';
      let receptortype = '';
      try {

         //Tipo de Ruc 
         const natural_us = "62cf62b0303f4e5a7008aa38"
         const juridico_us = "62cf62c1303f4e5a7008aa40"
         const nocontribuyente = "644941f1bfbadb6b5448f22f"
         const extranjero = "66596f2c117de7d3840b2c3c"
         //Tipo de Receptor 
         const contribuyente_us = "62fe881c0d9c81f1cc105669"
         const consumidor_final_us = "62fe88310d9c81f1cc10566c"
         const gobierno_us = "62fe883b0d9c81f1cc10566e"
         const extranjero_us = "62fe88460d9c81f1cc105670"

         console.log(client_type)
         console.log(client_rec)
         //!Validar algunos campos de el json a enviar el post  tipo de cliente y ruc 
         if (client_type == 1 && client_rec != "Contribuyente") {
            clienttype = natural_us
            receptortype = contribuyente_us
            setClient_type(clienttype)
            setClient_rec(receptortype)//contribuyente 
            console.log('Es persona o extrjr', client_type);
         } else if (client_type === 1 && client_rec == 'Contribuyente') {
            clienttype = natural_us
            receptortype = contribuyente_us
            setClient_type(clienttype)
            setClient_rec(receptortype)//contribuyente 
            console.log('Es persona natural', client_type);
         }
         else if (client_type === 1 && client_rec == "No contribuyente") {
            clienttype = nocontribuyente
            receptortype = consumidor_final_us
            setClient_type(clienttype)
            setClient_rec(receptortype)//contribuyente 
         }
         else if (client_type == 2 && client_rec == "Contribuyente") {
            clienttype = juridico_us
            receptortype = contribuyente_us
            setClient_type(clienttype)
            setClient_rec(receptortype)//contribuyente 
            console.log('Es Empresa', client_type,);
         } else if (client_type == 2 && client_rec == "Gobierno") {
            clienttype = juridico_us
            receptortype = gobierno_us
            setClient_type(clienttype)
            setClient_rec(receptortype)//contribuyente 

            //We get the client type id and client rec type id (Extranjero ) and we set the client type and client rec type like extranjero
         } else if (client_type == '66596f2c117de7d3840b2c3c' && client_rec == "62fe88460d9c81f1cc105670") {
            clienttype = extranjero
            receptortype = extranjero_us
            setClient_type(clienttype)
            setClient_rec(receptortype)//contribuyente 
            setClient_dv('Extranjero')
         }

         //!Validar algunos campos de el json a enviar el post  
         const response = await ClientFetchAPI.newClient({
            name: client_name,
            business_phone: dataStorage.Business.phone,
            receptor_type: receptortype,
            client_phone: client_phone,
            receptor_client_ruc_type: clienttype,
            Direccion: client_direction,
            email: client_email,
            ruc: client_ruc,
            dv: client_dv
         })

         console.log(response);
         navigate('/clientes')
      } catch (error) {
         ErrorAlert(error.response.data.message, 'Error', 'error');
         setClient_type(1)
         setClient_rec('')
         setClient_direction('')
         setClient_email('')
         setClient_phone('')

         setClient_name("")
         setClient_ruc('')
         setClient_MsgRes("")

         setRespuesta(null)
         setLoading(false)
         setPaso(1);

      }
   }

   //Function to get the types of receptors (clients)
   const getTipoRec = async () => {
      try {
         const response = await TipoRecFetchAPI.getTipoRec()
         setTiporecdata({
            infoTipo: response.data.iTipoRec
         })

         console.log(response);

      } catch (error) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: error.message,
            showConfirmButton: false,
            timer: 1500
         })
      }
   }

   //Function to get the types of rucs 
   const getTiporuc = async () => {
      try {
         const response = await TipoRucFetchAPI.getTipoRuc()
         setTiporucdata({
            infoTipo: response.data.dTipoRuc
         })
         console.log(response);
      } catch (error) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: error.message,
            showConfirmButton: false,
            timer: 1500
         })
      }
   }

   //Hook to load initial info 
   useEffect(() => {
      (async () => {
         try {
            await getTiporuc()
            await getTipoRec()
         } catch (error) {
            console.log(error)
         }
      })()
   }, [])

   // Función para mostrar el componente correspondiente al paso actual
   const stepForm = () => {
      switch (paso) {
         case 1:
            return (
               <>
                  <section className='form-type-user mt-3'>
                     <div className='text-center'>
                        <small className="text-muted opacity-75">Pregunta {paso}</small>
                        <p className='h4  my-2'>
                           ¿Qué tipo de usuario deseas crear?
                        </p>
                        <div className="list-group list-group-checkable d-grid gap-2 border-0 w-auto">
                           <input className="list-group-item-check pe-none"
                              type="radio"
                              name="type_ruc"
                              id="natural_user"
                              value="Persona"
                              checked={client_type === 'Persona'}
                              onChange={(e) => setClient_type(e.target.value)}
                           />
                           <label className="list-group-item rounded-3 py-3" htmlFor="natural_user">
                              Persona
                           </label>
                           {/* --------------------------------------------- */}

                           <input className="list-group-item-check pe-none"
                              type="radio"
                              name="type_ruc"
                              id="list_group_step2"
                              value="Empresa"
                              checked={client_type === 'Empresa'}
                              onChange={(e) => setClient_type(e.target.value)}

                           />
                           <label className="list-group-item rounded-3 py-3" htmlFor="list_group_step2">
                              Empresa
                           </label>

                           {/* --------------------------------------------- */}
                           <input className="list-group-item-check pe-none"
                              type="radio"
                              name="type_ruc"
                              id="list_group_step3"
                              value="Gobierno"
                              checked={client_type === 'Gobierno'}
                              onChange={(e) => setClient_type(e.target.value)}
                           />
                           <label className="list-group-item rounded-3 py-3" htmlFor="list_group_step3">
                              Gobierno
                           </label>
                           {/* --------------------------------------------- */}
                           <input className="list-group-item-check pe-none"
                              type="radio"
                              name="type_ruc"
                              id="list_group_step4"
                              value="Extranjero"
                              checked={client_type === 'Extranjero'}
                              onChange={(e) => setClient_type(e.target.value)}
                           />

                           <label className="list-group-item rounded-3 py-3" htmlFor="list_group_step4">
                              Extranjero
                           </label>
                        </div>
                        {/* --------------------------------------------- */}
                        <div className='d-flex justify-content-end mb-4'>
                           <button className="btn btn-primary px-4" onClick={() => setPaso(paso + 1)}>Siguiente <FiArrowRight /></button>
                        </div>
                     </div>
                  </section>

               </>
            );
         case 2:
            return (
               <>
                  {/* <small className='text-secondary'>
                     Tipo de usuario: {client_type}
                  </small> */}

                  <div className='text-center mt-2'>
                     <small className="text-muted opacity-75">Pregunta {paso}</small>
                     <p className='h4 my-2'>
                        Ingrese RUC/ID del cliente
                     </p>
                     <div className="row g-3 justify-content-center mt-2">
                        {/* -----Submit Input RUC------ */}
                        <div className="col-md-3">
                           <input type="text" className="form-control"
                              id="client_ruc"
                              placeholder='Ingrese RUC/ID'
                              value={client_ruc} onChange={(e) => setClient_ruc(e.target.value)}
                              required
                           />
                        </div>
                        <div className="col-md-2">
                           <button type="button" className="btn btn-success mb-3" onClick={checkClientOnDgi}>Verificar datos</button>
                        </div>
                        {/* -----Submit Input RUC------ */}
                        {loading ? (
                           <div>
                              <p className='pb-0 mb-0 text-muted'>
                                 Esperando Respuesta....
                              </p>
                              <div class="card-body">
                                 <h5 class="card-title placeholder-glow ">
                                    <span class="placeholder col-8 py-5 rounded border">
                                    </span>
                                 </h5>
                              </div>
                           </div>
                        ) : (
                           <div></div>
                        )}

                        {client_msg_res == "CONTRIBUYENTE NO INSCRITO" || client_msg_res == null
                           ? <>
                              <div className='row'>
                                 <div className='col-12 col-md-8 offset-md-2'>
                                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                       <h4 className="alert-heading">Usuario no encontrado!</h4>
                                       {/* <p>CONTRIBUYENTE NO INSCRITO.</p> */}
                                       <hr />
                                       <p className="mb-0">
                                          Lo siento, no pudimos validar el usuario que
                                          ha ingresado en la DGI.
                                          Sin embargo, aún puede completar el formulario.
                                          <strong>Puede seguir para completar el registro</strong>.
                                          {/* Verifique los datos ingresado en caso de saber que
                                          el usuario existe. */}
                                       </p>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                       <button className="btn btn-danger" onClick={() => window.location.reload()} >Cancelar</button>
                                       <button className="btn btn-success" onClick={() => setPaso(paso + 1)}>Continuar</button>
                                    </div>
                                 </div>
                              </div>
                           </>
                           : respuesta && (
                              <div className='row'>
                                 <div className='col-12 col-md-8 offset-md-2'>
                                    <div className="bg-light bg-gradient p-4 rounded border" role="alert">
                                       <h4 >Usuario encontrado</h4>
                                       <div>
                                          <div>La verificación de los datos  ha sido exitosa,
                                             Confirma que la información obtentida de la DGI es correcta.</div>
                                          <hr />
                                          <ul className="list-group list-group-flush rounded alert-success">
                                             <li className="list-group-item bg-success text-dark bg-opacity-10" >Nombre:{client_name}</li>
                                             <li className="list-group-item bg-success text-dark bg-opacity-10" >RUC:{client_ruc}</li>
                                             <li className="list-group-item bg-success text-dark bg-opacity-10">Tipo RUC:{client_type != 2 ? 'Natural' : 'Juridico'}</li>
                                             <li className="list-group-item bg-success text-dark bg-opacity-10">DV:{client_dv}</li>
                                             <li className="list-group-item  bg-success text-dark bg-opacity-10">Tipo de cliente:{client_rec}</li>
                                          </ul>

                                          <div className='d-flex justify-content-between'>
                                             <button className="btn btn-danger" onClick={() => window.location.reload()}>Cancelar</button>
                                             <button className="btn btn-success" onClick={() => setPaso(paso + 1)}>Confirmo</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                           )}
                        {error && (
                           <div>
                              <h2>Error al enviar el formulario:</h2>
                              <p>{error}</p>
                           </div>
                        )}

                     </div>
                  </div>
                  <div className='d-flex justify-content-between mb-4 mt-2'>
                     <button className="btn btn-outline-light border text-secondary px-4" onClick={() => setPaso(paso - 1)}><FiArrowLeft /> Anterior </button>
                  </div>
               </>
            );
         case 3:
            console.log('Paso 3', client_msg_res, client_type)
            return (
               <>
                  <div className='text-center mt-2'>
                     <small className="text-muted opacity-75">Pregunta {paso}</small>
                     <p className='h4  my-2'>
                        Datos del cliente
                     </p>
                     {respuesta && (
                        <div>
                           <div>
                              <div className="mb-3 row">
                                 <label htmlfor="client_name" className="col-sm-2 col-form-label">Nombre:</label>
                                 <div className="col-sm-6" >
                                    <input type="text"
                                       className="form-control"
                                       id="client_name"
                                       name="name"
                                       required
                                       value={client_name}
                                       onChange={(e) => setClient_name(e.target.value)}
                                    />
                                 </div>
                              </div>

                              <div className="mb-3 row">
                                 <label htmlfor="client_name" className="col-sm-2 col-form-label">RUC:</label>
                                 <div className="col-sm-6">
                                    <input type="text"
                                       className="form-control rounded"
                                       name="ruc"
                                       value={client_ruc}
                                       onChange={(e) => setClient_ruc(e.target.value)}
                                    />
                                 </div>
                              </div>

                              <div className="mb-3 row">
                                 <label htmlfor="client_name" className="col-sm-2 col-form-label">Tipo de RUC:</label>
                                 <div className="col-sm-6">
                                    <input type="text"
                                       className="form-control rounded"

                                       value={client_type != 2 && client_msg_res === 'CONTRIBUYENTE NO INSCRITO' ? 'No contribuyente' : client_type === '66596f2c117de7d3840b2c3c' && client_msg_res === null ? 'Extranjero' : client_type != 2 && client_msg_res === 'CONTRIBUYENTE EXISTENTE' ? 'Natural' : 'Juridico'}
                                       onChange={(e) => setClient_type(e.target.value)}
                                    />
                                 </div>
                              </div>

                              <div className="mb-3 row">
                                 <label htmlfor="client_name" className="col-sm-2 col-form-label">DV:</label>
                                 <div className="col-sm-6">
                                    <input type="text"
                                       className="form-control rounded"
                                       name="dv"
                                       id="client_name"
                                       value={client_type === '66596f2c117de7d3840b2c3c' && client_msg_res === null ? 'Extranjero' : client_dv}
                                       onChange={(e) => setClient_dv(e.target.value)}
                                    />
                                 </div>
                              </div>

                              <div className="mb-3 row">
                                 <label htmlfor="client_name" className="col-sm-2 col-form-label">Tipo de cliente:</label>
                                 <div className="col-sm-6">
                                    <input type="text"
                                       className="form-control rounded"
                                       value={client_type === '66596f2c117de7d3840b2c3c' && client_msg_res === null ? 'Extranjero' : client_rec}
                                       onChange={(e) => setClient_rec(e.target.value)}
                                    />
                                 </div>
                              </div>

                              <div className="mb-3 row">
                                 <label htmlfor="client_name" className="col-sm-2 col-form-label">Existe?:</label>
                                 <div className="col-sm-6">
                                    <input type="text"
                                       className="form-control rounded"
                                       value={client_msg_res}
                                    />
                                 </div>
                              </div>

                              <div className="mb-3 row">
                                 <label htmlfor="client_name" className="col-sm-2 col-form-label">Correo Electrónico:</label>
                                 <div className="col-sm-6">
                                    <input type="email"
                                       className="form-control rounded"
                                       id="client_email"
                                       value={client_email}
                                       name="email"
                                       placeholder='example@example.com'
                                       onChange={(e) => setClient_email(e.target.value)}
                                       required
                                    />
                                 </div>
                              </div>

                              <div className="mb-3 row">
                                 <label htmlfor="client_name" className="col-sm-2 col-form-label ">Teléfono:</label>

                                 <div className="col-sm-6">
                                    <IntlTelInput
                                       containerClassName="intl-tel-input"
                                       inputClassName="form-control rounded"
                                       placeholder='0000-0000'
                                       defaultCountry='pa'
                                       input
                                       type="tel"
                                       format={true}
                                       onPhoneNumberChange={handlePhoneChange}
                                    />
                                 </div>
                              </div>

                              <div className="mb-3 row">
                                 <label htmlfor="client_name" className="col-sm-2 col-form-label">Dirección:</label>
                                 <div className="col-sm-6">
                                    <textarea
                                       className="form-control rounded"
                                       id="client_direction"
                                       name='Direccion'
                                       required
                                       onChange={(e) => setClient_direction(e.target.value)}
                                       placeholder="Ingresar dirección">
                                    </textarea>
                                 </div>
                              </div>
                              <button type='button' onClick={e => createClient(e)} className="btn btn-success">Crear cliente</button>
                           </div>
                        </div>
                     )
                     }
                     {error && (
                        <div>
                           <h2>Error al enviar el formulario:</h2>
                           <p>{error}</p>
                        </div>
                     )}
                  </div>
                  <div className='d-flex justify-content-between mb-4'>
                     <button className="btn btn-light text-secondary" onClick={() => setPaso(paso - 1)}>Anterior</button>

                  </div>
               </>
            );
         default:
            return null;
      }
   };

   return (
      <>
         <main>
            <Header title='Nuevo Cliente' ></Header>
            <div className="container head-content ">
               <div className='mt-2'>
                  <ButtonExit data="Clientes" link='/clientes' />
               </div>
               {/* <div className='d-flex justify-content-center py-1'>
                     <div class="position-relative m-4 w-50">
                        <div class="progress" style={{ height: '1px' }}>
                           <div class="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <button type="button" class="position-absolute top-0 start-us-0 start-us-0 translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: '2rem', height: '2rem' }}>1</button>
                        <button type="button" class="position-absolute top-0 start-us-10 translate-middle btn btn-sm btn-secondary rounded-pill" style={{ width: '2rem', height: '2rem' }}>2</button>
                        <button type="button" class="position-absolute top-0 start-us-20 translate-middle btn btn-sm btn-secondary rounded-pill" style={{ width: '2rem', height: '2rem' }}>3</button>
                        <button type="button" class="position-absolute top-0 start-us-30 translate-middle btn btn-sm btn-secondary rounded-pill" style={{ width: '2rem', height: '2rem' }}>4</button>
                     </div>
                  </div> */}
               {stepForm()}
            </div >
         </main >
         <Footer></Footer>
      </>
   );
}
export default NewClient;