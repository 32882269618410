import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer';


const styles = StyleSheet.create({
   tableContainer: {
      flexDirection: 'row',
      marginTop: 0,
      alignContent: 'center',
   },
   textNo: {

      width: 100,
      fontSize: 9,
      alignItems: 'center',
      textAlign: 'center',
      borderColor: '#F1F1F1',
      borderWidth: 1,
      paddingVertical: 2,


   },

   textNormal: {
      width: 200,
      fontSize: 9,
      alignItems: 'center',
      textAlign: 'center',
      borderColor: '#F1F1F1',
      borderWidth: 1,
      paddingVertical: 2,

   },

   textDescription: {
      width: 700,
      fontSize: 9,
      alignItems: 'center',
      textAlign: 'center',
      borderColor: '#F1F1F1',
      borderWidth: 1,
      paddingVertical: 2,

   },
});

function Content({ products }) {

   //Function to list a products 
   const productsList = () => {
      const listproducts = products.map((product, index) => (
         <View style={styles.tableContainer}>
            <Text style={styles.textNo}> {index} </Text>
            <Text style={styles.textDescription}>
               {product.name}
            </Text>
            <Text style={styles.textNormal}>{product.cant}</Text>
            <Text style={styles.textNormal}>{typeof product.price === 'string' ? parseFloat(product.price).toFixed(2) : parseFloat(product.price).toFixed(2)}</Text>
            <Text style={styles.textNormal}>{typeof product.total_discount === 'string' ? parseFloat(product.total_discount).toFixed(2) : parseFloat(product.total_discount).toFixed(2)}</Text>
            <Text style={styles.textNormal}>{typeof product.subtotal_product === 'string' ? parseFloat(product.subtotal_product).toFixed(2) : parseFloat(product.subtotal_product).toFixed(2)}</Text>
            <Text style={styles.textNormal}>{typeof product.total_product_itbms === 'string' ? parseFloat(product.total_product_itbms).toFixed(2) : parseFloat(product.total_product_itbms).toFixed(2)}</Text>
            <Text style={styles.textNormal}>{typeof product.total_product === 'string' ? parseFloat(product.total_product).toFixed(2) : parseFloat(product.total_product).toFixed(2)}</Text>
         </View>
      ))

      return listproducts
   }
   return (
      <>

         {productsList()}


      </>
   )
}

export default Content