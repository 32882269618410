import React from "react";
import NavBar from "./NavBar";
import Logo from "./img/ushopper_green.png";
function PrivacyPolicy() {
    return (
        <>
        <NavBar></NavBar>
        <div className="px-4 py-2 pt-5 my-1 text-center">
            <img
            class="d-block mx-auto mb-4"
            src={Logo}
            alt=""
            width="190"
            height="85"
            />

        </div>

        <main>
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h4>Política de privacidad de Ushopper</h4>
                    <small>Última actualización 7 de julio del 2022</small>
                </div>

                <h5 className="subtitle mt-3"> 1. Introducción</h5>
                <p>
                    Cuando utiliza nuestros servicios, nos está confiando su información. 
                    Entendemos que esta es una gran responsabilidad y trabajamos arduamente 
                    para proteger su información y darle el control.
                    Esta Política de privacidad está destinada a ayudarlo a 
                    comprender qué información recopilamos, por qué la recopilamos y 
                    qué puede hacer para proteger su información.
                </p>

                <p>
                    
                    Algunos términos clave se definen a continuación, y a lo largo de este documento:
                    <br />
                    Ushopper es la empresa que recopila y procesa Datos personales para los fines descritos en esta Política.
                    Los datos personales son cualquier información relacionada con una persona física identificada o identificable ("sujeto de datos")
                    Cliente o Cliente es una entidad comercial legal con la que Ushopper tiene un acuerdo para proporcionar los Servicios
                    
                </p>

                <h5 className="subtitle mt-3"> 2. ¿Qué es Ushopper?</h5>
                <p>
                    Ushopper es una herramienta de WhatsApp Business CRM. 
                    El producto proporciona una "Plataforma de conversación en la nube" con WhatsApp Business 
                    que permite a nuestros Clientes almacenar, manipular, analizar y transferir mensajes entre 
                    sus sistemas comerciales y sus clientes en una variedad de canales de mensajería 
                    proporcionados por Ushopper y de terceros (el "Servicio"). .
                    <br />
                    Ushopper está comprometida con la protección de los Datos personales, incluidos los datos 
                    que utilizamos para nuestros propios fines y que mantenemos en nombre de nuestros Clientes.
                </p>

                <h5 className="subtitle mt-3">    3. Recopilación y uso de datos personales </h5>

                <p>Ushopper recopila información, incluidos datos personales, para los siguientes propósitos:</p>
                    <ol>
                        <li>Prestación y gestión del Servicio</li>
                        <li>Propósitos comerciales internos</li>
                        <li>Comunicación con usted y marketing</li>
                        <li>Reclutamiento y manejo de personal.</li>
                        <li>Cobro del pago del Servicio</li>
                        <li> Para comprender y mejorar nuestro Servicio y Sitio web</li>
                    </ol>
      
           
                <p>
                    Para brindar nuestros servicios a nuestros clientes, recopilamos el 
                    nombre del usuario, la dirección de correo electrónico y el número de 
                    teléfono y cargamos estos detalles en nuestros servidores seguros 
                     Estos detalles se usan solo para 
                    identificar a los usuarios y entregar el contenido que necesitan en la 
                    aplicación.
                </p>
                
            
                <p>
                Para una mejor experiencia y para brindar nuestro Servicio, es posible que le solicitemos que nos proporcione, ya sea directa o indirectamente, cierta información de identificación personal, que incluye, entre otros, el nombre de usuario y las credenciales de Oauth2. La información que solicitamos será retenida por nosotros y utilizada como se describe en esta política de privacidad.
                    <br />
                </p>
                <p>
                    Esta Política no pretende imponer ningún límite a lo que hacemos 
                    con los datos que se agregan y/o anonimizan para que ya no estén 
                    asociados con un individuo identificable (Sujeto de datos) o 
                    Cliente de los Servicios.
                    <br />
                    Esta Política no pretende imponer ningún límite a lo que hacemos 
                    con los datos que se agregan y/o anonimizan para que ya no estén 
                    asociados con un individuo identificable (Sujeto de datos) o 
                    Cliente de los Servicios.
                    <br />
                    Los servicios de Ushopper no están dirigidos a niños menores de 16 
                    años. Si se entera de que un niño menor de 16 años nos ha proporcionado 
                    datos personales sin consentimiento, comuníquese con nosotros.
                </p>


                <h5 className="subtitle mt-3">Prestación y gestión del Servicio </h5>
 
                <p>

                    En el curso de la prestación del Servicio, Ushopper puede recibir, acceder, analizar, procesar y mantener Datos personales en nombre de nuestros Clientes.

                    <br />                                   

                    Nuestros Clientes determinan los tipos de Datos personales que se recopilarán y utilizarán dentro del Servicio, cómo se utilizarán y divulgarán, y cuánto tiempo se almacenarán. Para cualquier pregunta relacionada con la forma en que nuestros Clientes utilizan sus Datos personales, comuníquese con ellos directamente.

                    <br />

                    Datos del Servicio, es la información que se procesa en nombre de nuestros Clientes durante la prestación del Servicio.
                    <br />
                    Las prácticas de privacidad de Ushopper relacionadas con los Datos de servicio no están cubiertas por esta Política de privacidad. Se detallan en la Declaración de privacidad de datos del servicio Ushopper a continuación.
                    Esta declaración de privacidad de datos explica cómo y qué datos personales recopilamos de usted a través de nuestro sitio web.
                    <br />
                    Los datos personales son todos los datos que pueden relacionarse con usted personalmente, como su nombre, dirección, cuenta(s) de correo electrónico, comportamiento del usuario.
                    Sus datos se almacenan en servidores seguros en la nube y hacemos todo lo posible para cifrar toda la información de identificación personal.
                    <br />
                    Si necesita que eliminemos los datos de su cuenta, puede solicitarlo enviándonos un correo electrónico a <b>info@smart-retail-group.com</b> .<br />
                    Al finalizar, todos sus datos (contactos, conversaciones, etc.) se eliminan permanentemente de nuestra base de datos a menos que solicite lo contrario.

                    <br />
                    La información de la cuenta, incluida la información de contacto, la información del perfil de usuario y la información sobre su método de pago, se recopila de usted cuando se registra o se autentica en nuestro Servicio y se utiliza para administrar el pago del Servicio, permitirnos brindar asistencia.
                </p>

            </div>
        </main>

        <footer className="pt-5 my-5 text-muted border-top d-flex justify-content-center">
            Created by the Smart Retail Group © 2022
        </footer>
  

        </>
    );
}

export default PrivacyPolicy;
