import React, { useEffect, useState } from "react";
import Header from '../Navbar/Header';
import Nabvar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import ButtonExit from "../Buttons/ButtonExit";
import TableCategories from "./TableCategories"
import ModalNewCategorie from "./ModalCreateCategorie";
//API 
import { dCodCPBScmpFetchAPI } from "../../../api/DcodCPBScmp";

function Categories() {
  const name_label = "Menú principal ";
  const [eventHandler, setEventHandler] = useState(0);
  const [panamanianCodes, setPanamanianCodes] = useState([]); 
  //Function to get the panamanian codes 
  const getPanamanianCodes = async () => {
    try {
      const response = await dCodCPBScmpFetchAPI.getPanamanianCodes(localStorage.getItem('ushopper-access-token'));
      ///We set the  codes 
      setPanamanianCodes(response.data.dCodCPBScmp);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  //Hook to load initial data 
  useEffect(() => {
    getPanamanianCodes()
  }, [])
  return (
    <>
      <main>
        <Header title="Categorias "></Header>
        <div className="container head-content ">
          <div className='d-flex justify-content-between mt-2'>
            <ButtonExit data="Menú Principal  " link='/dashboard' />
            <ModalNewCategorie setEventHandler={setEventHandler} panamanianCodes={panamanianCodes}></ModalNewCategorie>
          </div>
          <section className="data-section-us">
            <div className="card-body overflow-auto">
              <TableCategories eventHandler={eventHandler} setEventHandler={setEventHandler} panamanianCodes={panamanianCodes}></TableCategories>
            </div>
          </section>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Categories;
