import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class TipoRucFetchAPI { 

    static async getTipoRuc() {
        const res = await server("/dTipoRuc", {
            method: "get",
        });
        return res;
    }
    static async getTipoRucById(ruc_type_id) {
        const res = await server(`/dTipoRuc/${ruc_type_id}`, {
            method: "get",
        });
        return res;
    }
}
