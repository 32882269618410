import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': "application/json"}, 
});

export class CategoriesFetchAPI {
    static async newCategories(data, token){
     
        const res = await server ("/categories", {
            method: "post",
            data: data,
            headers :{'ushopper-access-token': token}
        });
        return res;
    }

    static async getCategoriesbyBuisness(business_phone, token){
        const res = await server (`/categories/${business_phone}`, {
            method: "get", 
            headers :{'ushopper-access-token': token}

        });
        return res;
    }

    static async getcategoriebyid(_id, token){
        const res = await server (`/categoriebyid/${_id}`, {
            method: "get", 
            headers :{'ushopper-access-token': token}

        });
        return res;
    }

    static async updateCategories(_id, data, token){
        const res = await server (`/categoriebyid/${_id}`, {
            method: "put",
            data: data, 
            headers :{'ushopper-access-token': token}

        });
        return res;
    }

    static async deleteCategories(categori_id, business_phone, token){
        const res = await server (`/categories/${business_phone}`, {
            method: "delete", 
            data: {
                categori_id
            },
            headers :{'ushopper-access-token': token}

        });
        return res;
    }

}