import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
//Components App
import Header from '../Navbar/Header';
import ModalDetails from "./ModalDetails"
import ButtonExit from "../Buttons/ButtonExit";
// External Componets
import moment from 'moment-timezone';

//Libraries
import Swal from "sweetalert2";
//DataTable
import MUIDataTable from 'mui-datatables'
//api
import { OrdersFetchAPI } from "../../../api/Orders";


function Transactions() {

  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const [minDate, setInitialDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
  const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')

  const [data_rows, setDataRows] = useState([])

  const [option, setOption] = useState({
    option: "Principal",
  });

  const showOptions = (option) => {
    setOption({
      option: option,
    });
  };

  let scope = 1;
  const [infotransaccion, setInfotransaccion] = useState({
    transaccions: [],
    isLoaded: false,
  });

  const [temporal, setTemporal] = useState({
    transaccions: [],
  });

  let datastorage = JSON.parse(localStorage.getItem("userData"));
  let token = localStorage.getItem('ushopper-access-token');
  let business_ruc = datastorage.Business.ruc

  const getTransactions = async (minDate, maxDate) => {
    Swal.fire({
      title: "Cargando",
      html: 'Cargando informacion',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: () => {
        Swal.showLoading();
      }
    });
    try {
      const response = await OrdersFetchAPI.getBusinessOrdersByDate(business_ruc, minDate, maxDate, token);
      console.log(response);
      setInfotransaccion({
        transaccions: response.data.business_orders,
        isLoaded: true,
      });

      setTemporal({
        transaccions: response.data.business_orders,
        isLoaded: true,
      });
      //?Cargan los  datos a la DataTable
      setDataRows(response.data.business_orders)
      Swal.fire({
        icon: "success",
        title: "Realizado",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "No hay ordenes para las fechas establecidas",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };


  const columns = [
    {
      name: "code", 
      label: "Codigo de transaccion"
    }, 
    {
      name: "receptor_name",
      label: "Cliente",
      options: {
        responsive: 'stacked',
      }
    },
    {
      name: "invoice_type",
      label: "Tipo",
    },
    {
      name: "payment_method",
      label: "Metodo de Pago",
    },
    {
      name: "payment_type",
      label: "Tipo de Pago",
    },
    {
      name: "total_cash_received",
      label: "Total Recibido",
    },
    {
      name: "createdAt",
      label: "Fecha",
      options: {
        filter: false,
      }
    },
    {
      name: "receptor_ruc",
      label: "RUC",
    },
    {
      name: '_id',
      label: "Acciones",
      options: {
        filter: false,
        customBodyRender: (name) => {
          return (
            <ModalDetails id={name}></ModalDetails>
          );
        },
      },
    },
  ]

  const filterOptions = {
    Acciones: {
      display: false,
    },
  };

  const options = {
    selectableRows: false,
    print: false,
    download: false,
    responsive: true,
    filter: true,
    filterOptions: filterOptions,

  };


  return (
    <>
      <main>
        <Header title="Transacciones "></Header>
        <div className="container head-content ">
          <div className='d-flex justify-content-start mt-2'>
            <ButtonExit data="Menú Principal  " link='/dashboard' />
          </div>

          <div>
            <h4>Filtros</h4>
            <div className="row gx-3 gy-2 align-items-center bg-white p-2 my-3 border-sp border shadow-sm">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label htmlFor="input_from">Desde</label>
                  <input type="date" name="initialDate" onChange={e => setInitialDate(e.target.value + "T00:00:00.000+00:00")} className="control mt-3" />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label htmlFor="input_from">Hasta</label>
                  <input type="date" name="finishDate" onChange={e => setMaxDate(e.target.value + "T23:59:59.000+00:00")} className="control mt-3" />
                </div>
              </div>

              <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                <button type="button" onClick={e => getTransactions(minDate, maxDate)} className="btn btn-success px-5">Aceptar</button>
              </div>
            </div>
          </div>

          <section className="data-section-us">
            <div>
              <div className="mt-5">
                <MUIDataTable
                  title={"Lista de Transacciones"}
                  columns={columns}
                  data={data_rows}
                  className="rounded rounded-us mb-4"
                  options={options}
                />
              </div>
            </div>
          </section>
        </div>
      </main>
      {/* <Footer></Footer> */}
    </>
  );
}

export default Transactions;
