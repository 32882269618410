import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': "application/json"}, 
});


export class UsersFetchAPI {
    static async getuserbyphone(user_phone){
        const res = await server (`/user/${user_phone}`, {
            method: "get",
        });
        return res;
    }

    static async getuserbyid(_id){
        const res = await server (`/userbayid/${_id}`, {
            method: "get",
        });
        return res;
    }

    static async updateuser(data, user_phone){
        const res = await server (`/user/${user_phone}`, {
            method: "put",
            data: data
        });
        return res;
    }
}
