import React from 'react'
import { View, StyleSheet, Text, Font } from '@react-pdf/renderer';
import FontsRoboto from './Fonts/Roboto-Bold.ttf'

//Styles 
Font.register({
   family: 'Roboto',
   fonts: [
      {
         src: FontsRoboto,
         fontWeight: 'bold',
      },

   ],
});

const styles = StyleSheet.create({
   tableContainer: {
      flexDirection: 'row',
      marginTop: 24,

   },

   tableTotal: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 5,


   },

   textNo: {
      backgroundColor: '#F1F1F1',
      width: 200,
      fontSize: 9,
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: 5,
      borderColor: '#00000',
      border: 0,
      fontFamily: 'Roboto',



   },

   textNormal: {
      backgroundColor: '#F1F1F1',
      width: 200,
      fontSize: 10,
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: 4,
      fontWeight: 'bold',
      fontFamily: 'Roboto',


   },

   textDescription: {
      width: 700,
      fontSize: 10,
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: 4,
      fontFamily: 'Roboto',
      backgroundColor: '#F1F1F1',
      borderColor: '#000000',
      border: 0,

   },
   alignText: {
      flexDirection: 'row',
   },
   textTotal: {
      fontSize: 10,
      fontFamily: 'Roboto',
      textAlign: 'right',
   },
   text: {
      fontSize: 9,        
      textAlign: 'right',
      borderColor: '#F1F1F1',
      borderWidth: 1,
      width: 50,
   },
});
function FullValue(props) {
   return (
      <>
         <View style={styles.tableTotal}>
            <View style={styles.alignText}>
               <Text style={styles.textTotal}>{props.title} </Text>
               <Text style={styles.text}> {props.value}  </Text>
            </View>
         </View>

      </>
   )
}

export default FullValue