import React, { useEffect, useState } from "react";
//Components App
import Footer from "../Footer/Footer";
import Header from "../Navbar/Header";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Swal from "sweetalert2";
//api
import { BusinessFetchAPI } from '../../../api/Business';
//Icons
import { FaPlusCircle, FaSlideshare } from "react-icons/fa";
//utils 
import { RenderIf } from '../../../utils/RenderIf';

function ListCredentials() {
    //To set business credentials 
    const [business, setBusiness] = useState([]);
    let token = localStorage.getItem('ushopper-access-token');
    const [show, setShow] = useState(false);
    const [businessSelected, setBusinessSelected] = useState({
        id: 0,
        ruc: 0,
        name: '',
        credits: 0,
        dv: 0
    });
    const [eventHandler, setEventHandler] = useState(0);
    const [modalCase, setModalCase] = useState('add_credits');
    const handleClose = () => setShow(false);
    const handleShow = (id, ruc, modal) => {
        setShow(true);
        setModalCase(modal);
        getBusinessById(id)
    }
    //Function to get business credentials
    const getBusiness = async () => {
        try {
            const response = await BusinessFetchAPI.getBusinessByCredentialsType('production', token);
            setBusiness(response.data.business);
        } catch (err) {
            console.log(err)
        }
    }

    //Function to get business buy id 
    const getBusinessById = async (id) => {
        try {
            const response = await BusinessFetchAPI.Businessbyid(id, token);
            setBusinessSelected({
                id: response.data.Buisnessbyid._id,
                ruc: response.data.Buisnessbyid.ruc,
                dv: response.data.Buisnessbyid.dv,
                credits: response.data.Buisnessbyid.credits,
                name: response.data.Buisnessbyid.name
            });
        } catch (err) {
            console.log(err)
        }
    }
    //We generate the business list 
    const generateListBusiness = () => {
        let rows = business.map((business) => (
            <div className="col-lg-12 col-sm-12 mt-3 " key={business._id}>
                <div className="list-group-checkable ">

                    <label className="list-group-item ">
                        <div className=" d-flex justify-content-between align-items-center p-2">
                            <span className="d-block  opacity-80">
                                Empresa:&nbsp; {business.name}&nbsp;
                                Ruc: &nbsp;{business.ruc} &nbsp;
                                Dv: &nbsp;{business.dv} &nbsp;
                                Creditos: &nbsp;{business.credits} &nbsp;
                            </span>

                            <div className=" d-flex justify-content-between small opacity-75">
                                <button className="card-up btn btn-secondary-us bg-btn-secundary " onClick={() => handleShow(business._id, business.ruc, 'add_credits')}>
                                    <FaPlusCircle className="text-secondary-us " />
                                </button>
                                <button className="card-up btn btn-secondary-us bg-btn-secundary " onClick={() => handleShow(business._id, business.ruc, 'share_credits')}>
                                    <FaSlideshare className="text-secondary-us " />
                                </button>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        ));
        return (
            <>
                {rows}
            </>
        )
    }

    //Function to add credits 
    const addCredits = async (e) => {
        e.preventDefault()
        const form = new FormData(e.target)
        form.append('ruc', businessSelected.ruc);
        let credits = Object.fromEntries(form.entries());
        try {
            await BusinessFetchAPI.addBusinessCredits(credits, token)
            setEventHandler(1)
            Swal.fire({
                icon: 'success',
                title: 'Creditos acreditados correctamente ',
                showConfirmButton: false,
            })
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: err.response.data.error,
                showConfirmButton: false,
            })
        }
    }

    //Function to share credits 
    const shareCredits = async (e) => {
        e.preventDefault();
        try {
            const form = new FormData(e.target);
            form.append('sender_ruc', businessSelected.ruc);
            let shareCreditForm = Object.fromEntries(form.entries());
            //We share the business credits 
            await BusinessFetchAPI.shareBusinessCredits(shareCreditForm, token);
            //We reset the data 
            setEventHandler(1); 
            getBusinessById(businessSelected.id);
            //Alert
            Swal.fire({
                icon: 'success',
                title: 'Creditos compartidos correctamente ',
                showConfirmButton: false,
            })
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Error al compartir creditos',
                showConfirmButton: false,
            })
        }
    }

    //Load initial data 
    useEffect(() => {
        getBusiness();
        setEventHandler(0)
    }, [eventHandler]);

    return (
        <>
            <Header></Header>
            <div className="mt-5 ">
                <div className="container">
                    <div className="head-section-us mt-4 py-lg-5 py-sm-2  ">
                        <div className="text-center mb-3">
                            <h2 className="main-title"> Lista de empresas </h2>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-content-center gap-1 mt-4">
                        <h5 className="sub-title">Lista de empresas con creditos en produccion</h5>
                    </div>
                </div>
            </div>
            <section className="data-section-us">
                <div className="container ">
                    {/* Carga de datos  */}
                    {generateListBusiness()}
                </div>
            </section>

            <Footer></Footer>
            {/** Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>{modalCase === 'add_credits' ? 'Agregar creditos' : 'Compartir creditos'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/** Modal body of add credits */}
                    <RenderIf isTrue={modalCase === 'add_credits'}>
                        <Form onSubmit={addCredits} encType='multipart/form-data'>
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Creditos</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder='Ingrese el numero de creditos a agregar'
                                            name='credits'
                                        />
                                    </Form.Group>
                                </Col>

                                <div className="mt-3 d-flex justify-content-end">
                                    <Button
                                        size="sm"
                                        variant="success"
                                        type="submit"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </Row>
                        </Form>
                    </RenderIf>
                    {/** Modal body of share credits */}
                    <RenderIf isTrue={modalCase === 'share_credits'}>
                        <Form onSubmit={shareCredits} encType='multipart/form-data'>
                            <Row>
                                <Col md={12}>
                                    <p>Empresa seleccionada: {businessSelected.name}</p>
                                </Col>
                                <Col md={12}>
                                    <p>Creditos disponibles: {businessSelected.credits}</p>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Seleccione empresa a compartir</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ingrese el ruc de la empresa a compartir creditos"
                                            name="beneficiary_ruc"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Creditos</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder='Ingrese el numero de creditos a compartir'
                                            name='credits'
                                        />
                                    </Form.Group>
                                </Col>
                                <div className="mt-3 d-flex justify-content-end">
                                    <Button
                                        size="sm"
                                        variant="success"
                                        type="submit"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </Row>
                        </Form>
                    </RenderIf>
                </Modal.Body>
            </Modal>
        </>
    );
}


export default ListCredentials;
