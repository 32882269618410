import axios from 'axios'; 



const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': "application/json"}, 
});


export class OcrFetchAPI{ 
    static async uploadInvoice(data){ 
        const res = await server(`/invoice/ocr`, { 
            method: 'POST', 
            data: data
        })
        return res;
    }

    static async analyzeQr(data){ 
        const res = await server(`/invoice/qr`, {
            method: 'POST', 
            data: data
        }); 
        return res
    }

    static async analyzeUrl(url){ 
        const res = await server(`/invoice/qr/dgi_url`, { 
            method: 'POST',
            data: {
                url_path: url
            }
        })
        return res
    }


    static async registerInvoice(data){ 
        const res = await server(`register/invoice`, { 
            method: 'POST', 
            data: data
        }); 
        return res
    }
}