import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";

// import img_bg from "./image.png";
import { FaPlus } from "react-icons/fa";
//api
import { ProductsFetchAPI } from "../../../../api/Products";
import { CategoriesFetchAPI } from "../../../../api/Categories";
//Context
import UserContext from "../../../../context/UserContext";

function ModalProductoRegistro({setLoad}) {
  const [show, setShow] = useState(false);
  let dataStorage = JSON.parse(localStorage.getItem("userData"));
  let token = localStorage.getItem('ushopper-access-token');
  const [categories, setCategories] = useState({
    infoCategorie: [],
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submit = async (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("business_phone", dataStorage.Business.phone);
    let newproduct = Object.fromEntries(form.entries());

    try {
      const response = await ProductsFetchAPI.newProduct(form, token);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    //We close the modal 
    setShow(false)
    //We reload the component with the new data 
    setLoad(1)
  };

  //consulta las categorias
  const getcategories = async () => {
    let business_phone = dataStorage.Business.phone;
    try {
      const responsecategories =
        await CategoriesFetchAPI.getCategoriesbyBuisness(business_phone, token);
      setCategories({
        infoCategorie: responsecategories.data.business_categories.categories,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getcategories();
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const infocategoriesSelect = () => {
    let getdata = categories.infoCategorie;
    let option = getdata.map((data) => (
      <option value={data.name}>{data.name}</option>
    ));
    return <>{option}</>;
  };

  return (
    <>
      <button className="btn btn-sm btn-primary" onClick={handleShow}>
        <span className="text-white px-2">
          <FaPlus />
          <strong > Nuevo producto</strong>
        </span>
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Registrar un producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="needs-validation"
            onSubmit={submit}
            encType="multipart/form-data"
          >
            {/* <div class="d-flex justify-content-center ">
              <img src={img_bg} class="img-modal rounded-circle " />
            </div> */}
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="item"
                name="name"
                required
                autoFocus
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="price">
              <Form.Label>Precio</Form.Label>
              <Form.Control
                type="text"
                placeholder="0.00"
                name="price"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="">
              <Form.Label>Categoria</Form.Label>
              <Form.Select aria-label="" name="categorie_name">
                <option>Seleccione una categoria </option>
                {infocategoriesSelect()}
              </Form.Select>
            </Form.Group>

            <Form.Label>Tipo</Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Producto"
                  value="Producto"
                  name="typeItem"
                  type={type}
                  id={`inline-${type}-1`}
                />
                <Form.Check
                  inline
                  label="Servicio"
                  value="Servicio"
                  name="typeItem"
                  type={type}
                  id={`inline-${type}-2`}
                />
              </div>
            ))}

            <Form.Group className="mb-3" controlId="clientId">
              <Form.Label>Codigo</Form.Label>
               <Form.Control
                type="text"
                placeholder="Code"
                name="upc_code"
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="clientId">
              <Form.Label>Impuesto</Form.Label>
              <Form.Select aria-label="" name="tax">
                <option>Seleccione un impuesto</option>
                <option value="0%">0%</option>
                <option value="7%">7%</option>
                <option value="10%">10%</option>
                <option value="15%">15%</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="clientId">
              <Form.Label></Form.Label>
              <Form.Control
                type="file"
                placeholder="Imagen"
                name="files"
              />
            </Form.Group>

            <div className="mt-3 d-flex justify-content-end">
              <Button size="sm" variant="success" type="submit">
                Guardar
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalProductoRegistro;
