import React from "react";
//Components
import Container from "react-bootstrap/Container";
import NavBar from "./Navbar/Navbar";
import CardsOptions from "./Cards/CardOptions";
import Header from "./Navbar/Header";
import Footer from "./Footer/Footer";
import { RenderIf } from "../../utils/RenderIf";
//Style
import "../../assets/css/style.css";

export default function Dashboard() {
  let userData = localStorage.getItem('userData');

  return (
    <>
      {/* <NavBar></NavBar>       */}
      <main>
        <Header></Header>
        <div className="container head-content ">
          <CardsOptions userData={JSON.parse(userData)}></CardsOptions>
        </div>
      </main>

      <Footer></Footer>
    </>
  );
}
