import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class FetchAPI {
    static async register(data) {
        const res = await server("/auth/register", {
            method: "post",
            data: data,
        });

        return res;
    }

    static async login(data) {
        const res = await server("/auth/login", {
            method: "post",
            data: data,
        });

        return res;
    }

    static async validtoken(data) {
        const res = await server("/auth/tokenIsValid", {
            method: "post",
            headers: { 'ushopper-access-token': data },

        });

        return res;
    }

    static async me(data) {
        const res = await server("/auth/me", {
            method: "get",
            headers: { 'ushopper-access-token': data },

        });
        return res;
    }

    //Function to generate business token 
    static async generateBusinessToken(business_ruc, token) {
        const res = await server("/auth/business/token", {
            method: "post",
            data: {
               business_ruc: business_ruc
            },
            headers: { 'ushopper-access-token': token },
        });

        return res;
    }



}



