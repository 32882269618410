import React from 'react'

function CardInfo(props) {
   return (
      <>
         <div class={props.col}>
            <div class="border border-sp p-2 mt-3">
               <label class="small text-muted">
                  {props.title}
               </label>
               <p class="transaction-text  text-center overflow-x-auto" >
                  {props.data}
               </p>
            </div>
         </div>
      </>
   )
}

export default CardInfo