import React from 'react'
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
//Fonts 
import FontsRoboto from './Table/Fonts/Roboto-Bold.ttf'



Font.register({
   family: 'Roboto',
   fonts: [
      {
         src: FontsRoboto,
         fontWeight: 'bold',
      },
   ],
});

const styles = StyleSheet.create({
   container: {
      marginTop: 15,
      marginBottom: 10,
      padding: 10,

   },
   text: {
      fontSize: 10,
   },
   textNormal: {
      fontSize: 10,
      fontFamily: 'Roboto'
   },
   alignText: {
      flexDirection: 'row',
   },

});

function Emitter({ emitter_data }) {

   console.log(emitter_data)
   const bussines_name = emitter_data.name;
   const ruc = emitter_data.ruc;
   const dv = emitter_data.dv;
   const direction = emitter_data.direction;

   return (
      <>
         <View style={styles.container}>
            {/* Bussiness Name  */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>Emisor: </Text>
               <Text style={styles.text}> {bussines_name}</Text>
            </View>
            {/* Bussiness RUC */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>RUC: </Text>
               <Text style={styles.text}> {ruc}</Text>
            </View>
            {/*  Bussiness DV */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>DV: </Text>
               <Text style={styles.text}> {dv}</Text>
            </View>
            {/* Direction */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>Dirección: </Text>
               <Text style={styles.text}> {direction}</Text>
            </View>
         </View>
      </>
   )
}

export default Emitter