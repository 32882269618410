import React from "react";
import Imge from "./img/hero-phone-02.png";
import Video_Ushopper from "./video/ushopper.gif";
import logo from "./img/logo/ushopper.png";
function Hero() {
  return (
    <>
    <section className="hero-banner py-5 py-lg-2 bg-color-sencudary">
        {/* <div className=" z-index-1">
            <img src={Banner_Hero} alt="" />
        </div> */}
        <div className="container mt-lg-5">
            <div className="row align-items-center bg"  data-aos="fade-right" data-aos-offset="200" >
                <div className="col-lg-4 order-2 ">
                    <div className="d-flex justify-content-center z-index-2 content-img">
                        <img src={Imge} className="img-fluid img-hero " alt="Web Development"/>
                        <div className="">
                            {/* <img src={Video_Ushopper } className="img-animation-ushopper"/> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 hero-ushopper"  data-aos="fade-left" data-aos-offset="200" >

                    <div>
                        <img src={logo} className="logo-ushopper" alt="Logo"/>
                        <h1 className="text-ushopper title-hero-ushopper">
                            En manteminiento                            
                        </h1>
                        <p className=" p-ushopper my-3">
                        Pronto volveremos para innovar y automatizar los métodos de pago de tú negocio.
                        </p>
                    {/* <h1 className=" title-hero-ushopper">Facturar desde 
                        <span className="color-ushopper"> Whatsapp </span> 
                        <div className="slider ">                      
                            <div className="slide">                                                       
                                <div><div>Fácil y sencillo</div></div>
                                <div><div>Simple</div></div>  
                                <div><div>Rápido</div></div>                     
                            </div>                      
                        </div>
                    </h1> */}
{/*         
                 <p className=" p-ushopper my-3">
                        Permite a nuestros clientes almacenar, manipular, 
                        analizar y transferir facturas entre sus sistemas 
                        comerciales y a sus clientes sin aplicaciones adicionales.
                    </p>  */}

                    <div className="d-flex justify-content-xl-start justify-content-sm-center">
                        {/* <a href="#" className="btn btn-lg color-bg-ushopper border mt-3 text-white"> Suscribirse </a> */}
                    </div>

                    </div>

                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Hero;
