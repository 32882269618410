import React, { useEffect, useContext, useState } from "react";
import Header from '../Navbar/Header';
import Nabvar from "../Navbar/Navbar";
import ButtonExit from "../Buttons/ButtonExit";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FaSearch } from "react-icons/fa";
import { FaSave } from "react-icons/fa";
//api
import { ProductsFetchAPI } from "../../../api/Products";
import { CategoriesFetchAPI } from "../../../api/Categories";
//DataTable
import MUIDataTable from 'mui-datatables'




function Stock() {

  const [data_rows, setDataRows] = useState([])

  const [productsdata, setproductsdata] = useState({
    products: [],
    isLoaded: false,
  });
  const [option, setOption] = useState({
    option: "Principal",
  });

  const [temporal, setTemporal] = useState({
    transaccions: [],
  });

  const [categoriesfilter, setCategoriesfilter] = useState({
    infocategories: [],
  });
  let datastorage = JSON.parse(localStorage.getItem("userData"));
  let business_phone = datastorage.Business.phone;

  //----------------------------Filter----------------------------------------------
  const showOptions = async (option) => {
    await getproducts();
    setOption({
      option: option,
    });
  };

  //consulta los productos filtrados
  const submit = async (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    let filtroproduct = Object.fromEntries(form.entries());
    try {
      const response = await ProductsFetchAPI.filter(
        filtroproduct,
        business_phone
      );

      setproductsdata({
        products: response.data.response.products,
        isLoaded: true,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  //llama todos las categorias
  const getcategories = async () => {
    try {
      const response =
        await CategoriesFetchAPI.getCategoriesbyBuisness(business_phone);
      setCategoriesfilter({
        infocategories:
          response.data.business_categories.categories,
      });

      console.log(response);
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  //popula la informacion de las categorias en el select
  const infocategoriesSelect = () => {
    let getdata = categoriesfilter.infocategories;
    let option = getdata.map((data) => (
      <option value={data._id}>{data.name}</option>
    ));
    return <>{option}</>;
  };

  //---------------------------------Table-------------------------------------------

  //consulta todos los productos
  const getproducts = async () => {
    //declaramos y asignamos el celular del negocio
    try {
      const response = await ProductsFetchAPI.getProductsByBusiness(
        business_phone
      );
      setproductsdata({
        products: response.data.business_products.products,
        isLoaded: true,
      });

      setTemporal({
        products: response.data.business_products.products,
        isLoaded: true,
      });

      console.log(response);
      //?Cargan los  datos a la DataTable
      setDataRows(response.data.business_products.products)
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "No hay productos resgistrados",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  //actualiza la cantidad de existencia de un producto
  const submitamount = async (event, id) => {
    event.preventDefault();
    const form = new FormData(event.target);
    let amountupdate = Object.fromEntries(form.entries());

    try {
      const response = await ProductsFetchAPI.productupdateinventori(
        amountupdate,
        id
      );
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
      await getproducts();
    } catch (error) {
      console.log(error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getproducts();
        await getcategories();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  //filtro para buscar cualquier coincidencia
  function handleChange(event) {
    let resultFilter = [];

    if (event.target.value == "") {
      setproductsdata(temporal);

      console.log(event.target.value + " entre aqui");
    } else {
      for (
        let recorrer = 0;
        recorrer < productsdata.products.length;
        recorrer++
      ) {
        const examinarproducto = productsdata.products[recorrer];

        const info = JSON.stringify(examinarproducto);
        const resultado = info
          .toLowerCase()
          .includes(event.target.value.toLowerCase());

        if (resultado == true) {
          resultFilter.push(examinarproducto);

          setproductsdata({
            products: resultFilter,
            isLoaded: true,
          });
          console.log(resultFilter);
        }
      }
    }
  }

  const tabledata = () => {
    let scope = 1;
    let getdata = productsdata.products;
    let row = getdata.map((data) => (
      <div className="col-lg-6 col-sm-12 mt-2 ">
        <div className="list-group-checkable ">
          {/* <img className="img-table rounded border " src={data.image}></img>
          <h6> {data.name} </h6> 
          {data.price}
          {!data.categorie ? <td>No registrado</td> : <>{data.categorie.name}</>}
          {!data.typeItem ? <td>No registrado</td> : <>{data.typeItem}</>}
          {data.tax.dTasaITBMSName} */}

          <label class="list-group-item" for={data._id}>
            <div className="d-flex justify-content-between align-items-center p-2">
              <img className="img-table" src={data.image}></img>
              <div className="">
                <h6>{data.name}</h6>
                <span class="d-block small opacity-50 ">
                  <small>Precio: {data.price}</small>
                  <small>
                    {" "}
                    {!data.categorie ? (
                      <td>No registrado</td>
                    ) : (
                      <>{data.categorie.name}</>
                    )}
                  </small>
                  <small>
                    &nbsp;Tipo:
                    {!data.typeItem ? (
                      <span>No registrado</span>
                    ) : (
                      <>{data.typeItem}</>
                    )}
                  </small>
                  <small>Impuesto:{data.tax.dTasaITBMSName}</small>
                </span>
              </div>

              <div className="d-flex justify-content-end small opacity-75">
                <InputGroup className="mb-3 w-50">
                  <Form
                    className="d-flex justify-content-between"
                    onSubmit={(e) => submitamount(e, data._id)}
                    encType="multipart/form-data">


                    <Form.Control
                      placeholder={data.count}
                      value={data.count}
                      name="count"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />


                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>Guardar</Tooltip>
                        }
                      >
                        <button
                          type="submit"
                          className="btn btn-secondary-us bg-btn-secundary border"
                        >
                          <FaSave className="text-secondary-us " />
                        </button>
                      </OverlayTrigger>
                    ))}
                  </Form>
                </InputGroup>
              </div>
            </div>
          </label>
        </div>
      </div>
    ));
    return <>{!productsdata.isLoaded ? <td>Cargando</td> : <>{row}</>}</>;
  };

  const columns = [
    {
      name: "image",
      label: "Imagen",
      options: {
        filter: false,
        customBodyRender: (name) => {
          return (
            <img className="img-table" src={name} />
          );
        },
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        responsive: 'stacked',
      }
    },
    {
      name: "typeItem",
      label: "Tipo",
    },
    {
      name: "price",
      label: "Precio",
    },
    {
      name: "count",
      label: "Cantidad",
    },

    {
      name: '_id',
      label: "Editar Cantidad",
      options: {
        filter: false,
        customBodyRender: (name) => {
          return (
            <div>
              <InputGroup className="mb-3 w-50">
                <Form
                  className="d-flex justify-content-between"
                  onSubmit={(e) => submitamount(e, name)}
                  encType="multipart/form-data">


                  <Form.Control
                    name="count"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    placeholder="Ingrese"

                  />


                  {["top"].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`}>Guardar</Tooltip>
                      }
                    >
                      <button
                        type="submit"
                        className="btn btn-secondary-us bg-btn-secundary border"
                      >
                        <FaSave className="text-secondary-us " />
                      </button>
                    </OverlayTrigger>
                  ))}
                </Form>
              </InputGroup>
            </div>

          );
        },
      },
    },

  ]

  const filterOptions = {
    Acciones: {
      display: false,
    },
  };

  const options = {
    selectableRows: false,
    print: false,
    download: false,
    responsive: true,
    filter: true,
    filterOptions: filterOptions,

  };


  return (
    <>
      <main>
        <Header></Header>
        <div className="container head-content ">
          <div className='d-flex justify-content-between mt-2'>
            <ButtonExit data="Menú Principal  " link='/dashboard' />
          </div>
          {/* <div className='text-center'>
            <h4>Productos en existencias  </h4>
          </div> */}

          <div className="my-4">
            <MUIDataTable
              title={"Productos en existencias"}
              columns={columns}
              data={data_rows}
              className="rounded rounded-us "
              options={options}
            />
          </div>




          {/* <section className="data-section-us">
            <div className="container ">
              <div className="row">{tabledata()}</div>
            </div>
          </section> */}
        </div>
      </main>
    </>
  );
}

export default Stock;
