import React, { useEffect, useContext, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from "sweetalert2";
import { FaPen } from "react-icons/fa";

//api
import { ProductsFetchAPI } from '../../../../api/Products'
import { CategoriesFetchAPI } from '../../../../api/Categories'

function ModalProductoEditar({ id, setLoad }) {
    let dataStorage = JSON.parse(localStorage.getItem("userData"));
    let token = localStorage.getItem('ushopper-access-token');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [productbyid, setProductbyid] = useState({ producto: [] });
    const [categories, setCategories] = useState({ infoCategorie: [] });


    const getproduct = async () => {
        try {
            const response = await ProductsFetchAPI.getProductsById(id, token);
            setProductbyid({
                producto: [response.data.product]
            })
        } catch (error) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.response.data.message,
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    const handleShow = () => {
        setShow(true);
        getproduct();
    }

    //consulta las categorias
    const getcategories = async () => {
        let business_phone = dataStorage.Business.phone
        try {
            const responsecategories = await CategoriesFetchAPI.getCategoriesbyBuisness(business_phone, token)
            setCategories({
                infoCategorie: responsecategories.data.business_categories.categories
            })
        } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: error.response.data.message,
                showConfirmButton: false,
                timer: 1500,
            });
        }

    }

    useEffect(() => {
        (async () => {
            try {
                await getcategories()
            } catch (error) {
                console.log(error)
            }
        })()
    }, [])


    const submit = async (event) => {
        event.preventDefault()
        const form = new FormData(event.target)
        Object.fromEntries(form.entries())

        try {
            const responseupdate = await ProductsFetchAPI.updateProduct(form, id, token)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: responseupdate.data.message,
                showConfirmButton: false,
                timer: 1500
            })

        } catch (error) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.response.data.message,
                showConfirmButton: false,
                timer: 1500
            })
        }
        //We reload the father component 
        setLoad(1)
        //We close the modal
        setShow(false);

    }

    const infocategoriesSelect = () => {
        let getdata = categories.infoCategorie;
        let option = getdata.map((data) => (
            <option value={data.name}>{data.name}</option>
        ))
        return (
            <>
                {option}
            </>
        )
    }

    const formdata = () => {

        let getdata = productbyid.producto
        let row = getdata.map((data) => (
            <Form className="needs-validation" onSubmit={submit} encType='multipart/form-data'>
                <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={data.name}
                        defaultValue={data.name}
                        name='name'
                        required
                        autoFocus
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="price">
                    <Form.Label>Precio</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={data.price}
                        defaultValue={data.price}
                        name='price'
                        required

                    />
                </Form.Group>


                <Form.Group className="mb-3" controlId="">
                    <Form.Label>Categoria</Form.Label>
                    <Form.Select aria-label="" name="categorie_name">
                        <option>{data.categorie.name}</option>
                        {infocategoriesSelect()}
                    </Form.Select>
                </Form.Group>

                <Form.Label>Tipo</Form.Label>
                {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                            inline
                            label="Producto"
                            value="Producto"
                            name="typeItem"
                            type={type}
                            id={`inline-${type}-1`}
                        />
                        <Form.Check
                            inline
                            label="Servicio"
                            value="Servicio"
                            name="typeItem"
                            type={type}
                            id={`inline-${type}-2`}
                        />
                    </div>
                ))}



                <Form.Group className="mb-3" controlId="clientId">
                    <Form.Label>Impuesto</Form.Label>
                    <Form.Select
                        aria-label=""
                        name='tax'
                    >
                        <option value={data.tax.dTasaITBMSName}>{data.tax.dTasaITBMSName}</option>
                        <option value="0%">0%</option>
                        <option value="7%">7%</option>
                        <option value="10%">10%</option>
                        <option value="15%">15%</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="clientId">
                    <Form.Label></Form.Label>
                    <Form.Control
                        type="file"
                        placeholder="Imagen"
                        name='files'
                    />
                </Form.Group>


                <div className="mt-3 d-flex justify-content-end">
                    <Button
                        size="sm"
                        variant="success"
                        type="submit"
                    >
                        Guardar cambios
                    </Button>
                </div>
            </Form>
        ));
        return (
            <>
                {row}
            </>
        )
    }

    return (
        <>

            {['top'].map((placement) => (
                <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                        <Tooltip id={`tooltip-${placement}`}>
                            Editar producto
                        </Tooltip>
                    }
                >
                    <button
                        className=" btn btn-secondary-us "
                        onClick={handleShow}
                    >
                        <FaPen className="text-secondary-us " />
                    </button>
                </OverlayTrigger>
            ))}


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edición de producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {formdata()}
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ModalProductoEditar