import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
//Imgs
import card_user from "./img/card/1.png";
import { RiVipDiamondFill } from "react-icons/ri";
import { BsShieldFillCheck } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { UsersFetchAPI } from "../../../api/Users";//api
import { BusinessFetchAPI } from "../../../api/Business";

import Swal from "sweetalert2";
function ProfileCard(props) {
  const [infoUser, setInfoUser] = useState({
    info: "",
  });

  const [businesInfo, setBusinessInfo] = useState({
    name: "",
    credits: "",
  })

  let datastorage = JSON.parse(localStorage.getItem("userData"));
  let userId = datastorage.infoUser.id;
  const getuserbyid = async () => {
    try {
      const response = await UsersFetchAPI.getuserbyid(userId);
      setInfoUser({ info: response.data.Userbyid });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const getBusinessById = async () => {
    try {
      let token = localStorage.getItem('ushopper-access-token')
      let businessId = datastorage.Business._id
      const response = await BusinessFetchAPI.Businessbyid(businessId, token);
      console.log(response)
      setBusinessInfo({
        name: response.data.Buisnessbyid.name,
        credits: response.data.Buisnessbyid.credits,
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error.data.message !== undefined ? error.data.message : error,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  useEffect(() => {
    (async () => {
      try {
        getuserbyid();
        if (datastorage.infoUser.userType !== 0) {
          getBusinessById()
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);


  return (
    <>
      <div className="container head-us mt-4">
        <div className="d-flex justify-content-end ">
          <span class="badge bg-light text-dark me-2"> Créditos:{businesInfo.credits}</span>
        </div>
        <div className="">
          <h2 className="head-title-us text-white fw-bold"> {props.title} </h2>
        </div>
      </div>
    </>
  );
}

export default ProfileCard;
