import React, { useEffect, useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { UsersFetchAPI } from "../../../../api/Users"
import { FaPen } from "react-icons/fa";
function EditProfile() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [infouser, setInfouser] = useState({
        infouser: []
    })

    let datastorage = JSON.parse(localStorage.getItem('userData'));
    let user_id = datastorage.infoUser.id
    let user_phone = datastorage.infoUser.phone

    const infoprofile = async () => {
        try {
            const response = await UsersFetchAPI.getuserbyid(user_id);
            setInfouser({
                infouser: [response.data.Userbyid]
            })
        } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: error.data.message,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }

    useEffect(() => {
        (async () => {
            try {
                await infoprofile();
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])


    const submit = async (event) => {
        event.preventDefault()
        const form = new FormData(event.target)
        let updateuser = Object.fromEntries(form.entries());

        try {
            const response = await UsersFetchAPI.updateuser(updateuser, user_phone)
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
            });
            setInterval(refreshPage(), 1500);
        } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: error.data.message,
                showConfirmButton: false,
                timer: 1500,
            });
        }

        function refreshPage() {
            window.location.reload(false);
        }
    }

    const infoform = () => {
        let getdata = infouser.infouser;
        let info = getdata.map((data) => (
            <form className="needs-validation" onSubmit={submit} encType='multipart/form-data'>
                <div className="row mb-3">
                    <label htmlFor="name" className="col-md-4 col-lg-3 col-form-label">Nombre</label>
                    <div className="col-md-8 col-lg-9">
                        <input placeholder={data.name} name="name" type="text" className="form-control" id="name" />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="lastName" className="col-md-4 col-lg-3 col-form-label">Apellido</label>
                    <div className="col-md-8 col-lg-9">
                        <input placeholder={data.lastName} name="lastName" type="text" className="form-control" id="lastName" />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                    <div className="col-md-8 col-lg-9">
                        <input placeholder={data.email} name="email" type="text" className="form-control" id="email" />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="userId" className="col-md-4 col-lg-3 col-form-label">Cedula / Pasaporte</label>
                    <div className="col-md-8 col-lg-9">
                        <input placeholder={data.userId} name="userId" type="text" className="form-control" id="userId" />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="phone" className="col-md-4 col-lg-3 col-form-label">Celular</label>
                    <div className="col-md-8 col-lg-9">
                        <input placeholder={data.phone} name="phone" type="text" className="form-control" id="phone" />
                    </div>
                </div>
                <div className="text-center d-flex justify-content-end">
                    <button type="submit" className="btn btn-sm button-us">Guardar</button>
                </div>
            </form>

        ));

        return (
            <>
                {info}
            </>
        )
    }

    return (
        <>
            <button className='btn btn-light text-secondary-us border-sp' onClick={handleShow}>
                <span className='font-us label-btn'>
                    <FaPen className='icon-m' />
                    Editar Datos
                </span>
            </button>
            <Modal show={show} onHide={handleClose}>                
                <div class="header-modal-sp">
                    <div class="btn-close-modal-sm d-flex justify-content-end">
                        <button type="button" class="btn-close m-1" title=" Cerrar " onClick={handleClose} > </button>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <h4 class="title-modal"> Editar mis datos </h4>
                    </div>
                </div> 
                <Modal.Body>
                    {infoform()}
                </Modal.Body>  
            </Modal>
        </>
    )
}

export default EditProfile