import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class BusinessFetchAPI {

    static async Businessbycollaborator(idcollaborator) {
        const res = await server(`/businesscollaborator/${idcollaborator}`, {
            method: "get",
        });
        return res;
    }

    //Function to get business by phone
    static async getBusinessByPhone(business_phone) {
        const res = await server(`/business/${business_phone}`, {
            method: 'get'
        });
        return res;
    }

    static async Businessbyid(_id, token) {
        const res = await server(`/businessbyid/${_id}`, {
            method: "get",
            headers: {
                'ushopper-access-token': token
            }
        });
        return res;
    }


    static async Updatebusiness(_id, data) {
        const res = await server(`/business/${_id}`, {
            method: "put",
            data: data
        });
        return res;
    }

    static async getBusinessCredentialsToAprove() {
        const res = await server(`/credentials/business`, {
            method: 'get',
        });
        return res;
    }

    static async updateBusinessFECredentials(is_aproved, business_id) {
        const res = await server(`/credentials/business`, {
            method: 'put',
            data: {
                is_aproved, business_id
            }
        });
        return res;
    }


    static async getBusinessByCredentialsType(credentials_type, token) {
        const res = await server(`/credentials/${credentials_type}/business`, {
            method: 'get',
            headers: {
                'ushopper-access-token': token
            }
        });
        return res;
    }

    static async addBusinessCredits(data, token) {
        const res = await server(`/dgi/credits/add/business`, {
            method: 'post',
            data: data,
            headers: {
                'ushopper-access-token': token
            }
        });
        return res;
    }
    static async shareBusinessCredits(data, token) {
        const res = await server(`/dgi/share/credits`, {
            method: 'post',
            data: data,
            headers: {
                'ushopper-access-token': token
            }
        });
        return res;
    }
}