import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': "application/json"}, 
});

export class CompanyFetchAPI { /*Registrar empresa*/

    static async newCompany (data){
        const res = await server ("/business", {
            method: "post",
            data: data,
        });
        return res;
    }

    static async getCompanyByBusiness (business_phone){/*Obtener empresas*/
        const res = await server (`/business/${business_phone}`, {
            method: "GET",
        });
        return res;
    }

    static async updateCompany (data, business_id){ /*Actualizar empresa*/
        const res = await server(`/business/${business_id}`, {
            method: 'PUT', 
            data: data
        });
        return res;
    }

}
