import React, { useState, useEffect } from "react";
import Header from '../Navbar/Header';
import Footer from "../Footer/Footer";
import InfoProfile from "./InfoProfile/InfoUser";
import EditProfile from "./EditProfile/EditProfile";
import InfoCompany from "./InfoProfile/InfoCompany";
import ButtonExit from "../Buttons/ButtonExit";
//Components Boots
import Swal from "sweetalert2";
//Icons 
import { FaCity, FaUserAstronaut } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
//api
import { UsersFetchAPI } from "../../../api/Users";
import { RenderIf } from "../../../utils/RenderIf";

//css
import './Profile.css';

export default function Profile() {
  const [option, setOption] = useState({
    option: "Principal",
  });

  const showCards = (option) => {
    setOption({
      option: option,
    });
  };

  const name_label = "Menú Principal";
  const [infoUser, setInfoUser] = useState({
    info: "",
  });

  let datastorage = JSON.parse(localStorage.getItem("userData"));
  let userId = datastorage.infoUser.id;

  const getuserbyid = async () => {
    try {
      const response = await UsersFetchAPI.getuserbyid(userId);
      setInfoUser({ info: response.data.Userbyid });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getuserbyid();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  //const urliImg = infoUser.profilePicture
  const nameUser = infoUser.info.name;
  const lastName = infoUser.info.lastName;
  const email = infoUser.info.email
  let urlUser =
    "https://images.unsplash.com/photo-1503220317375-aaad61436b1b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80";

  if (infoUser.info.profilePicture === "") {
    urlUser =
      "https://images.unsplash.com/photo-1503220317375-aaad61436b1b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80";
  } else {
    urlUser = infoUser.info.profilePicture;
  }

  return (
    <>
      <main>
        <Header></Header>
        <div className="container head-content "> 
        <div className='d-flex justify-content-start mt-2'>
            <ButtonExit data="Menú Principal  " link='/dashboard' />
          </div>
            <div class="container db-social">         
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-xl-11">
                  <div class="widget head-profile has-shadow">
                    <div class="widget-body pb-0">
                      <div class="row d-flex align-items-center justify-content-center">
                        {/* <div class="col-xl-4 col-md-4 d-flex justify-content-lg-start justify-content-md-start justify-content-center">
                        </div> */}
                        <div class="col-xl-4 col-md-4 d-flex justify-content-center">
                          <div class="image-default">
                            {/* variable correct src={urlUser} */}
                            <img class="bd-placeholder-img img-profile-use rounded-circle" src={urlUser} alt="..." />
                          </div>
                          <div class="infos">
                            <h2>{nameUser} {lastName}</h2>
                            <div class="location"> <MdEmail /> {email} </div>
                          </div>
                        </div>
                        {/* <div class="col-xl-4 col-md-4 d-flex justify-content-lg-end justify-content-md-end justify-content-center">
                        </div> */}

                        <div className=" pills-option-us d-flex justify-content-center">
                          <button className="pills-us " onClick={() => showCards("Principal")}><FaUserAstronaut className="icon-m" /> Datos de Usuario </button>
                          <button className="pills-us " onClick={() => showCards("Empresa")} ><FaCity className="icon-m" /> Datos de Empresa </button>
                        </div>

                        <RenderIf isTrue={option.option === "Principal"}>
                          <InfoProfile></InfoProfile>
                        </RenderIf>

                        <RenderIf isTrue={option.option === "Empresa"}>
                          <InfoCompany></InfoCompany>
                        </RenderIf>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </main>  
      <Footer></Footer>

    </>
  );
}
