import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Swal from "sweetalert2";
import logo from "./us.png";
import { FaUserAl } from "react-icons/fa";

//api
import { ClientFetchAPI } from "../../../api/Clients";
function ListClients() {
  const [clientsdata, setclientsdata] = useState({
    clients: [],
    isLoaded: false,
  });

  let datastorage = JSON.parse(localStorage.getItem("userData"));

  const getclients = async () => {
    //declaramos y asignamos el celular del negocio
    let business_phone = datastorage.Business.phone;
    try {
      const response = await ClientFetchAPI.getClientsByBusiness(
        business_phone
      );
      setclientsdata({
        clients: response.data.business_clients.clients,
        isLoaded: true,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getclients();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const list_data_client = () => {
    let getdata = clientsdata.clients;
    // console.log(getdata);
    let row = getdata.map((data) => (
        <div>
            <input
            class="list-group-item-check"
            type="radio"
            name="listClientGroup"
            id={data._id}
            value=""          
            />
            <label class="list-group-item py-3" for={data._id}>
            {data.name} {data.lastName}     
            <span class="d-block small opacity-50 mt-1">
                Correo: {data.email}, 
                &nbsp;Teléfono: {data.phone}, 
                &nbsp;ID: {!data.clientId ? ("No registrado") : (data.clientId)}, 
                &nbsp;Ruc: {!data.ruc ? ("No registrado") : (data.ruc)} ,
                &nbsp;DV: {!data.dv ? ("No registrado") : (data.dv)}
            </span>
            </label>
        </div>
    ));
    return <>{!clientsdata.isLoaded ? <td>Cargando</td> : <>{row}</>}</>;
  };

  return (
    <>
    <Container>
        <article className="container-cards">
        <section className="justify-content-md-center  mt-1">
            <div className="d-flex justify-content-center mb-5">
                <img src={logo} alt="" width="150" height="150" />
            </div>
            <h3 className="text-center text-ushopper"> Lista de Clientes </h3>
            <Form>
                <Row className="align-items-center justify-content-center ">
                    <Col sm={4} xs={8}  className="my-1">
                    <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                        Name
                    </Form.Label>
                    <Form.Control id="inlineFormInputName" placeholder="Buscar cliente" />
                    </Col>
                    <Col xs="auto" className="my-1">
                        <button className="btn btn-success" type="submit">Buscar</button>
                    </Col>
                </Row>
            </Form>
            <div class="list-group list-group-checkable">
                {list_data_client()}
            </div>
        
        </section>
        </article>
        <a href="#" class="btn-flotante">          
        Cancelar
        </a>
    </Container>
    </>
  );
}

export default ListClients;
