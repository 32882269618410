import React from "react";
import {Link} from "react-router-dom";
import Logo_Main from  "./img/ushopper_green.png";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import "./navbar.css";
function NavBar() {
  return (
    <>   
      {/* <nav
        className="navbar navbar-expand-lg navbar-light py-2 shadow-sm"
        id="mainNav"
      >
        <div className="container px-1">
          <a className="navbar-title " href="/">
           <img src={Logo_Main} className=" img-logo" alt="" />
          </a>
          <button
            className="navbar-toggler text-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link  nav-link-ushopper" href="#about">
                  
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link  nav-link-ushopper" to="/team">
                  Equipo 
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}

<Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="shadow-sm">
      <Container>
        <Navbar.Brand href="/">          
          <img src={Logo_Main} className=" img-logo" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <Link  to="/" className="nav-link  nav-link-ushopper">
                Inicio 
          </Link> 
          <Link  to="/team" className="nav-link  nav-link-ushopper">
                Equipo 
          </Link> 

            {/* <Link  to="/contact" className="nav-link  nav-link-ushopper">
                Contacto
            </Link>   */}
          </Nav>
          <Nav>
            <Nav.Link>
              <Link  to="/sign-in" className=" btn rounded-pill text-white color-bg-ushopper">
                Iniciar sesión
              </Link> 
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default NavBar;
