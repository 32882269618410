import React from 'react'

export default function Footer() {
    return (
        <>
            <footer>
                <div className="my-2 mt-4 d-flex justify-content-between container">
                    <p className="text-center text-muted text-light opacity-50">© Ushopper</p>
                    <p className='text-center text-muted text-light opacity-50'>Powered by Smart Retail Group</p>
                </div>
            </footer>
        </>
    )
}
