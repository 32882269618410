import React from 'react'
import { View, StyleSheet, Text, Font } from '@react-pdf/renderer';
import FontsRoboto from './Fonts/Roboto-Bold.ttf'
import Content from './Content';
import FullValue from './FullValue';
import ItbmsValues from './ItbmsValues';
import TimePayment from './TimePayment';
//Styles 
Font.register({
   family: 'Roboto',
   fonts: [
      {
         src: FontsRoboto,
         fontWeight: 'bold',
      },

   ],
});

const styles = StyleSheet.create({
   tableContainer: {
      flexDirection: 'row',
      marginTop: 24,

   },

   tableTotal: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 5,


   },

   textNo: {
      backgroundColor: '#F1F1F1',
      width: 100,
      fontSize: 9,
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: 5,
      borderColor: '#00000',
      border: 0,
      fontFamily: 'Roboto',



   },

   textNormal: {
      backgroundColor: '#F1F1F1',
      width: 200,
      fontSize: 10,
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: 4,
      fontWeight: 'bold',
      fontFamily: 'Roboto',


   },

   textDescription: {
      width: 700,
      fontSize: 10,
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: 4,
      fontFamily: 'Roboto',
      backgroundColor: '#F1F1F1',
      borderColor: '#000000',
      border: 0,

   },
   alignText: {
      flexDirection: 'row',
   },
   textTotal: {
      fontSize: 10,
      fontFamily: 'Roboto',
      textAlign: 'right',
   },
   text: {
      fontSize: 9,
      textAlign: 'right',
      borderColor: '#F1F1F1',
      borderWidth: 1,
      width: 50,
   },


   valueSection: {
      flexDirection: 'row',
      justifyContent: 'space-between'
   }
});

function Table({ products, totalCashReceived, invoiceTotal, invoiceTotalItbms, paymentMethod, invoiceSubtotal, totalTaxes }) {
   let return_cash = totalCashReceived - invoiceTotal
   return (
      <>
         {/* Header de la tabla  */}
         <View style={styles.tableContainer}>
            <Text style={styles.textNo} >No.</Text>
            <Text style={styles.textDescription}>Descripción</Text>
            <Text style={styles.textNormal}>Cantidad </Text>
            <Text style={styles.textNormal}> &nbsp;  Precio &nbsp;  Unitario</Text>
            <Text style={styles.textNormal}>Descuento Unitario</Text>
            <Text style={styles.textNormal}>Monto</Text>
            <Text style={styles.textNormal}>ITBMS</Text>
            <Text style={styles.textNormal}>Valor Item</Text>
         </View>

         {/* Content Data Rows 
         Mediante un props puedes enviarle data y asi replicarla con uno solamente 
         */}

         <Content products={products} />

         {/* Values  */}
         <View style={styles.valueSection}>
            <View>
               {/*  Desglose ITBMS */}
               <ItbmsValues totalTaxes={totalTaxes} total={ typeof invoiceTotalItbms === 'string' ? parseFloat(invoiceTotalItbms).toFixed(2) : invoiceTotalItbms.toFixed(2)} />
               {/* Información de Pago a Plazo */}
               {/*  <TimePayment /> */}
            </View>

            <View>
               {/* Content Total  */}
               <FullValue title="Valor Total" value={typeof invoiceTotal === 'string' ? parseFloat(invoiceTotal).toFixed(2) : invoiceTotal.toFixed(2)} />
               <FullValue title="Total Neto" value={typeof invoiceSubtotal === 'string' ? parseFloat(invoiceSubtotal).toFixed(2) : invoiceSubtotal.toFixed(2)} />
               <FullValue title="Total Impuesto" value={typeof invoiceTotalItbms === 'string' ? parseFloat(invoiceTotalItbms).toFixed(2) : invoiceTotalItbms.toFixed(2)} />
               <FullValue title="Total " value={typeof invoiceTotal === 'string' ? parseFloat(invoiceTotal).toFixed(2) : invoiceTotal.toFixed(2)} />
               {/* Content Total payments forms   */}
               <View style={styles.container}>
                  <Text style={styles.textTotal}>Forma de Pago</Text>
               </View>
               <FullValue title={paymentMethod} value={typeof invoiceTotal === 'string' ? parseFloat(invoiceTotal).toFixed(2) : invoiceTotal.toFixed(2)} />
               <FullValue title="TOTAL PAGADO " value={typeof totalCashReceived === 'string' ? parseFloat(totalCashReceived).toFixed(2) : totalCashReceived.toFixed(2)} />
               <FullValue title="Vuelto" value={  typeof return_cash === 'string' ? parseFloat(return_cash).toFixed(2) : return_cash.toFixed(2)} />
            </View>

         </View>
      </>
   )
}

export default Table