import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class dCodCPBScmpFetchAPI { 

    static async getPanamanianCodes(token) {
        const res = await server("/dCodCPBScmp", {
            method: "get",
            headers: {
                'ushopper-access-token': token
            }
        });
        return res;
    }
}
