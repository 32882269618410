import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Usercontext from "../../context/UserContext";

//Components
import Footer from "../Layout/Footer";
import Swal from "sweetalert2";

//Api 
import { FetchAPI } from "../../api/Auth";
import { BusinessFetchAPI } from "../../api/Business"
import { TipoRucFetchAPI } from '../../api/dTipoRuc';
//Images
import imglogo from "./img/white-ushopper.png";


//Object of direct login 
let userLogged = {
    isLoggedByUrl: false
}

function Login() {

    const { setUserData } = useContext(Usercontext);
    const navigate = useNavigate();

    //Event to login with our login sistem 
    const submit = async (event) => {
        event.preventDefault();
        const form = new FormData(event.target);
        let newUser = Object.fromEntries(form.entries());
        let ruc_type = ''
        try {
            const response = await FetchAPI.login(newUser)
            let token = response.data.token;
            let infoUser = response.data.user;

            //solicito el negocio al que pertenece el usuario
            try {
                if (infoUser.userType !== 0) {
                    let infoBusiness = await BusinessFetchAPI.Businessbycollaborator(response.data.user.id);
                    console.log(infoBusiness)
                    try {
                        let ruc_response = await TipoRucFetchAPI.getTipoRucById(infoBusiness.data.listBusiness[0].ruc_type);
                        ruc_type = {
                            type: ruc_response.data.ruc_type.dTipoRucName,
                            value: ruc_response.data.ruc_type.dTipoRucValue
                        }
                    } catch (err) {
                        ruc_type = ''
                    }

                    let Business = {
                        "_id": infoBusiness.data.listBusiness[0]._id,
                        "phone": infoBusiness.data.listBusiness[0].phone,
                        "name": infoBusiness.data.listBusiness[0].name,
                        "credits": infoBusiness.data.listBusiness[0].credits,
                        "ruc": infoBusiness.data.listBusiness[0].ruc,
                        "dv": infoBusiness.data.listBusiness[0].dv,
                        "fe_credentials": infoBusiness.data.listBusiness[0].fe_credentials,
                        "direction": infoBusiness.data.listBusiness[0].direction,
                        "email": infoBusiness.data.listBusiness[0].email,
                        "business_ruc_type": ruc_type
                    }
                    setUserData({ infoUser, token, Business, isLoggedByUrl: userLogged.isLoggedByUrl });
                    localStorage.setItem('userData', JSON.stringify({ infoUser, token, Business }));
                } else {
                    setUserData({ infoUser, token, isLoggedByUrl: userLogged.isLoggedByUrl })
                    localStorage.setItem('userData', JSON.stringify({ infoUser, token }));
                }
                Swal.fire({
                    icon: "success",
                    title: response.data.message,
                    text: "Bienvenido",
                    showConfirmButton: false,
                    timer: 1500,
                });
                localStorage.setItem('ushopper-access-token', token);
                localStorage.setItem('isLoggedByUrl', userLogged.isLoggedByUrl);

                //We validate the user type (if is 0 is admin if is 1 is user)
                
                navigate("/dashboard");
            } catch (error) {

                console.log(error)
                setUserData({ infoUser, token, isLoggedByUrl: userLogged.isLoggedByUrl })

                //We validate the user type (if is 0 is admin if is 1 is user)
                if (infoUser.userType === 0) {
                    navigate('/dashboard')
                } else {
                    Swal.fire({
                        position: "top-end",
                        title: "Debes registrar tu empresa",
                        text: error.response.data.message,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    navigate("/signCompany-up");
                }
            }
        } catch (error) {
            console.log(error)
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: error,
                //text: error.response.data.message,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    };
    return (
        <>
            <div className="main-login bg-primary-ushopper pb-5 py-lg-5">
                <div className=" pb-5">
                    <div>
                        <div className="text-center ">
                            <main className="form-signin m-auto h-100">
                                <Link to="/">
                                    <img
                                        className="mb-4"
                                        src={imglogo}
                                        alt=""
                                        width="150"
                                        height="150"
                                    />

                                </Link>

                                <div className="p-5 border rounded-us bg-white">
                                    <form onSubmit={submit} noValidate>
                                        <h1 className="h4 mb-4 fw-normal title-login">
                                            Ingrese a su cuenta
                                        </h1>

                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control rounded-top"
                                                name="email"
                                                id="floatingInput"
                                                placeholder="name@example.com"
                                                required
                                                autoFocus
                                            />
                                            <label htmlFor="floatingInput">Numero de telefono</label>
                                        </div>
                                        <div className="form-floating">
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                id="floatingPassword"
                                                placeholder="Contraseña"
                                                required
                                            />
                                            <label htmlFor="floatingPassword">Contraseña</label>
                                        </div>

                                        <button className="w-100  button-us" type="submit">
                                            Iniciar sesión
                                        </button>

                                        <div className="mt-3 links">

                                            ¿Nuevo en
                                            <span className="text-primary-us"> Ushopper </span>?
                                            <div className="link-login">
                                                <Link to="/sign-up" className="link">
                                                    Crear una cuenta
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </div>


        </>
    );
}

export default Login;