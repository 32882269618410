import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class DgiFetchAPI {

    //Function to check if a user is a DGI user
    static async isDgiUser(ruc, ruc_type, token) {
        const res = await server("/dgi/contribuyent", {ruc, ruc_type}, {
            method: "post",
            headers: {
                'ushopper-access-token': token
            }
        });
        return res;
    }
}