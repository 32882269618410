import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";

function ButtonExit(props) {
  return (
    <>
      <Link to={props.link} >
        <button className="btn btn-light btn-sm px-3">
          <MdOutlineArrowBackIos className="icon-m"/>
          <strong>{props.data}</strong>
        </button>
      </Link>
    </>
  )
}
export default ButtonExit;
