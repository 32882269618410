import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";

//Imgs
import Logo from "./white-ushopper.png";
import Avatar from "./user.png";

//api
import { UsersFetchAPI } from "../../../api/Users";
import { RenderIf } from "../../../utils/RenderIf";

export default function Nabvar(props) {
  const [infoUser, setInfoUser] = useState({
    info: "",
  });
  let datastorage = JSON.parse(localStorage.getItem("userData"));
  let userId = datastorage.infoUser.id;

  const getuserbyid = async () => {
    try {
      const response = await UsersFetchAPI.getuserbyid(userId);
      setInfoUser({ info: response.data.Userbyid });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getuserbyid();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  //const urliImg = infoUser.profilePicture
  const nameUser = infoUser.info.name;
  const lastNameUser = infoUser.info.lastName;
  // const urlUser = infoUser.info.profilePicture;

  const logout = async (e) => {
    //We remove the access token of the app and the data of the user
    localStorage.removeItem("ushopper-access-token");
    localStorage.removeItem("userData");
  };

  let urlUser = Avatar

  if (infoUser.info.profilePicture === "") {
    urlUser = Avatar
  } else {
    urlUser = infoUser.info.profilePicture;
  }

  return (
    <>
      <nav className="navbar">
        <div className="navbar-section">
          {/* <div className="logo-us">
            <img src={Logo} className="nabvar-logo-us" alt="Logo Ushopper Brand" />
          </div>
          <div class="dropdown">
            <img src={urlUser} alt="..." className="nabvar-avatar-us mt-1 rounded-circle" />
            <div class="dropdown-menu">   
              <Link to="/profile" className="links-nav-us" >Perfil</Link>
              <Link to="/login" onClick={logout} className="links-nav-us">Cerrar Sesión</Link>
            </div>
          </div> */}
          <div class="d-flex justify-content-between mt-3">
            <img src={Logo} className="nabvar-logo-us" alt="Logo Ushopper Brand" />
            <div class="ms-auto me-2 rounded-pill text-white d-flex align-items-center">{nameUser} {lastNameUser}</div>
            <div class="vr text-white"></div>
            <div class="dropdown ms-2 ">
              <img src={urlUser} alt="..." className="nabvar-avatar-us mt-1 rounded-circle" />
              <div class="dropdown-menu">
                <RenderIf isTrue={datastorage.infoUser.userType !== 0}>
                  <Link to="/profile" className="links-nav-us" >Perfil</Link>
                </RenderIf>
                <Link to="/login" onClick={logout} className="links-nav-us">Cerrar Sesión</Link>
              </div>
            </div>
          </div>
          <h3 className="text-center text-white">
            {props.title}
          </h3>
        </div>
      </nav>

    </>
  );
}
