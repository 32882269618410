import React, { useEffect, useState } from 'react'
//Components 
import Header from '../Navbar/Header';
import ButtonExit from "../Buttons/ButtonExit";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import { PDFViewer } from '@react-pdf/renderer';
import DocPdf from '../Pdf/DocPdf';
import Select from 'react-select'
//Icons
import logo from '../../assets/img/logo/logo-srg.png'
//Modules
import Swal from 'sweetalert2';
//API 
import { OrdersFetchAPI } from '../../../api/Orders';
import { InvoiceFetchAPI } from '../../../api/Invoice';
import { ProductsFetchAPI } from '../../../api/Products';
import LoadingAlert from '../../Alerts/LoadingAlert';

//Icons
import { FaTrashAlt } from 'react-icons/fa'

function Credit() {

   let datastorage = JSON.parse(localStorage.getItem("userData"));
   let business_phone = datastorage.Business.phone;
   let business_info = datastorage.Business;
   let token = localStorage.getItem('ushopper-access-token');
   let business_ruc = datastorage.Business.ruc

   //Form Fields
   const [show, setShow] = useState(false);
   const [order_code, setOrderCode] = useState("")
   const [businessProducts, setBusinessProducts] = useState([]);
   let [invoiceTotal, setInvoiceTotal] = useState(0);
   let [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
   let [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
   let [invoiceDiscountTotal, setInvoiceDiscountTotal] = useState(0)
   let [productSelected, setProduct] = useState('null');
   let [eventHandler, setEventHandler] = useState(0)
   let [transactionClientPayed, setTransactionClientPayed] = useState(0);
   let [transactionClientPaymentMethod, setTransactionClientPaymentMethod] = useState('');


   const [products, setProducts] = useState([])
   //Total taxes 
   let [totalTaxes, setTotalTaxes] = useState([
      { total_base: 0, tax: "0%", total_tax: 0 },
      { total_base: 0, tax: "7%", total_tax: 0 },
      { total_base: 0, tax: "10%", total_tax: 0 },
      { total_base: 0, tax: "15%", total_tax: 0 }
   ]);

   //Transmitter info
   const [emmiterData, setEmmiterData] = useState({
      "ruc": business_info.fe_credentials.pac.user,
      "dv": business_info.dv,
      "name": business_info.name,
      "ruc_type": business_info.business_ruc_type.type,
      "direction": business_info.direction
   });

   //Receptor info 
   const [receptorData, setReceptorData] = useState({
      name: "",
      ruc: "",
      direction: "",
      dv: "",
      ruc_type: "",
      type: ""
   });
   //invoice dgi 
   let [cufe, setCufe] = useState();
   let [authDGI, setAuthDGI] = useState();
   let [invoiceDate, setInvoiceDate] = useState()
   let [invoiceQR, setInvoiceQR] = useState()

   const [transaction, setTransaction] = useState([]);
   const [selectedTransaction, setselectedTransaction] = useState({});

   //For the modal 
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true)

   //Function to charge the transaction selected and the info of it 
   const getTransactionByCode = async (order_code) => {
      if (order_code !== '' && order_code !== null && order_code !== 'null') {

         try {
            const response = await OrdersFetchAPI.getBusinessOrderByCode(business_ruc, order_code, token);
            setTransaction(response.data.order);

            console.log(response);
            let transactionselected = response.data.order
            setselectedTransaction(transactionselected)
            //We set the products of the transaction 
            setProducts(transactionselected.products)
            //We set the invoice totals
            setInvoiceTotal(transactionselected.total_order)
            setInvoiceTotalItbms(transactionselected.total_order_itbms)
            setInvoiceSubtotal(transactionselected.total_order - transactionselected.total_order_itbms)
            setInvoiceDiscountTotal(transactionselected.total_order_discount)
            //We set some client info 
            setTransactionClientPayed(transactionselected.total_cash_received)
            setTransactionClientPaymentMethod(transactionselected.payment_method)
            //After save the info we set the receptor info on the state 
            setReceptorData({
               name: transactionselected.receptor_name,
               ruc: transactionselected.receptor_ruc,
               direction: transactionselected.receptor_direction,
               dv: transactionselected.receptor_dv === undefined ? '' : transactionselected.receptor_dv,
               ruc_type: transactionselected.receptor_type_ruc === undefined ? '' : transactionselected.receptor_type_ruc,
               type: transactionselected.receptor_type,
               receptor_country_code: transactionselected.receptor_country_code === undefined ? 'PA' : transactionselected.receptor_country_code
            });



         } catch (error) {
            console.log(error)
            Swal.fire({
               position: "top-end",
               icon: "info",
               title: "No hay transacciones resgistradas",
               showConfirmButton: false,
               timer: 1500,
            });
         }
      } else {
         setselectedTransaction({})
         Swal.fire({
            icon: "error",
            title: "Campos vacios",
            showConfirmButton: false,
            timer: 1500,
         });
      }
      //We reload info 
      setEventHandler(1)
   };
   //  const handleChangeNotes = (option) => {
   //     setSelectedOption(option);
   //     getClientById(option.value);
   //  };


   //Function to generate the credit note 
   const generateCreditNote = async () => {
      //We show the loading alert
      LoadingAlert('Generando nota de credito', 'Por favor espere')
      try {
         //We construc the json to send 
         let json_credit_note = {
            "business_phone": business_info.phone,
            "business_ruc": business_info.ruc,
            "operation_type": "Salida o venta",
            "sell_type": 'Venta de Giro del negocio',
            "document_type": "Nota de Crédito Referente a una o Varias FE",
            "invoice_type": "Nota de credito",
            "cufe": selectedTransaction.cufe,
            "transmitter_ruc": business_info.ruc,
            "transmitter_dv": business_info.dv,
            "transmitter_name": business_info.name,
            "transmitter_ruc_type": business_info.business_ruc_type.type,
            "branch_code": "0000",
            "branch_direction": business_info.direction,
            "transmitter_phone": business_info.phone,
            "transmitter_email": business_info.email,
            "receptor_type": selectedTransaction.receptor_type,
            "receptor_name": selectedTransaction.receptor_name,
            "receptor_direction": selectedTransaction.receptor_direction,
            "total_cash_received": selectedTransaction.total_cash_received,
            "payment_type": selectedTransaction.payment_type,
            "payment_method": selectedTransaction.payment_method,
            "items": products
         }

         //We check if the client is contribuyente or not 
         if (selectedTransaction.receptor_type_ruc && (selectedTransaction.receptor_type_ruc === 'Juridico' || selectedTransaction.receptor_type_ruc === 'Natural')) {
            //if is contribuyent we add the fields of ruc
            json_credit_note.receptor_type_ruc = selectedTransaction.receptor_type_ruc
            json_credit_note.receptor_ruc = selectedTransaction.receptor_ruc
            json_credit_note.receptor_dv = selectedTransaction.receptor_dv
            json_credit_note.receptor_dv = selectedTransaction.receptor_dv
            json_credit_note.receptor_country_code = selectedTransaction.receptor_country_code
         } else {
            json_credit_note.receptor_ruc = selectedTransaction.receptor_ruc
            json_credit_note.receptor_country_code = selectedTransaction.receptor_country_code

         }

         //We check the payment type of the client
         if (selectedTransaction.payment_type === 'credito') {
            let date = new Date(selectedTransaction.payment_dues[0].next_payment_date);
            json_credit_note.payment_dues = [{
               "next_payment_date": date.toISOString().split('T')[0] + "T11:56:40-05:00",
               "total_to_pay": selectedTransaction.payment_dues[0].total_to_pay
            }]
         }

         //Depending of the credentials type we send the transmitter info 
         if (business_info.fe_credentials.credentials_type === 'demo') {
            json_credit_note.transmitter_name = "FE generada en ambiente de pruebas - sin valor comercial ni fiscal"
            json_credit_note.transmitter_ruc = "844084-1-504061"
            json_credit_note.transmitter_dv = "00"
         }

         //We generate the electronic credit note 
         const credit_note_response = await InvoiceFetchAPI.generateCreditNote(json_credit_note);

         //After tje response of the credit note we save the info to the database 
         json_credit_note.cufe = credit_note_response.data.data.CUFE
         json_credit_note.Qr = credit_note_response.data.data.QR
         json_credit_note.code = credit_note_response.data.order_code
         json_credit_note.products = json_credit_note.items
         json_credit_note.auth_protocol = credit_note_response.data.data.AU
         try {
            //We register the transaction on the database 
            await OrdersFetchAPI.newOrder(json_credit_note);
            //Alert 
            Swal.fire({
               icon: "success",
               title: 'Nota de credito generada',
               //text: `${credit_note_response.data.data.QR}`
            });

            //We set the dgi info 
            setCufe(credit_note_response.data.data.CUFE)
            setAuthDGI(credit_note_response.data.data.AU)
            setInvoiceDate(credit_note_response.data.data.dFecProc)
            setInvoiceQR(credit_note_response.data.data.QR)
            //We show the modal info
            setShow(true)
            //We reload info 
            setEventHandler(1)
            //We clean the form 
            setselectedTransaction({})
         } catch (err) {
            //Alert 
            Swal.fire({
               icon: "error",
               title: 'Error',
               text: `${err}`
            });
         }

      } catch (err) {
         console.log(err)
      }
   }

   //Function to add a product 
   const addProduct = async (e) => {
      e.preventDefault();
      //If the switch is checked it means that the product exist so we get the product of the server 
      const product_selected = await ProductsFetchAPI.getProductsById(productSelected, token);

      //We calculate the total product itbms 
      let total_product_itbms = 1 * (product_selected.data.product.tax.value * (product_selected.data.product.price - 0))

      //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
      let subtotal_product = (1 * (product_selected.data.product.price - 0));

      //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
      let total_product = (1 * (product_selected.data.product.price - 0)) + total_product_itbms;

      //We get the itbms an we calculate the total itbms 
      invoiceTotalItbms = invoiceTotalItbms + (1 * ((product_selected.data.product.tax.value * (product_selected.data.product.price - 0))));
      setInvoiceTotalItbms(invoiceTotalItbms);

      //We calculate the subtotal 
      invoiceSubtotal = invoiceSubtotal + total_product;
      setInvoiceSubtotal(invoiceSubtotal);

      //We calculate the total of discounts 
      invoiceDiscountTotal = invoiceDiscountTotal + 0;
      setInvoiceDiscountTotal(invoiceDiscountTotal)

      //We calculate the total  of the transaction
      invoiceTotal = invoiceSubtotal + invoiceTotalItbms;
      setInvoiceTotal(invoiceTotal)

      let indexTax = totalTaxes.map(value => value['tax']).indexOf(product_selected.data.product.tax.dTasaITBMSName)

      //If the paymenthod exist we just affect the total of it 
      totalTaxes[indexTax].total_tax = totalTaxes[indexTax].total_tax + total_product_itbms;
      totalTaxes[indexTax].total_base = totalTaxes[indexTax].total_base + subtotal_product;

      setTotalTaxes(totalTaxes)

      //We add the products 
      setProducts([...products, {
         "name": product_selected.data.product.name,
         "code": product_selected.data.product.upc ? product_selected.data.product.upc : '0000',
         "cant": 1,
         "price": product_selected.data.product.price,
         "tax": product_selected.data.product.tax.dTasaITBMSName,
         "total_product_itbms": total_product_itbms,
         "total_discount": 0,
         "subtotal_product": subtotal_product,
         "total_product": total_product,
         "product": productSelected,
         "exist_product": true,
         "panamanianCode": product_selected.data.product.categorie.dCodCPBScmp.code
      }]);
   }

   //Function to render the table preview invoice table 
   const tableProduct = () => {
      let productData = products;
      let rows = productData.map((product, index) => (
         <tr key={index}>
            <td>{product.name}</td>
            <td>{product.code}</td>
            <td>

               <input
                  type='number'
                  defaultValue={product.cant}
                  className='form-control'
                  onChange={(e) => handleEditTableProduct(index, "cant", e.target.value)}

               /></td>
            <td>{product.price}</td>
            <td>

               <select className='form-select' aria-label="Select payment type"
                  onChange={(e) => handleEditTableProduct(index, "tax", e.target.value)}
                  value={product.tax}>

                  <option value="7%">7%</option>
                  <option value="0%">0%</option>
                  <option value="10%">10%</option>
                  <option value="15%">15%</option>
               </select>
            </td>
            <td>
               <input
                  type='number'
                  defaultValue={product.total_discount}
                  onChange={(e) => handleEditTableProduct(index, "total_discount", e.target.value)}
                  className='form-control'
               />
            </td>
            <td>{product.total_product}</td>
            <td>
               <button onClick={(e) => deleteProduct(index)} className="btn-danger-us btn text-secondary bg-btn-secundary ">
                  <FaTrashAlt />
               </button>
            </td>
         </tr>
      ))
      return (
         <>
            {rows}
         </>
      )
   }

   //Function to edit the table product 
   const handleEditTableProduct = (indexCell, cellName, cellValue) => {
      //We create a new array with the edit product
      const editProducts = products.map((product, index) => {
         let newProductCant = product.cant;
         let newProductDiscount = product.total_discount
         //We get the itbms and we calculate the total itbms
         let itmb_value = product.tax.split('%')[0] / 100;

         let total_product_itbms = 0;
         let subtotal_product = 0
         let total_product = 0
         //We check if we want to update the amount of the product
         if (cellName === 'cant') {
            newProductCant = cellValue
         }
         //We check if we want to update the tax of the product
         if (cellName === "tax") {
            itmb_value = cellValue.split('%')[0] / 100;
         }
         //We check if we want to update the total discount of the product
         if (cellName === "total_discount") {
            newProductDiscount = cellValue
         }
         //We found the product to edit we return it 
         if (index === indexCell) {
            //We calculate the total product itbms 
            total_product_itbms = newProductCant * (itmb_value * (product.price - newProductDiscount))
            //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
            subtotal_product = (newProductCant * (product.price - newProductDiscount));
            //We calculate the product total and add it to the product list json (for default the cant is 1 and the discount is 0)
            total_product = (newProductCant * (product.price - newProductDiscount)) + total_product_itbms;
            return {
               ...product,
               [cellName]: cellValue,
               "subtotal_product": subtotal_product,
               "total_product": total_product,
               "total_product_itbms": total_product_itbms,
               "total_discount": newProductDiscount
            };
         }
         //We return the product 
         return product;
      });
      //We set the products edited
      setProducts(editProducts);
      //We reload the component
      setEventHandler(1)
   };


   //Function to calculate the invoice totals 
   const calculateInvoiceTotals = (products) => {
      //For every new calculate we set the totals 
      let invoiceDiscountTotal = 0
      let invoiceSubtotal = 0
      let invoiceTotalItbms = 0
      let invoiceTotal = 0

      //Total taxes 
      let newTotalTaxes = [
         { total_base: 0, tax: "0%", total_tax: 0 },
         { total_base: 0, tax: "7%", total_tax: 0 },
         { total_base: 0, tax: "10%", total_tax: 0 },
         { total_base: 0, tax: "15%", total_tax: 0 }
      ]
      //if we dont we products we put the totals in 0 for default 
      if (products.length === 0) {
         //We calculate the totals of the invoice 
         setInvoiceDiscountTotal(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
      } else {
         //For every product we calculate 
         for (let i = 0; i < products.length; i++) {
            let product_info = products[i]
            //We get the itbms and we calculate the total itbms
            let itmb_value = product_info.tax.split('%')[0] / 100;
            //Unit discount
            let unitItemDiscount = product_info.total_discount;
            //We validate that the discount comes like an string 
            if (typeof unitItemDiscount === 'string') {
               unitItemDiscount = parseFloat(product_info.total_discount)
            }
            //Variables
            let total_product_price = 0;
            let total_product_itbms = 0;
            let product_total_discount = 0;
            //We set the total discount of the product (we calculate the total discount of the products because the product.total_discount is the unit discount)
            product_total_discount = unitItemDiscount * product_info.cant
            //We set the total of the product 
            total_product_price = (product_info.cant * (product_info.price - unitItemDiscount));
            //We set the itbms of the product 
            total_product_itbms = (product_info.cant * ((product_info.price - unitItemDiscount) * itmb_value));

            //We calculate the totals of the invoice 
            invoiceDiscountTotal = invoiceDiscountTotal + product_total_discount;
            invoiceSubtotal = invoiceSubtotal + total_product_price;
            invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
            invoiceTotal = invoiceTotal + total_product_price + total_product_itbms;

            let indexTax = newTotalTaxes.map(value => value['tax']).indexOf(product_info.tax)

            //If the paymenthod exist we just affect the total of it 
            newTotalTaxes[indexTax].total_tax = newTotalTaxes[indexTax].total_tax + total_product_itbms;
            newTotalTaxes[indexTax].total_base = newTotalTaxes[indexTax].total_base + total_product_price;
            //We set the totals
            setTotalTaxes(newTotalTaxes)
         }
         //We set the totals
         setInvoiceTotalItbms(invoiceTotalItbms);
         setInvoiceSubtotal(invoiceSubtotal);
         setInvoiceDiscountTotal(invoiceDiscountTotal)
         setInvoiceTotal(invoiceTotal)
      }
   }

   //Function to delete a product of the table preview 
   const deleteProduct = async (product_index) => {
      let product = products[product_index];

      if (product.exist_product === false) {
         //We get the itbms and we calculate the total itbms
         let itmb_value = product.tax.split('%')[0] / 100;
         let product_itbms = (product.cant * (itmb_value * (product.price - product.total_discount)));
         invoiceTotalItbms = invoiceTotalItbms - product_itbms
         //Subtotal
         invoiceSubtotal = invoiceSubtotal - product.total_product;
         //total 
         invoiceTotal = invoiceTotal - (product.total_product + product_itbms)
         //We calculate the total of discounts 
         invoiceDiscountTotal = invoiceDiscountTotal - parseFloat(product.total_discount);
      } else {
         //We get the itbms and we calculate the total itbms
         let itmb_value = product.tax.split('%')[0] / 100;
         let product_itbms = (product.cant * (itmb_value * (product.price - product.total_discount)));
         invoiceTotalItbms = invoiceTotalItbms - product_itbms
         //Subtotal
         invoiceSubtotal = invoiceSubtotal - product.total_product;
         //total 
         invoiceTotal = invoiceTotal - (product.total_product + product_itbms)
         //We calculate the total of discounts 
         invoiceDiscountTotal = invoiceDiscountTotal - parseFloat(product.total_discount);

      }
      //We set the totals
      setInvoiceSubtotal(invoiceSubtotal);
      setInvoiceTotal(invoiceTotal);
      setInvoiceTotalItbms(invoiceTotalItbms);
      setInvoiceDiscountTotal(invoiceDiscountTotal)

      //We delete the product 
      products.splice(product_index, 1);
      setProducts([...products]);
   }

   //Hook to load initial info 
   useEffect(() => {
      (async () => {
         try {
            //We get the business products 
            const products = await ProductsFetchAPI.getProductsByBusiness(business_info.phone, token);
            setBusinessProducts(products.data.business_products.products)
         } catch (err) {
            console.log(err);
         }
      })();
   }, []);

   //Hook to reload the products and re calculate the total invoices
   useEffect(() => {
      setProducts([...products]);
      calculateInvoiceTotals([...products])
      setEventHandler(0)
   }, [eventHandler])


   return (
      <>
         <main>
            <Header title='Nota de crédito ' ></Header>
            <div className="container head-content ">
               <div className='d-flex justify-content-start mt-2'>
                  <ButtonExit data="Menú Principal  " link='/dashboard' />
               </div>
               <div className='container mb-5'>
                  <div className='d-flex justify-content-center'>
                     <input type="text" className='form-control w-25' placeholder='Numero de factura'  onChange={(e) => (setOrderCode(e.target.value))}/>
                     <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                        <button type="button" onClick={e => getTransactionByCode(order_code)} className="btn btn-success px-5">Aceptar</button>
                     </div>
                  </div>


                  {selectedTransaction._id &&
                     <div>
                        <ul class="list-group">
                           <li class="list-group-item">{selectedTransaction.cufe}</li>
                        </ul>

                        <div className='invoice-us'>
                           <div>
                              <div className="invoice-us-dates p-4">
                                 <div className=" header-invoice-us d-flex  justify-content-between align-content-center mb-10 mb-lg-15">
                                    {/*----- LOgo----- */}
                                    <a href="#">
                                       <img alt="Logo" className="invoice-logo" src={logo} />
                                    </a>
                                    {/*------ CUFE -----*/}
                                    <div className="my-1 d-flex justify-content- gap-2">
                                       <div title="Clic para consultar" >
                                          <a href={!selectedTransaction.Qr ? '#' : selectedTransaction.Qr} className="btn btn-primary btn-sm" target="_blank" >Ver Factura Electrónica </a>
                                       </div>
                                       {/* <div title="Clic para consultar" >
                                          <button className="btn btn-success btn-sm"  >Descargar Factura</button>
                                       </div> */}
                                    </div>
                                 </div>
                                 {/* -----End CUFE----- */}
                                 {/*----- Numb Transaction------ */}
                                 <div className="invoice-us-n-transaction mt-4">
                                    <h5>
                                       Factura #{selectedTransaction.code}
                                    </h5>
                                 </div>
                                 {/*-----End Numb Transaction------ */}
                                 <div className='row gap-1'>
                                    {/*-----Client  Details ------ */}
                                    <div className='col-5'>
                                       <h6 className="my-2 pb-2 fw-bold text-decoration-underline text-muted">Datos del cliente:</h6>

                                       <p>
                                          <span className="">Cliente: </span>
                                          {!selectedTransaction.receptor_name ? <small className="text-muted text-center">No registrado</small> : selectedTransaction.receptor_name}
                                       </p>

                                       <p>Tipo de RUC: {!selectedTransaction.receptor_type_ruc ? <small className="text-muted text-center">No registrado</small> : selectedTransaction.receptor_type_ruc}</p>
                                       <p>RUC: {!selectedTransaction.receptor_ruc ? <small className="text-muted text-center">No registrado</small> : selectedTransaction.receptor_ruc}</p>
                                       <p>DV: {!selectedTransaction.receptor_dv ? <small className="text-muted text-center">No registrado</small> : selectedTransaction.receptor_dv}</p>
                                       <p>Tipo: {!selectedTransaction.receptor_type ? <small className="text-muted text-center">No registrado</small> : selectedTransaction.receptor_type}</p>
                                    </div>
                                    {/*-----End Client  Details------ */}
                                    {/*-----Transaction Details ------ */}
                                    <div className='col-6'>
                                       <h6 className="my-2 pb-2 fw-bold  text-decoration-underline text-muted" >Detalles de Transación:</h6>
                                       <p>
                                          Tipo de Transacción :{selectedTransaction.invoice_type}
                                       </p>
                                       <p>Código de Sucursa:{selectedTransaction.branch_code}</p>
                                       <p>Dirección de Sucursal :{selectedTransaction.branch_direction}</p>
                                       <p>Estado :{selectedTransaction.state}</p>
                                       <p>Fecha:{selectedTransaction.createdAt}</p>
                                    </div>
                                 </div>
                                 <div className='row'>
                                    <div className="col-6">
                                       <div className="p-2 mt-3">
                                          <label className="pb-2 fw-bold text-decoration-underline text-muted">
                                             Método de Pago:
                                          </label>
                                          <p className="transaction-text text-center" >
                                             {!selectedTransaction.payment_method ? <small className="text-muted text-center">No registrado</small> : selectedTransaction.payment_method}
                                          </p>
                                       </div>
                                    </div>
                                    <div className="col-6">
                                       <div className="p-2 mt-3">
                                          <label className="pb-2 fw-bold text-decoration-underline text-muted">
                                             Tipo de Método:
                                          </label>
                                          <p className="transaction-text text-center" >
                                             {!selectedTransaction.payment_type ? <small className="text-muted text-center">No registrado</small> : selectedTransaction.payment_type}
                                          </p>
                                       </div>
                                    </div>
                                 </div>

                                 {/**Product select  */}
                                 <Row className='align-items-center' id='div-existent-products'>
                                    <Form.Group className='mb-3'>
                                       <Form.Label>Seleccione su producto </Form.Label>
                                       <Form.Select aria-label="Select payment type" id="select_product" onChange={e => setProduct(e.target.value)} name="product_select">
                                          <option value="null">Seleccione...</option>
                                          {businessProducts.map(product => (
                                             <option key={product._id} value={product._id}>
                                                {product.name}
                                             </option>
                                          ))}
                                       </Form.Select>
                                    </Form.Group>
                                 </Row>

                                 {/* Button submit */}
                                 <Col md={2}>
                                    <Button type="button" onClick={addProduct} variant="success" className='mt-3'>Agregar</Button>
                                 </Col>

                                 {/*-----Items Details ------ */}
                                 <div className='invoice-product-us mt-4'>
                                    <div className="table-responsive">
                                       <table className="table table-hover table-borderless ">
                                          <thead className="bg-light" >
                                             <tr>
                                                <th>Producto</th>
                                                <th>Código</th>
                                                <th>Cantidad </th>
                                                <th>Precio</th>
                                                <th>Impuesto(itbms)</th>
                                                <th>Descuento unitario</th>
                                                <th>Total</th>
                                                <th>Acciones</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {tableProduct()}
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>

                                 {/*-----End Items Details ------ */}
                                 <div className='invoice-us-totals mt-4'>
                                    <div class="d-flex justify-content-end">
                                       <div class="mw-300px">
                                          <div class="d-flex justify-content-between mb-2">
                                             <div class="  fw-bold text-gray-600 fs-7"> Descuentos: </div>
                                             <div class=" fs-6 text-gray-800"> {invoiceDiscountTotal}</div>
                                          </div>

                                          <div class="d-flex justify-content-between mb-2">
                                             <div class="  fw-bold text-gray-600 fs-7">Itbms :</div>
                                             <div class="text-end fs-6 text-gray-800"> {invoiceTotalItbms} </div>
                                          </div>

                                          <div class="d-flex justify-content-between mb-2">
                                             <div class="  fw-bold text-gray-600 "> Subtotal: </div>
                                             <div class="fs-6"> {invoiceSubtotal} </div>
                                          </div>

                                          <div class="d-flex justify-content-between">
                                             <div class="  fw-bold text-gray-600 fs-7">Total: </div>
                                             <div class=" fs-6"> {invoiceTotal}</div>
                                          </div>

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="mt-4">
                           <div>
                              <button type="button" onClick={generateCreditNote} className='mt-3 btn btn-success'>Crear nota de crédito </button>
                           </div>
                        </div>

                     </div>
                  }
               </div >

            </div >
         </main >

         <Modal dialogClassName="modal-90w" size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>PDF de la factura </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <PDFViewer className=' pdf-viewer-sp '>
                  <DocPdf doc_type="credit_note" invoiceQR={invoiceQR} invoiceDate={invoiceDate} cufe={cufe} auth_protocol={authDGI} receptor={receptorData} emitter={emmiterData} products={products} invoiceSubtotal={invoiceSubtotal} totalTaxes={totalTaxes}
                     totalCashReceived={transactionClientPayed} invoiceTotal={invoiceTotal} invoiceTotalItbms={invoiceTotalItbms} paymentMethod={transactionClientPaymentMethod} />
               </PDFViewer>
            </Modal.Body>
         </Modal>

      </>
   )
}

export default Credit

