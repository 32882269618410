import React, { useEffect, useContext, useState } from "react";
import Swal from "sweetalert2";
import { BusinessFetchAPI } from "../../../../api/Business";
import { FetchAPI } from "../../../../api/Auth"; 

import { FaKey } from "react-icons/fa";
import CardInfo from "../../../../utils/CardInfo";
//Components
import ModalEditCompany from '../EditarCompany/EditModalCompany';
//Utls 
import { RenderIf } from '../../../../utils/RenderIf';


function InfoCompany() {

  const [company, setCompany] = useState({
    infocompany: [],
  });

  let datastorage = JSON.parse(localStorage.getItem("userData"));
  let company_id = datastorage.Business._id;

  //Function to get the company 
  const getcompany = async () => {
    try {
      const response = await BusinessFetchAPI.Businessbyid(company_id);
      setCompany({
        infocompany: [response.data.Buisnessbyid],
      });
      console.log(response);
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getcompany();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const info = () => {
    let getdata = company.infocompany;

    let infodata = getdata.map((data) => (
      <>
        <div key={data._id} className="row mt-2">
          <CardInfo col={"col-md-3"} title={"Nombre empresa "} data={data.name} />
          <CardInfo col={"col-md-3"} title={"  RUC "} data={data.ruc} />
          <CardInfo col={"col-md-3"} title={"  DV "} data={data.dv} />
          <CardInfo col={"col-md-3"} title={" Número "} data={data.phone} />
          <CardInfo col={"col-md-3"} title={" API Token access key "} data={data.business_access_token} />

        </div>

        <div className=" mt-5">
          <h5>Datos de mi facturacion electronica</h5>
          <RenderIf isTrue={data.fe_credentials.credentials_in_approval.state && data.fe_credentials.credentials_in_approval.state === 'in_progress'}>
            <dt className="col-md-4">Estado de solicitud de cambio de credenciales</dt>
            <dd className="col-sm-9">{data.fe_credentials.credentials_in_approval.state && data.fe_credentials.credentials_in_approval.state === 'in_progress' ? 'Cambio en progreso' : ''}</dd>
          </RenderIf>
          <div className="row mt-3 mb-5">
            <div className="col-md-4">
              <div className="border border-sp p-2 mt-3">
                <label className="small text-muted">
                  Tipo de cuenta
                </label>
                <p className="transaction-text text-center" >
                  {data.fe_credentials.credentials_type === 'demo' ? 'Cuenta de prueba' : 'Facturacion electronica habilitada'}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="border border-sp p-2 mt-3">
                <label className="small text-muted">
                  Certificado
                </label>
                <p className="transaction-text text-center data-profile" >
                  {data.fe_credentials.credentials_type === 'demo' ? 'No registrado' : data.fe_credentials.conf.cer}
                </p>
              </div>
            </div>


            <div className="col-md-4">
              <div className="border border-sp p-2 mt-3">
                <label className="small text-muted">
                  Contra del Certificado
                </label>
                <p className="transaction-text text-center" >
                  {data.fe_credentials.credentials_type === 'demo' ? 'No registrado' : data.fe_credentials.conf.pass}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="border border-sp p-2 mt-3">
                <label className="small text-muted">
                  Usuario del PAC
                </label>
                <p className="transaction-text text-center overflow-x-auto data-profile" >
                  {data.fe_credentials.credentials_type === 'demo' ? 'No registrado' : data.fe_credentials.pac.user}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="border border-sp p-2 mt-3">
                <label className="small text-muted">
                  Contra del pac
                </label>
                <p className="transaction-text text-center overflow-x-auto data-profile" >
                  {data.fe_credentials.credentials_type === 'demo' ? 'No registrado' : data.fe_credentials.pac.pass}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="border border-sp p-2 mt-3">
                <label className="small text-muted">
                  Qr del Pac
                </label>
                <p className="transaction-text text-center data-profile" >
                  {data.fe_credentials.credentials_type === 'demo' ? 'No registrado' : <span className="overflow-x-scroll">{data.fe_credentials.pac.qr} </span>}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    ));
    return <>{infodata}</>;
  };

  //Function to generate the key 
  const generateBusinessTokenKey = async () => {
    try {
      let token = localStorage.getItem('ushopper-access-token'); 
      const response = await FetchAPI.generateBusinessToken(datastorage.Business.ruc, token)

      console.log(response)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <section>
        <div className="d-flex justify-content-between align-items-center mt-3  ">
          <h5>Datos de mi empresa </h5>
          <ModalEditCompany></ModalEditCompany>

          <div className="d-fex jusify-content-between aling-items-center mt-3">
            <button className='btn btn-light text-secondary-us border-sp' onClick={generateBusinessTokenKey}>
              <span className='font-us label-btn'>
                <FaKey className='icon-m' />
                Generar llave de acceso
              </span>
            </button>
          </div>
        </div>

        {info()}
      </section>
    </>
  );
}

export default InfoCompany;
