import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//img
// import User_Logo from "./img/user.png";
import card_client from "./img/card/2.png";
import card_product from "./img/card/3.png";
import card_invoice from "./img/card/4.png";
//  import card_categorie from "./img/card/5.png";
// import card_transactions from "./img/card/6.png";
import card_stock from "./img/card/7.png";
import card_stocks from "./img/card/8.png";
import card_transactions from "./img/card/9.png";
import card_historial from "./img/card/10.png";
import card_credit from "./img/card/11.png";
import ButtonExit from "../Buttons/ButtonExit";
import { FaArrowLeft } from "react-icons/fa";
import { RenderIf } from "../../../utils/RenderIf";

function CardOptions({ userData }) {

  const name_label = "Menú Principal";
  const [option, setOption] = useState({
    option: "Principal",
  });

  const showCards = (option) => {
    setOption({
      option: option,
    });
  };

  const mainMenu = [
    {
      name: "Clientes",
      image: card_client,
      link: "/clientes",
      sublink: "",
    },
    {
      name: "Transacciones ",
      image: card_historial,
      link: "/transactions",
    },
    {
      name: "Facturar",
      image: card_invoice,
      link: "/form",
      sublink: "",
    },
    {
      name: "Nota de Crédito",
      image: card_credit,
      link: "/credit",
    },

    {
      name: "Productos",
      image: card_stock,
      link: "/productos",
    },
    {
      name: "Existencia",
      image: card_stocks,
      link: "/stock",
    },
    {
      name: "Categorias",
      image: card_product,
      link: "/categories",
    },
  ];

  const adminMenu = [
    {
      name: "Creditos por aprobar ",
      image: card_historial,
      link: "/listcredentials",
    },
    {
      name: "Empresas",
      image: card_historial,
      link: "/listbusiness",
    },
  ]

  const menu = () => {
    let menu = userData.infoUser.userType === 0 ?  adminMenu: mainMenu
    return menu.map((options) => (
      <div className="col-6 col-lg-3 col-md-6 mt-3">
        <div
          className="cover"
          onClick={() => showCards(options.sublink)}
        >
          <Link
            to={options.link}
            className="title-us card-up"
          >
            <div className="body-card-us text-center shadow-sm">
              <div className="">
                <div className="mt-4">
                  <img
                    src={options.image}
                    className="img-card-us"
                    alt="images options "
                  />
                </div>
                <h3 className="title-card ">
                  {options.name}
                </h3>
              </div>
            </div>
          </Link>
        </div>
      </div>
    ))
  }
  return (
    <>
      <div className="container">
        <div className="py-4">
          {/* Options cards-Menu */}
          {/* *New Menu  */}

          <RenderIf isTrue={option.option === "Principal"}>
            <h3 className=" text-ushopper text-center main-title-section fade-in-top ">
              Menú Principal
            </h3>
            {/* Menu principal  */}
            <article className="container-cards">
              <section className="options-menu-cards ">
                <Row className="justify-content-md-center  fade-in-top">
                  {menu()}
                </Row>
              </section>
            </article>
          </RenderIf>
        </div>
      </div>
    </>
  );
}

export default CardOptions;
