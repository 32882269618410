import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";

//context
import UserContext from "./context/UserContext";

//Api
import { FetchAPI } from './api/Auth'
import { BusinessFetchAPI } from "./api/Business"

//Components
import LandingPage from "./components/Landing/LandingPage";
import Login from "./components/Login/Login";
import SingUp from "./components/SingUp/SingUp";
import SingUpCompany from "./components/SingUp/SingUpCompany";
import Team from "./components/Landing/Team";
import PrivacyPolicy from "./components/Landing/PrivacyPolicy";
import NoMatch from "./components/NoMatch/NoMatch";
import Swal from "sweetalert2";
import UploadFile from "./components/Dashboard/Invoice/UploadFile";
import ScanQr from './components/Dashboard/Invoice/ScanQr';
//Dashboard
import Dashboard from "./components/Dashboard/Dashboard";
import Profile from "./components/Dashboard/Profile/Profile";
import Clientes from "./components/Dashboard/Clientes/Clientes";
import Productos from "./components/Dashboard/Productos/Productos";
import Transactions from "./components/Dashboard/Transactions/Transactions"
import Categories from "./components/Dashboard/Categories/Categories";
import Stock from "./components/Dashboard/Stock/Stock"

import Credit from "./components/Dashboard/Biller/Credit";
//Extensions
import ListClients from "./components/Dashboard/ViewExtensions/ListClients"
import RegisterProduct from "./components/Dashboard/ViewExtensions/RegisterProducts";
import ListsProducts from "./components/Dashboard/ViewExtensions/ListsProducts";
import Biller from "./components/Dashboard/Biller/Biller";
import Report from "./components/Dashboard/Pdf/Report";
import Form from "./components/Dashboard/Biller/Form_biller";
import NewClient from "./components/Dashboard/Clientes/Form/NewClient";
//Admin panel 
import ListCredentials from './components/Dashboard/Administration/ListCredentials'; 
import ListBusiness from './components/Dashboard/Administration/ListBusiness'; 

//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap'
import './App.css';


//Object of direct login 
let userLogged = {
  isLoggedByUrl: false
}

//Event to login before the page is loaded (by url)
document.addEventListener("DOMContentLoaded", async function (e) {
  //We check if the user is login with an url or not
  if (window.location.href.split('?userphone=').length === 1) {
    console.log('No es login directo');
  } else {
    const user_phone = window.location.href.split('?userphone=')[1].split('&')[0];
    const access_token = window.location.href.split('?userphone=')[1].split('&')[1];
    //If the user is login by url we get the user_phone and we try to login with the number directly
    try {
      const response = await FetchAPI.login({ user_phone: user_phone, access_token: access_token });

      let token = response.data.token;
      let infoUser = response.data.user;
      let idcollaborator = infoUser.id  //id de usuario o colaborador

      //solicito el negocio al que pertenece el usuario
      try {
        const infoBusiness = await BusinessFetchAPI.Businessbycollaborator(idcollaborator);
        let Business = {
          "_id": infoBusiness.data.listBusiness[0]._id,
          "phone": infoBusiness.data.listBusiness[0].phone,
          "name": infoBusiness.data.listBusiness[0].name,
          "credits": infoBusiness.data.listBusiness[0].credits,
          "ruc": infoBusiness.data.listBusiness[0].ruc,
          "dv": infoBusiness.data.listBusiness[0].dv
        }

        //We affect the userLogged object 
        userLogged = {
          isLoggedByUrl: true,
          Business: Business,
          infoUser: infoUser
        }
        //We set the info on the local storage
        localStorage.setItem('ushopper-access-token', token);
        localStorage.setItem('userData', JSON.stringify({ infoUser, token, Business }));
        localStorage.setItem('isLoggedByUrl', userLogged.isLoggedByUrl);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: err.response.data.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.response.data.message,
        showConfirmButton: false,
        timer: 2500,
      });
      //window.location.href = '/sign-in'
    }

  }
});



function App() {

  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
    business: undefined,
  });


  useEffect(() => {
    const checklogin = async () => {
      let token = localStorage.getItem('ushopper-access-token');
      let userLoggedByUrl = localStorage.getItem('isLoggedByUrl');
      if (token == null) {
        localStorage.setItem('ushopper-access-token', '');
        token = '';
      }
      const tokenres = await FetchAPI.validtoken(token)
      if (tokenres.data === true) {
        const user = await FetchAPI.me(token);
        const infoBusiness = await BusinessFetchAPI.Businessbycollaborator(user.data._id);
        let Business = {
          "_id": infoBusiness.data.listBusiness[0]._id,
          "phone": infoBusiness.data.listBusiness[0].phone,
          "name": infoBusiness.data.listBusiness[0].name,
          "credits": infoBusiness.data.listBusiness[0].credits,
          "ruc": infoBusiness.data.listBusiness[0].ruc,
          "dv": infoBusiness.data.listBusiness[0].dv,
        }
        setUserData({
          token: token, user: user.data, business: Business, isLoggedByUrl: userLoggedByUrl
        });
      } else {
        setUserData({
          token: "",
          user: "",
          business: '',
          isLoggedByUrl: false
        });
        console.log('Token invalido');
      }
    }

    checklogin()
  }, [])


  return (
    <Router>
      <UserContext.Provider value={{ userData, setUserData }}>

        <Routes>
          {/* Index */}

          <Route exact path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SingUp />} />
          <Route path="/signCompany-up" element={<SingUpCompany />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/team" element={<Team />} />
          {/* Dashboard */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/clientes" element={<Clientes />} />
          <Route path="/productos" element={<Productos />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/stock" element={<Stock />} />
          <Route path="/biller" element={<Biller />} />
          <Route path="/form" element={<Form />} />
          <Route path="/report" element={<Report />} />
          <Route path="/credit" element={<Credit />} />
          {/* External  */}
          {/* <Route path="/register_client" element={<RegisterClients />} /> */}
          <Route path="/list_clients" element={<ListClients />} />
          <Route path="/register_product" element={<RegisterProduct />} />
          <Route path="/list_products" element={<ListsProducts />} />

          {/*Client */}
          <Route path="/new_client" element={<NewClient />} />
          <Route path="/invoice" element={<UploadFile />} />
          <Route path="/qrimage" element={<ScanQr />} />

          {/**Business */}
          <Route path="/listcredentials" element={<ListCredentials />} />
          <Route path="/listbusiness" element={<ListBusiness />} />

          {/* No Match link */}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </UserContext.Provider>
    </Router>

  );
}

export default App;
