import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class PaymentMethodFetchAPI { 

    static async getPaymentMethods() {
        const res = await server("/paymentmethod", {
            method: "get",
        });
        return res;
    }
}
