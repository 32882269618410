import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2";
import Col from "react-bootstrap/Col";
import { FaPen } from "react-icons/fa";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
//api
import { ClientFetchAPI } from '../../../../api/Clients'
import { TipoRecFetchAPI } from '../../../../api/iTipoRec';
import { TipoRucFetchAPI } from '../../../../api/dTipoRuc';


function ModalEditar({ id, setEventHandler }) {
  const [show, setShow] = useState(false);
  const [clientbyid, setClientbyid] = useState({
    client: []
  });
  const [tiporecdata, setTiporecdata] = useState({
    infoTipo: []
  })
  const [tiporucdata, setTiporucdata] = useState({
    infoTipo: []
  })
  const [client_phone, setClient_phone] = useState('');


  const getTipoRec = async () => {
    try {
      const response = await TipoRecFetchAPI.getTipoRec()
      setTiporecdata({
        infoTipo: response.data.iTipoRec
      })

    } catch (error) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  const getTiporuc = async () => {
    try {
      const response = await TipoRucFetchAPI.getTipoRuc()
      setTiporucdata({
        infoTipo: response.data.dTipoRuc
      })

    } catch (error) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  //Function to change the client phone (this is for the especial input)
  const handlePhoneChange = (status, phoneNumber, country) => {
    setClient_phone(phoneNumber)
  }

  useEffect(() => {
    (async () => {
      try {
        await getTipoRec()
        await getTiporuc()
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const infotiporecselect = () => {
    let getdata = tiporecdata.infoTipo;
    let option = getdata.map((data) => (
      <option value={data._id}>{data.iTipoRecname}</option>
    ))
    return (
      <>
        {option}
      </>
    )
  }

  const infotiporucselect = () => {
    let getdata = tiporucdata.infoTipo;
    let option = getdata.map((data) => (
      <option value={data._id}>{data.dTipoRucName}</option>
    ))
    return (
      <>
        {option}
      </>
    )
  }

  const getclient = async () => {
    try {
      const response = await ClientFetchAPI.getClientsByid(id)
      setClientbyid({
        client: [response.data.clientbyid]
      })

      setClient_phone(response.data.clientbyid.phone)
    } catch (error) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getclient();
  }

  const submit = async (event) => {
    event.preventDefault()
    const form = new FormData(event.target)
    form.append('_id', clientbyid.client[0]._id);
    form.append('client_phone', client_phone)
    let updateclient = Object.fromEntries(form.entries())

    try {
      const update = await ClientFetchAPI.updateClient(updateclient);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: update.data.message,
        showConfirmButton: false,
        timer: 1500
      })

      //We close the modal 
      setShow(false);
    } catch (error) {
      console.log(error)
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500
      })

    }
  }

  const formdata = () => {
    let getdata = clientbyid.client
    let row = getdata.map((data) => (

      <Form onSubmit={submit} encType='multipart/form-data'>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder={data.name}
                name='name'
                defaultValue={data.name}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="Direccion">
              <Form.Label>Direccion</Form.Label>
              <Form.Control
                type="text"
                placeholder={!data.Direccion ? ("No registrado") : (data.Direccion)}
                name="Direccion"
                defaultValue={data.Direccion ? data.Direccion : ""}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="client_phone">
              <Form.Label>Telefono</Form.Label>

              <IntlTelInput
                containerClassName="intl-tel-input"
                inputClassName="form-control rounded"
                placeholder='0000-0000'
                defaultCountry='pa'
                defaultValue={data.phone}
                input
                type="tel"
                format={true}
                onPhoneNumberChange={handlePhoneChange}
                name='client_phone'
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder={data.email}
                name='email'
                defaultValue={data.email}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Tipo cliente</Form.Label>
              <Form.Select aria-label="" name="receptor_type" disabled>
                <option value={!data.receptor_type ? ("") : (data.receptor_type._id)}>{!data.receptor_type ? ("No registrado") : (data.receptor_type.iTipoRecname)}</option>
                {infotiporecselect()}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Tipo ruc</Form.Label>
              <Form.Select aria-label="" name="receptor_client_ruc_type" disabled>
                <option value={!data.receptor_client_ruc_type ? ("") : (data.receptor_client_ruc_type._id)}>{!data.receptor_client_ruc_type ? ("No registrado") : (data.receptor_client_ruc_type.dTipoRucName)}</option>
                {infotiporucselect()}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="ruc">
              <Form.Label>Ruc</Form.Label>
              <Form.Control
                type="text"
                placeholder={!data.ruc ? ("No registrado") : (data.ruc)}
                name='ruc'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="dv">
              <Form.Label>Dv</Form.Label>
              <Form.Control
                type="text"
                placeholder={!data.dv ? ("No registrado") : (data.dv)}
                name='dv'
                disabled
              />
            </Form.Group>
          </Col>

          <div className="mt-3 d-flex justify-content-end">
            <Button
              size="sm"
              variant="success"
              type="submit"
            >
              Guardar
            </Button>
          </div>
        </Row>
      </Form>


    ));

    return (
      <>
        {row}
      </>
    )
  }

  return (
    <>

      <button className="btn btn-secondary-us" onClick={handleShow}>
        <FaPen className='text-secondary-us ' />
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar de nuevo cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formdata()}
        </Modal.Body>
      </Modal>


    </>
  )
}

export default ModalEditar