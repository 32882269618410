import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class TipoRecFetchAPI { 

    static async getTipoRec() {
        const res = await server("/itiporec", {
            method: "get",
        });
        return res;
    }
}


