import React, { useState, useEffect } from 'react'
//Components App
import ModalEditarCategories from '../Categories/ModalEditarCategories';
//Libraries
import Swal from 'sweetalert2'
//DataTable
import MUIDataTable from 'mui-datatables'
//icons 
import { FaRegTrashAlt } from "react-icons/fa";
//api
import { CategoriesFetchAPI } from "../../../api/Categories";

function TableCategories({ eventHandler, setEventHandler, panamanianCodes }) {
    //States 
    const [Categories, setCategories] = useState({
        infoCategories: [],
        isLoaded: false

    })

    const [data_rows, setDataRows] = useState([])
    let datastorage = JSON.parse(localStorage.getItem('userData'));
    let token = localStorage.getItem('ushopper-access-token');
    let business_phone = datastorage.Business.phone

    //Function to get categories
    const getcategories = async () => {
        try {
            const response = await CategoriesFetchAPI.getCategoriesbyBuisness(business_phone, token)
            setCategories({
                infoCategories: response.data.business_categories.categories,
                isLoaded: true
            })

            //?Cargan los  datos a la DataTable
            setDataRows(response.data.business_categories.categories)

        } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: error.data.message,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }

    useEffect(() => {
        (async () => {
            try {
                await getcategories()
            } catch (error) {
                console.log(error)
            }
        })()
        setEventHandler(0)
    }, [eventHandler])


    function alert(categori_id) {
        let buisness_phone = datastorage.Business.phone

        const getdelete = async () => {
            try {
                const responsedelete = await CategoriesFetchAPI.deleteCategories(categori_id, buisness_phone)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: responsedelete.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                getcategories()
            } catch (error) {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: error.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        }
        Swal.fire({
            icon: 'info',
            title: '¿Quieres eliminar este cliente?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Eliminar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                getdelete();
            } else if (result.isDenied) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'Accion cancelada',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    const columns = [

        {
            name: "image",
            label: "Imagen",
            options: {
                filter: false,
                customBodyRender: (name) => {
                    return (
                        <img className="img-table" src={!name ? "No Registrada " : name} />
                    );
                },
            },
        },
        {
            name: "name",
            label: "Nombre",
            options: {
                responsive: 'stacked',
            }
        },
        {
            name: "dCodCPBScmp",
            label: "Codificacion panameña",
            options: {
                responsive: 'stacked',
                customBodyRender: (dCodCPBScmp) => {
                    return (
                        dCodCPBScmp.name
                    );
                },
            }  
        },
        {
            name: '_id',
            label: "Acciones",
            options: {
                filter: false,
                customBodyRender: (name) => {
                    return (

                        <div className='d-flex align-content-center gap-1'>
                            <div className=" mx-1 card-up">
                                <ModalEditarCategories id={name} setEventHandler={setEventHandler} panamanianCodes={panamanianCodes} ></ModalEditarCategories>
                            </div>
                            <button type="button" className="btn-danger-us btn text-secondary bg-btn-secundary card-up" data-id={name} onClick={() => alert(name)} >
                                <FaRegTrashAlt />
                            </button>
                        </div>

                    );
                },
            },
        },
    ]

    const filterOptions = {
        Acciones: {
            display: false,
        },
    };

    const options = {
        selectableRows: false,
        print: false,
        download: false,
        responsive: true,
        filter: true,
        filterOptions: filterOptions,

    };


    return (
        <> <MUIDataTable
            title={"Lista de Categorias"}
            columns={columns}
            data={data_rows}
            className="rounded rounded-us mb-4"
            options={options}
        />

            {/* <table className="table table-borderless table-hover">
                <thead className="border-bottom">
                    <tr className="text-dark" >
                        <th scope="col">#</th>
                        <th scope="col">Imagen</th>
                        <th scope="col">Nombre</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {infotable()}
                </tbody>
            </table> */}
        </>
    )
}

export default TableCategories